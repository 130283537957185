import * as React from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { ResourceTypeEnum } from '../../../__generated/apollogen-types';
import { useHandledCreateGroupResourcesMutation } from '../../../queries/CreateGroupResourcesMutation';
import { getGroupResourcesQueryName } from '../../../queries/GroupResourcesQuery';
import { useSnackbar } from '../../ui/Snackbar';

interface Props {
  groupId: string;
  onCancel: () => void;
}

type InputChangeEventT = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export default function CreateGroupResourceForm(props: Props) {
  const { groupId } = props;
  const [url, setUrl] = React.useState('');
  const [label, setLabel] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const snackbar = useSnackbar();
  const handledCreateGroupResource = useHandledCreateGroupResourcesMutation();

  const handleChange = React.useCallback(
    (event: InputChangeEventT) => {
      if (event.target.name === 'url') {
        setUrl(event.target.value);
      } else {
        setLabel(event.target.value);
      }
    },
    [setUrl, setLabel]
  );

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<Element>) => {
      e.preventDefault();
      setIsSubmitting(true);
      try {
        const { status, message } = await handledCreateGroupResource({
          variables: {
            input: {
              groupId,
              resources: [
                { value: url, label, type: ResourceTypeEnum.website },
              ],
            },
          },
          refetchQueries: () => [getGroupResourcesQueryName()],
        });

        snackbar.setSnackbar(message, status);
      } catch (error) {
        // No Operation, "handled" mutations catch and normalize the errors
      } finally {
        setIsSubmitting(false);
      }
    },
    [url, label, groupId, snackbar, handledCreateGroupResource]
  );

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="mb3 w-100">
        <TextField
          id="url"
          name="url"
          label="URL"
          className="w-100"
          variant="outlined"
          value={url}
          onChange={handleChange}
          size="small"
        />
      </div>
      <div className="mb3 w-100">
        <TextField
          id="label"
          name="label"
          className="w-100"
          label="Title (optional)"
          variant="outlined"
          value={label}
          onChange={handleChange}
          size="small"
        />
      </div>
      <div className="flex justify-end">
        <Button
          variant="text"
          color="default"
          size="small"
          children="CANCEL"
          onClick={props.onCancel}
        />
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="small"
            children="ADD"
            type={'submit'}
          />
        )}
      </div>
    </form>
  );
}
