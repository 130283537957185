import * as React from 'react';
import * as queryString from 'query-string';
import pick from 'lodash-es/pick';
const { useMemo } = React;

interface QueryParamsDictT {
  [key: string]: string | undefined;
}

// Use like:
// const { id: initialId } = useQueryParams(['id'], window.location.search);
export const useQueryParams = (keys: string[], inputQueryString: string) => {
  return useMemo(() => {
    const qs = queryString.parse(inputQueryString) as QueryParamsDictT;
    return pick(qs, keys);
  }, [inputQueryString, ...keys]);
};
