import localforage from 'localforage';

export const CARD_REFERRAL_ID = 'preAuthCardReferral';
export const CAMERA_PERMISSION = 'cameraPermission';

export const appStorage = localforage.createInstance({ name: 'app' });

// ============================================================================
// Post auth path
const POST_AUTH_PATH = 'postAuthPath';

export const setPostAuthPath = (_path?: string) => {
  return appStorage.setItem(POST_AUTH_PATH, _path || window.location.pathname);
};

export const consumePostAuthPath = async (): Promise<string | undefined> => {
  const path = await appStorage.getItem<string | undefined>(POST_AUTH_PATH);
  await appStorage.removeItem(POST_AUTH_PATH);
  return path;
};
