import * as React from 'react';
import idx from '../../utils/idx';
import { TextField, Button } from '@material-ui/core';
import keyBy from 'lodash-es/keyBy';

import {
  CreateContactInfoInput,
  UpdateContactInfoInput,
  ContactInfoInputEnum,
  ContactInfoLabelEnum,
  RequestStatusEnum,
  ContactInfoDefinitionFragment,
  ContactInfoFragment,
  CreateContactInfoMutation_createContactInfo,
  UpdateContactInfoMutation_updateContactInfo,
  ContactPlatformsEnum,
} from '../../__generated/apollogen-types';
import useInputState from '../../utils/useInputState';

import { DangerButton } from '../ui/Button';
import { FormError } from '../ui/form/FormError';
import MuiPhoneInput from '../ui/MuiPhoneInput';

import { useUpdateContactInfoMutation } from '../../queries/UpdateContactInfoMutation';
import { useCreateContactInfoMutation } from '../../queries/CreateContactInfoMutation';
import { useDeleteContactInfoMutation } from '../../queries/DeleteContactInfoMutation';
import { useContactInfoDefinitionsQuery } from '../../queries/ContactInfoDefinitionsQuery';
import { useSnackbar } from '../ui/Snackbar';

type ChildrenPropsT = { children?: React.ReactNode };
const DefaultFormBodyContainer: React.ComponentType<ChildrenPropsT> = props => (
  <div children={props.children} />
);
const DefaultFormActionsContainer: React.ComponentType<
  ChildrenPropsT
> = props => <div className="flex justify-end pt2" children={props.children} />;

// ============================================================================
// Form types

type CreateOrUpdateResponseT =
  | CreateContactInfoMutation_createContactInfo
  | UpdateContactInfoMutation_updateContactInfo;

interface BasePropsT {
  cardId: string;
  FormBodyContainer?: React.ComponentType<ChildrenPropsT>;
  FormActionsContainer?: React.ComponentType<ChildrenPropsT>;
  onCancel: () => void;
  onDone: () => void;
}
export interface CreateContactInfoPropsT {
  mode: 'create';
  initialInfo: Partial<CreateContactInfoInput> & {
    platform: ContactPlatformsEnum;
  };
}

export interface UpdateContactInfoPropsT {
  mode: 'update';
  initialInfo: ContactInfoFragment;
}

export type ContactInfoFormPropsT = (
  | CreateContactInfoPropsT
  | UpdateContactInfoPropsT) &
  BasePropsT;

export default function ContactInfoFormContainer(props: ContactInfoFormPropsT) {
  const { data } = useContactInfoDefinitionsQuery();
  const contactInfoDefinitions = idx(data, _ => _!.contactInfoDefinitions);
  const definitionsMap = React.useMemo(() => {
    return contactInfoDefinitions
      ? keyBy<ContactInfoDefinitionFragment>(
          [...contactInfoDefinitions.links, ...contactInfoDefinitions.methods],
          'platform'
        )
      : undefined;
  }, [contactInfoDefinitions]);

  if (!definitionsMap) {
    return null;
  }

  const platform = props.initialInfo.platform;
  const definition = definitionsMap[platform];

  return <ContactInfoForm {...props} definition={definition} />;
}

export function ContactInfoForm(
  props: ContactInfoFormPropsT & { definition: ContactInfoDefinitionFragment }
) {
  const {
    cardId,
    definition,
    FormBodyContainer = DefaultFormBodyContainer,
    FormActionsContainer = DefaultFormActionsContainer,
  } = props;

  const snackbar = useSnackbar();
  const createContactInfo = useCreateContactInfoMutation();
  const updateContactInfo = useUpdateContactInfoMutation();
  const deleteContactInfo = useDeleteContactInfoMutation();

  const [initialValues] = React.useState<
    CreateContactInfoInput | UpdateContactInfoInput
  >(() => {
    if (props.mode === 'create') {
      return {
        value: '',
        countryCode: 'NO',
        dialingCode: '+47',
        ...props.initialInfo,
        platform: definition.platform,
      };
    }

    const info = props.initialInfo;
    const _initialValues: UpdateContactInfoInput = {
      id: info.id,
      platform: info.platform,
      value: info.rawValue,
      label: info.label,
    };

    if (info.__typename === 'PhoneNumberContactInfo') {
      _initialValues.value = info.nationalNumber || info.rawValue;
      _initialValues.countryCode = (info.countryCode || '').toLowerCase();
      _initialValues.dialingCode = info.dialingCode || '';
    }
    return _initialValues;
  });

  const valueInput = useInputState(initialValues.value);
  const labelInput = useInputState(() => {
    if (definition.labelType === ContactInfoLabelEnum.none) {
      return undefined;
    }
    return initialValues.label || '';
  });
  const [showLabelInput, setShowLabelInput] = React.useState(
    Boolean(labelInput.value)
  );

  const phoneInput = useInputState(() => ({
    dialingCodeId: initialValues.countryCode || 'no',
    dialingCode: initialValues.dialingCode || '+47',
    phoneNumber: initialValues.value,
  }));

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form: CreateContactInfoInput = {
      platform: definition.platform,
      value: valueInput.value,
    };

    if (definition.labelType !== ContactInfoLabelEnum.none) {
      form.label = labelInput.value;
    }

    if (definition.inputType === ContactInfoInputEnum.phoneNumber) {
      const { phoneNumber, dialingCode, dialingCodeId } = phoneInput.value;
      form.value = phoneNumber;
      form.dialingCode = dialingCode;
      form.countryCode = dialingCodeId;
    }

    Promise.resolve()
      .then(async () => {
        if (props.mode === 'create') {
          return createContactInfo({ cardId, contactInfo: [form] });
        }
        const id = (initialValues as UpdateContactInfoInput).id;
        const updateForm: UpdateContactInfoInput = { ...form, id };
        return updateContactInfo({ cardId, contactInfo: [updateForm] });
      })
      .then((data: CreateOrUpdateResponseT) => {
        const { status, message, validation } = data;
        const formValidation = validation[0];

        snackbar.setSnackbar(message, status);
        if (status === RequestStatusEnum.OK) {
          return props.onDone();
        }

        if (!formValidation) {
          return;
        }

        labelInput.setError(formValidation.label || undefined);

        // set validation messages
        if (definition.inputType === ContactInfoInputEnum.phoneNumber) {
          phoneInput.setError({
            dialingCode: formValidation.dialingCode || undefined,
            dialingCodeId: formValidation.countryCode || undefined,
            phoneNumber:
              formValidation.nationalNumber ||
              formValidation.value ||
              undefined,
          });
        } else {
          valueInput.setError(formValidation.value || undefined);
        }
      });
  }

  const handleDelete = () => {
    deleteContactInfo({
      cardId: props.cardId,
      contactInfoIds: [(initialValues as UpdateContactInfoInput).id],
    }).then(({ message, status }) => {
      snackbar.setSnackbar(message, status);
      if (status === RequestStatusEnum.OK) {
        props.onDone();
      }
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <FormBodyContainer>
        {definition.inputType === ContactInfoInputEnum.phoneNumber ? (
          <React.Fragment>
            <MuiPhoneInput
              variant="filled"
              size="small"
              label="Phone number"
              value={`${phoneInput.value.dialingCode} ${phoneInput.value.phoneNumber}`}
              initialCountryCode={phoneInput.value.dialingCodeId}
              onChange={(phoneValue, countryInfo) => {
                const dialingCode = `+${countryInfo.dialCode}`;
                phoneInput.setValue({
                  dialingCode, // +47
                  dialingCodeId: countryInfo.countryCode, // no
                  phoneNumber: phoneValue
                    .slice(dialingCode.length)
                    .replace(' ', ''),
                });
              }}
            />
            <FormError
              error={idx(phoneInput, _ => _.error!.dialingCode)}
              hideError={!phoneInput.showError}
            />
            <FormError
              error={idx(phoneInput, _ => _.error!.dialingCodeId)}
              hideError={!phoneInput.showError}
            />
            <FormError
              error={idx(phoneInput, _ => _.error!.phoneNumber)}
              hideError={!phoneInput.showError}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextField
              label="Value"
              variant="filled"
              size="small"
              className="w-100"
              style={{ marginBottom: '2rem' }}
              value={valueInput.value}
              placeholder=""
              type="text"
              onChange={e => valueInput.setValue(e.currentTarget.value)}
              helperText={valueInput.showError ? valueInput.error : undefined}
            />
          </React.Fragment>
        )}

        <div className="mt2 tr">
          {showLabelInput ? (
            <TextField
              label="Custom name"
              variant="filled"
              className="w-100"
              style={{ marginBottom: '2rem' }}
              size="small"
              value={labelInput.value}
              placeholder=""
              onChange={e => labelInput.setValue(e.currentTarget.value)}
              type="text"
              helperText={labelInput.showError ? labelInput.error : undefined}
            />
          ) : (
            <Button
              variant="text"
              color="primary"
              children="+ Add custom name"
              onClick={() => setShowLabelInput(true)}
            />
          )}
        </div>
      </FormBodyContainer>

      <FormActionsContainer>
        {props.mode === 'create' ? (
          <React.Fragment>
            <Button
              type="button"
              variant="text"
              color="secondary"
              children="Cancel"
              onClick={props.onCancel}
            />
            <Button
              type="submit"
              variant="text"
              color="primary"
              children="Create"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DangerButton
              type="button"
              children="Delete"
              onClick={handleDelete}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              children="Update"
            />
          </React.Fragment>
        )}
      </FormActionsContainer>
    </form>
  );
}
