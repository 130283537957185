import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
  MutationHookOptions,
  useMutation,
} from 'react-apollo';
import {
  removeContacts as RemoveContactsMutationT,
  removeContactsVariables as RemoveContactsMutationVariables,
} from '../__generated/apollogen-types';
import REMOVE_CONTACTS_MUTATION from './RemoveContactsMutation.graphql';

type DataT = RemoveContactsMutationT;
type VariablesT = RemoveContactsMutationVariables;

type RemoveContactsMutationOpts = MutationComponentOptions<DataT, VariablesT>;

export type RemoveContactsMutationFn = MutationFunction<DataT, VariablesT>;

export const RemoveContactsMutation = (props: {
  children: RemoveContactsMutationOpts['children'];
}) => (
  <Mutation<DataT, VariablesT> {...props} mutation={REMOVE_CONTACTS_MUTATION} />
);

export const useRemoveContactsMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(REMOVE_CONTACTS_MUTATION, {
    ...options,
  });
};
