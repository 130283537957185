import * as React from 'react';
import { Link } from '@reach/router';

import * as theme from '../ui/theme';

function NavItemBottomHighlight() {
  return (
    <div
      className="absolute w-100 bottom-0"
      style={{
        height: '3px',
        borderTopLeftRadius: '1px',
        borderTopRightRadius: '1px',
        backgroundColor: theme.colors.green400,
      }}
    />
  );
}

interface HeaderNavItemLinkT {
  type: 'link';
  name: string;
  hide?: boolean;
  linkTo: string;
  children: JSX.Element | null;
}
interface HeaderNavItemButtonT {
  type: 'button';
  name: string;
  hide?: boolean;
  onClick: () => void;
  children: JSX.Element | null;
}

export function HeaderNavItem(
  props: HeaderNavItemLinkT | HeaderNavItemButtonT
) {
  const currentPath = window.location.pathname;
  const isActivePath =
    props.type === 'link' && currentPath.startsWith(props.linkTo);
  const color = isActivePath ? theme.colors.light : theme.colors.gray400;

  if (props.hide) {
    return null;
  }

  const containerProps = {
    tabIndex: 1,
    className: 'h-100 db relative ph2 no-underline',
    style: { color },
  };

  const itemContent = (
    <div className="h-100 flex flex-column items-center justify-center ph1">
      <div>{props.children}</div>
      <h4 className="f6 fw4 lh-title mv0 ls-05 nowrap">{props.name}</h4>
      {isActivePath && <NavItemBottomHighlight />}
    </div>
  );

  return props.type === 'link' ? (
    <Link {...containerProps} to={props.linkTo} children={itemContent} />
  ) : (
    <div {...containerProps} onClick={props.onClick} children={itemContent} />
  );
}
