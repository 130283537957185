
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"GroupPermissionsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GroupPermissions"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewerCanAppendItems"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanDeleteItems"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanRenameGroup"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanEditGroupInfo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanEditGroupSettings"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanDeleteGroup"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanLeaveGroup"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanInviteMembers"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanRemoveMembers"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerCanJoinGroup"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":297}};
    doc.loc.source = {"body":"fragment GroupPermissionsFragment on GroupPermissions {\n  viewerCanAppendItems\n  viewerCanDeleteItems\n  viewerCanRenameGroup\n  viewerCanEditGroupInfo\n  viewerCanEditGroupSettings\n  viewerCanDeleteGroup\n  viewerCanLeaveGroup\n  viewerCanInviteMembers\n  viewerCanRemoveMembers\n  viewerCanJoinGroup\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
