import * as React from 'react';
import { TextFieldProps } from '@material-ui/core';
// @ts-ignore
import MuiPhoneInput from 'material-ui-phone-number';

interface CountryInfoT {
  name: string;
  dialCode: string;
  countryCode: string;
}

interface MuiPhoneInputPropsT {
  label: string;
  value: string;
  initialCountryCode?: string;
  size: TextFieldProps['size'];
  variant: TextFieldProps['variant'];
  onChange: (fullNumber: string, countryInfo: CountryInfoT) => void;
}
export default function PhoneInput(props: MuiPhoneInputPropsT) {
  const { label, variant, size, initialCountryCode, onChange, value } = props;

  const onChangeRef = React.useRef(onChange);
  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  return (
    <MuiPhoneInput
      size={size}
      variant={variant}
      defaultCountry={initialCountryCode || 'no'}
      preferredCountries={['no', 'us']}
      className="w-100"
      label={label}
      // countryCodeEditable={false}
      disableAreaCodes={true}
      value={value}
      inputProps={{
        style: { width: '100%' },
      }}
      // @ts-ignore
      onChange={(value, country) => {
        onChangeRef.current(value, country);
      }}
    />
  );
}
