export const appRootId = 'root';

const backendMode = process.env.REACT_APP_AUTH_MODE;
const nodeEnv = process.env.NODE_ENV;

const gqlUri = process.env.GQL_URI;
const cloudinaryDevConfig = 'l0loy9sa';
const cloudinaryProdConfig = 'aqnbefzo';

const appConfig = {
  nodeEnv,
  backendMode,
  graphQLUri:
    backendMode == 'production'
      ? 'https://live.minglapp.io/graphql'
      : 'https://dev.minglapp.io/graphql',
  cloudinaryCloudName: 'mingl',
  cloudinaryUnsignedUploadPreset:
    backendMode !== 'production' ? cloudinaryDevConfig : cloudinaryProdConfig,
};

export const urls = {
  playStore: 'https://mingl.page.link/PlayStore', // https://play.google.com/store/apps/details?id=com.mingl
  appStore: 'https://mingl.page.link/AppStore', // https://itunes.apple.com/us/app/mingl-no/id1276639898
  getMingl: 'https://mingl.page.link/getMingl',
};

export default appConfig;
