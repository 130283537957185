import * as React from 'react';
import { setPostAuthPath } from '../../app/storage/appStorage';
import debounce from 'lodash-es/debounce';
import { AuthProviderContextT } from '../../core/providers/AuthProvider';
import Button from '../../ui/Button';
import LinkButton from '../../ui/LinkButton';

const timeoutPromise = (timeout: number) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};

export const LoginSignup = (props: {
  authState: AuthProviderContextT;
  primaryButtonClassName?: string;
  secondaryButtonClassName?: string;
}) => {
  const onSignUp = debounce(
    async () => {
      await setPostAuthPath(window.location.pathname);
      await timeoutPromise(50);
      props.authState.createAccount('/authCallback');
    },
    225,
    { leading: true, trailing: false }
  );

  const onLogin = debounce(
    async () => {
      await setPostAuthPath(window.location.pathname);
      await timeoutPromise(50);
      props.authState.login('/authCallback');
    },
    225,
    { leading: true, trailing: false }
  );

  return (
    <React.Fragment>
      <Button
        className={
          props.primaryButtonClassName
            ? props.primaryButtonClassName
            : `bn br-pill pointer bg-button-green grow box-shadow-1 pv2 ph4 mb3 `
        }
        onClick={onSignUp}
        fontSize={5}
        children={
          <span
            className="f5 b ls-05 lh-title white ttc"
            children="Sign up for free"
          />
        }
      />
      <br />

      <LinkButton
        className={
          props.secondaryButtonClassName
            ? props.secondaryButtonClassName
            : 'mingl-black underline'
        }
        onClick={onLogin}
        fontSize={6}
        children="Existing user?"
      />
    </React.Fragment>
  );
};
