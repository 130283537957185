import * as React from 'react';
import clsx from 'clsx';

import {
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Button,
  Divider,
  ThemeProvider,
  makeStyles,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardHeader,
  CardActions,
  Menu,
  MenuItem,
  ListItemIcon,
  CardContent,
} from '@material-ui/core';
import { Edit as EditIcon, Add as AddIcon } from '@material-ui/icons';

import {
  GetNode_node_User_cards,
  ContactInfoDefinitionFragment,
  ContactInfoFragment,
  ContactPlatformsEnum,
} from '../../__generated/apollogen-types';

import ContactInfoForm, {
  CreateContactInfoPropsT,
  UpdateContactInfoPropsT,
} from './ContactInfoForm';

import { useContactInfoDefinitionsQuery } from '../../queries/ContactInfoDefinitionsQuery';
import { whiteMuiTheme } from '../ui/theme';

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  contactInfoSectionCard: {
    marginBottom: theme.spacing(2),
  },
  contactInfoAvatar: {
    height: '2rem',
    width: '2rem',
  },
}));

const ContactInfoMenu = (props: {
  ariaId: string;
  type: 'links' | 'methods';
  anchorEl: HTMLElement | null;
  onDismiss: () => void;
  onSelect: (platform: ContactPlatformsEnum) => void;
}) => {
  const { data } = useContactInfoDefinitionsQuery();
  const classes = useStyles();

  if (!data || !data.contactInfoDefinitions) {
    return null;
  }

  const items: ContactInfoDefinitionFragment[] =
    data.contactInfoDefinitions[props.type];

  return (
    <>
      <Menu
        id={props.ariaId}
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.onDismiss}
      >
        {items.map(definition => (
          <MenuItem
            key={definition.platform}
            onClick={() => {
              props.onSelect(definition.platform);
              props.onDismiss();
            }}
          >
            <ListItemIcon>
              <Avatar
                alt={`${definition.platform} icon`}
                src={definition.icon}
                className={classes.contactInfoAvatar}
              />
            </ListItemIcon>
            <ListItemText primary={definition.displayName} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ContactInfoListItem = (props: {
  contactInfo: ContactInfoFragment;
  onEdit: (contactInfo: ContactInfoFragment) => void;
}) => {
  const { contactInfo, onEdit } = props;
  const classes = useStyles();

  const onClick = React.useCallback(() => {
    onEdit(contactInfo);
  }, [contactInfo, onEdit]);

  return (
    <ListItem key={contactInfo.id} button={true} onClick={onClick}>
      <ListItemAvatar>
        <Avatar
          alt={`${contactInfo.platform} icon`}
          src={contactInfo.icon}
          className={classes.contactInfoAvatar}
        />
      </ListItemAvatar>

      <ListItemText>
        <div className="flex flex-column">
          {contactInfo.label && (
            <Typography
              variant="body2"
              className="truncate"
              children={contactInfo.label}
            />
          )}
          <Typography
            variant="body1"
            className="truncate"
            children={contactInfo.value}
          />
        </div>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={onClick} aria-label="edit">
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ContactInfoListCard = (props: {
  title: string;
  type: 'links' | 'methods';
  contactInfos: ContactInfoFragment[];
  triggerCreate: (platform: ContactPlatformsEnum) => void;
  triggerEdit: (item: ContactInfoFragment) => void;
}) => {
  const { title, type, contactInfos, triggerCreate, triggerEdit } = props;
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    []
  );
  const dismissMenu = React.useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const emptyState = contactInfos.length === 0;

  return (
    <ThemeProvider theme={whiteMuiTheme}>
      <ContactInfoMenu
        ariaId="contact-info-menu"
        anchorEl={menuAnchorEl}
        onSelect={triggerCreate}
        type={props.type}
        onDismiss={dismissMenu}
      />

      <Card className={classes.contactInfoSectionCard}>
        <CardHeader
          titleTypographyProps={{ align: emptyState ? 'center' : 'left' }}
          title={title}
        />

        {emptyState ? (
          <>
            <CardContent className="tc">
              <Typography
                variant="subtitle1"
                className="w-100 tc"
                children={
                  type === 'links'
                    ? `Add links to share your Facebook, Instagram, or website on your card`
                    : `Add contact info to share your phone number, email, or WhatsApp on your card`
                }
              />
            </CardContent>

            <CardActions className="justify-center">
              <Button
                aria-controls="contact-info-menu"
                aria-haspopup="true"
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={menuButtonClick}
                children={type === 'links' ? 'Add a link' : 'Add contact info'}
              />
            </CardActions>
          </>
        ) : (
          <>
            {/* Non-empty state */}
            <List>
              {contactInfos.map((info, i) => (
                <React.Fragment key={info.id}>
                  <ContactInfoListItem
                    onEdit={triggerEdit}
                    contactInfo={info}
                  />

                  <Divider
                    variant="inset"
                    component="li"
                    className={clsx({
                      [classes.hidden]: i === contactInfos.length - 1,
                    })}
                  />
                </React.Fragment>
              ))}
            </List>

            <CardActions className="justify-end">
              <Button
                aria-controls="contact-info-menu"
                aria-haspopup="true"
                variant="text"
                color="secondary"
                children="Add new"
                startIcon={<AddIcon />}
                onClick={menuButtonClick}
              />
            </CardActions>
          </>
        )}
      </Card>
    </ThemeProvider>
  );
};

interface PropsT {
  card: GetNode_node_User_cards;
  refetch: () => void;
}
export default function ManageContactInfo(props: PropsT) {
  const { card, refetch } = props;

  type FormStateT =
    | { mode: 'inactive' }
    | { mode: 'create' } & CreateContactInfoPropsT
    | { mode: 'update' } & UpdateContactInfoPropsT;

  const [formState, setFormState] = React.useState<FormStateT>({
    mode: 'inactive',
  });

  const showCreateContactInfoForm = React.useCallback(
    (platform: ContactPlatformsEnum) => {
      setFormState({ mode: 'create', initialInfo: { platform } });
    },
    []
  );

  const showUpdateContactInfoForm = React.useCallback(
    (initialInfo: ContactInfoFragment) => {
      setFormState({ mode: 'update', initialInfo: { ...initialInfo } });
    },
    []
  );
  const dismissContactInfoForm = React.useCallback(() => {
    setFormState({ mode: 'inactive' });
  }, []);

  const onUpdateSuccess = React.useCallback(() => {
    dismissContactInfoForm();
    refetch();
  }, [refetch, dismissContactInfoForm]);

  return (
    <div className="pt4">
      <ContactInfoListCard
        contactInfos={card.contactInfo.methods}
        title="Contact info"
        type="methods"
        triggerCreate={showCreateContactInfoForm}
        triggerEdit={showUpdateContactInfoForm}
      />

      <ContactInfoListCard
        contactInfos={card.contactInfo.links}
        title="Website and social links"
        type="links"
        triggerCreate={showCreateContactInfoForm}
        triggerEdit={showUpdateContactInfoForm}
      />

      <Dialog
        open={formState.mode !== 'inactive'}
        onClose={dismissContactInfoForm}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        {formState.mode === 'inactive' && <DialogContent />}
        {formState.mode === 'update' && (
          <ContactInfoForm
            key={formState.initialInfo.id}
            mode="update"
            FormBodyContainer={DialogContent}
            FormActionsContainer={DialogActions}
            cardId={card.id}
            initialInfo={formState.initialInfo}
            onCancel={dismissContactInfoForm}
            onDone={onUpdateSuccess}
          />
        )}
        {formState.mode === 'create' && (
          <ContactInfoForm
            mode="create"
            FormBodyContainer={DialogContent}
            FormActionsContainer={DialogActions}
            cardId={card.id}
            initialInfo={formState.initialInfo}
            onCancel={dismissContactInfoForm}
            onDone={onUpdateSuccess}
          />
        )}
      </Dialog>
    </div>
  );
}
