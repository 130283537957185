/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  width: number | string;
  height: number | string;
  className?: string;
}

const DeviceCameraIcon = ({ width, height, color, className }: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    width={width}
    height={height}
    className={className}
    role="img"
    aria-labelledby="title"
    name="device-camera"
  >
    <path
      fillRule="evenodd"
      fill={color}
      d="M15 3H7c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM6 5H2V4h4v1zm4.5 7C8.56 12 7 10.44 7 8.5S8.56 5 10.5 5 14 6.56 14 8.5 12.44 12 10.5 12zM13 8.5c0 1.38-1.13 2.5-2.5 2.5S8 9.87 8 8.5 9.13 6 10.5 6 13 7.13 13 8.5z"
    />
  </svg>
);

export default DeviceCameraIcon;
