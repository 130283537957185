/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
  className?: string;
}

const UserAddIcon = ({ color, size, className }: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className={className}
    name="user-add"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M5.5 8C4.122 8 3 6.878 3 5.5S4.122 3 5.5 3 8 4.122 8 5.5 6.878 8 5.5 8zm0-4C4.673 4 4 4.673 4 5.5S4.673 7 5.5 7 7 6.327 7 5.5 6.327 4 5.5 4zM9.5 14h-8a.5.5 0 0 1-.5-.5v-2c0-.108.035-.214.1-.3C2.133 9.822 3.778 9 5.5 9s3.366.822 4.4 2.2c.064.086.1.191.1.3v2a.5.5 0 0 1-.5.5zM2 13h7v-1.328A4.524 4.524 0 0 0 5.5 10 4.521 4.521 0 0 0 2 11.672zM14.5 7h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"
      />
      <path
        fill={color}
        d="M12.5 9a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v4a.5.5 0 0 1-.5.5z"
      />
    </g>
  </svg>
);

export default UserAddIcon;
