import * as React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { captureException } from '../../../utils/raven';
import analytics, { analyticsEvents } from '../../../utils/analytics';
import { consumePostAuthPath } from '../storage/appStorage';
import LoadingPage from '../../../components/LoadingPage';
import PageContainer from '../../../containers/PageContainer';

const redirectViewer = async () => {
  let navigateTo = '/home';
  try {
    const postAuthPath = await consumePostAuthPath();
    if (postAuthPath) {
      navigateTo = postAuthPath;
    }
  } catch (error) {
    captureException(error);
  }

  analytics.track(analyticsEvents.authCallbackPage.navigateTo, {
    route: navigateTo,
  });
  navigate(navigateTo);
};

export default function AuthCallbackRoute(props: RouteComponentProps) {
  React.useEffect(() => {
    redirectViewer();
  }, []);

  return (
    <PageContainer redirectUnauthenticated={false}>
      <LoadingPage />
    </PageContainer>
  );
}
