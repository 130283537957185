import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import { FormLabel } from './FormLabel';
import { FormError } from './FormError';

interface TextAreaProps {
  name: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void;
  onChange: (event: React.ChangeEvent<{}>) => void;
  label?: string;
  touched?: boolean;
  error?: string;
  style?: object;
  suggestedLength?: number;
  color?: string;
  // placeholder: string;
}

export default function FormTextArea({
  name,
  value,
  onBlur,
  onChange,
  label,
  touched,
  style = {},
  suggestedLength,
  error,
  color,
}: TextAreaProps) {
  return (
    <div>
      {label && (
        <div>
          <FormLabel
            htmlFor={name}
            children={label}
            hasError={!!(error && touched)}
            color={color}
          />
        </div>
      )}

      <TextareaAutosize
        className="placeholder-darkblue"
        placeholder={'Write something about yourself'}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxRows={7}
        style={{
          resize: 'vertical',
          fontWeight: 200,
          ...style,
        }}
      />
      {suggestedLength && (
        <span
          className={`f8 fw6 fr ${color}`}
          children={`${value.length} / ${suggestedLength}`}
        />
      )}

      <FormError error={error} hideError={!touched} />
    </div>
  );
}
