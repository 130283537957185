export function strictImageCanBeTransformed(imageUrl: string): boolean {
  return /^https?:\/\/res\.cloudinary\.com\/mingl\/image\/upload(\/v\d+)?\/[a-zA-Z0-9]+\.[a-zA-Z0-9]{3,4}$/.test(
    imageUrl
  );
}

export function isInMinglImageCloud(imageUrl: string): boolean {
  return /^https?:\/\/res\.cloudinary\.com\/mingl\/image\/upload/.test(
    imageUrl
  );
}
