import config from './config';

// Based on:
// https://cloudinary.com/documentation/upload_images#uploading_with_a_direct_call_to_the_api
const XHR_DONE = 4;

export type ProgressCallback = (progress: number) => void;
export type UploadImageFn = (
  file: File,
  onProgress?: ProgressCallback
) => Promise<string>;

export const uploadImage: UploadImageFn = (
  file,
  onProgress
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const url = `https://api.cloudinary.com/v1_1/${config.cloudinaryCloudName}/upload`;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    const fd = new FormData();
    fd.append('upload_preset', config.cloudinaryUnsignedUploadPreset);
    fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
    fd.append('file', file);

    // Update progress (can be used to show progress indicator)
    if (typeof onProgress === 'function') {
      xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
        const progress = Math.round((e.loaded * 100.0) / e.total);
        onProgress(progress);
      });
    }

    xhr.onreadystatechange = (e: Event) => {
      if (xhr.readyState === XHR_DONE) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          const secure_url = response.secure_url;
          // img.alt = response.public_id;
          return resolve(secure_url);
        }
        console.error('Something went wrong while uploading', xhr);
        reject(new Error('Error uploading image'));
      }
    };
    xhr.send(fd);
  });
};
