import { useCallback } from 'react';
import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';
import gql from 'graphql-tag';

import {
  ReplyToGroupEnquiryMutation,
  ReplyToGroupEnquiryMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import idx from '../utils/idx';
// import CREATE_ENQUIRY_RESOURCES from './ReplyToGroupEnquiryMutation.graphql';
const CREATE_ENQUIRY_RESOURCES = gql`
  mutation ReplyToGroupEnquiryMutation(
    $input: ReplyToGroupEnquiryMutationInput!
  ) {
    replyToGroupEnquiry(input: $input) {
      status
      message
    }
  }
`;

type DataT = ReplyToGroupEnquiryMutation;
type VariablesT = ReplyToGroupEnquiryMutationVariables;

export type ReplyToGroupEnquiryMutationFnT = MutationFunction<
  DataT,
  VariablesT
>;

export const useReplyToGroupEnquiryMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(CREATE_ENQUIRY_RESOURCES, {
    ...options,
  });
};

// ============================================================================
// Mutation handler

type MutationOutputT = DataT['replyToGroupEnquiry'];

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledReplyToGroupEnquiryMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [replyToGroupEnquiry] = useReplyToGroupEnquiryMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'ReplyToGroupEnquiryMutationOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
      };

      try {
        const res = await replyToGroupEnquiry(options);
        return idx(res, _ => _!.data!.replyToGroupEnquiry) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [replyToGroupEnquiry]
  );

  return handledMutationFn;
};
