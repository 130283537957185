import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';
import {
  CreateGroupResourcesMutation,
  CreateGroupResourcesMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import CREATE_GROUP_RESOURCES from './CreateGroupResourcesMutation.graphql';
import { useCallback } from 'react';
import idx from '../utils/idx';

type DataT = CreateGroupResourcesMutation;
type VariablesT = CreateGroupResourcesMutationVariables;

export type CreateGroupResourcesMutationFnT = MutationFunction<
  DataT,
  VariablesT
>;

export const useCreateGroupResourcesMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(CREATE_GROUP_RESOURCES, {
    ...options,
  });
};

// ============================================================================
// Mutation handler

type MutationOutputT = NonNullable<DataT['createGroupResources']>;

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledCreateGroupResourcesMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [createGroupResources] = useCreateGroupResourcesMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'CreateGroupResourcesMutationOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
        createdResources: [],
        validation: [],
        groupId: idx(options, _ => _.variables!.input.groupId) || '',
      };

      try {
        const res = await createGroupResources(options);
        return idx(res, _ => _!.data!.createGroupResources) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [createGroupResources]
  );

  return handledMutationFn;
};
