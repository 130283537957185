import { QueryHookOptions, useQuery } from 'react-apollo';

import { LastEventQuery as LastEventQueryT } from '../__generated/apollogen-types';
import GET_LAST_EVENT_QUERY from './LastEventQuery.graphql';
import { getOperationName } from 'apollo-link';

type DataT = LastEventQueryT;
type VariablesT = null;

export const useLastEventQuery = (
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery(GET_LAST_EVENT_QUERY, options);
};

export const getGroupListQueryName = () =>
  getOperationName(GET_LAST_EVENT_QUERY) || '';
