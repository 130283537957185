import * as React from 'react';
import { RouteComponentProps } from '@reach/router';

import analytics, { analyticsEvents } from '../../../utils/analytics';
import ReactLifecycles from '../../../components/ReactLifecycles';
import { AuthConsumer } from '../providers/AuthProvider';

export default function Logout(props: RouteComponentProps) {
  return (
    <AuthConsumer
      children={_auth => (
        <ReactLifecycles
          onComponentDidMount={() => {
            analytics.track(analyticsEvents.user.loggedOut, () => {
              analytics.reset();
              _auth.logout();
            });
          }}
        />
      )}
    />
  );
}
