import {
  CardPrivacyLevel,
  UpdateCardInput,
} from '../../../__generated/apollogen-types';

export type FormValues = UpdateCardInput & {
  acceptsTerms?: boolean;
  imageTransform?: {
    x?: string;
    y?: string;
    scale?: string;
  };
};

export const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  workTitle: '',
  email: '',
  address: '',
  phoneNumber: '',
  imageURL: '',
  biography: '',
  links: [
    { label: 'facebook', url: '' },
    { label: 'linkedin', url: '' },
    { label: 'twitter', url: '' },
    { label: 'instagram', url: '' },
    { label: 'website', url: '' },
    { label: 'document', url: '' },
  ],
  privacy: CardPrivacyLevel.PUBLIC,
  acceptsTerms: false,
};
