import * as React from 'react';
import { RouteComponentProps, navigate, Link } from '@reach/router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import idx from '../../../utils/idx';
import PageContainer from '../../../containers/PageContainer';
import { analyticsEvents } from '../../../utils/analytics';
import { captureException } from '../../../utils/raven';
import {
  useGroupInvitesForViewerQuery,
  getGroupInvitesForViewerQueryName,
} from '../../../queries/GroupInvitesForViewer';
import { getGroupListQueryName } from '../../../queries/GroupListQuery';
import { NavComponents } from '../../Navigation/HeaderNavigationComponents';
import { AuthConsumer } from '../../core/providers/AuthProvider';
import { GroupInvitesForViewer_groupInvitesForViewer_invites } from '../../../__generated/apollogen-types';
import { useHandledJoinGroupMutation } from '../../../queries/JoinGroupMutation';
import { useHandledDeclineGroupInvitationMutation } from '../../../queries/DeclineGroupInvitationMutation';

import Loader from '../../../components/Loader';
import { SiteBackground } from '../../../modules/app/containers/SiteBackground';
import { LoginSignup } from '../../../modules/card/components/SignUpLoginPostAuth';
import GroupCard from '../../../modules/groups/components/GroupCard';
import { ButtonWithLoadingState } from '../../../modules/ui/ButtonContainer';
import { colors } from '../../ui/theme';
import { useSnackbar } from '../../ui/Snackbar';

const loadMountainView = () =>
  import('../../../components/icons/MountainViewIcon');
const LazyMountainViewIcon = React.lazy(loadMountainView);

interface GroupInvitesRouteProps extends RouteComponentProps {
  groupInvites: GroupInvitesForViewer_groupInvitesForViewer_invites[];
  contentLoading?: boolean;
}

const GroupInvitationCard = (props: {
  joinGroup: (invitationId: string) => void;
  declineGroupInvitation: (invitationId: string) => void;
  invite: GroupInvitesForViewer_groupInvitesForViewer_invites;
}) => {
  const [buttonState, setbuttonState] = React.useState<
    'joining' | 'declining' | undefined
  >(undefined);
  const { title, imageThumbnailURL } = props.invite.invitedBy;

  return (
    <GroupCard group={props.invite.group}>
      <div className="flex content-center justify-start mb3">
        <img
          src={imageThumbnailURL}
          className="br-pill "
          style={{ height: '1.5rem', width: '1.5rem', objectFit: 'contain' }}
        />
        <span className="mv0 pl3 mingl-black">
          <span className="fw6">{title}</span>
          <span>{` wants you to join this group.`}</span>
        </span>
      </div>

      <div className="flex justify-around">
        <div className="w-40">
          <ButtonWithLoadingState
            text="Decline"
            mood="neutral"
            disabled={Boolean(buttonState)}
            loading={buttonState === 'declining'}
            onClick={() => {
              setbuttonState('declining');
              analytics.track(
                analyticsEvents.groupInvites.inviteDeclinedPressed
              );
              props.declineGroupInvitation(props.invite.id);
            }}
          />
        </div>

        <div className="w-40">
          <ButtonWithLoadingState
            text="Join"
            mood="positive"
            disabled={Boolean(buttonState)}
            loading={buttonState === 'joining'}
            onClick={() => {
              setbuttonState('joining');
              analytics.track(
                analyticsEvents.groupInvites.inviteAcceptedPressed
              );
              props.joinGroup(props.invite.id);
            }}
          />
        </div>
      </div>
    </GroupCard>
  );
};

export function GroupInvitesRoute(props: GroupInvitesRouteProps) {
  const snackbar = useSnackbar();

  const handledDeclineInvitation = useHandledDeclineGroupInvitationMutation();
  const handledJoinGroup = useHandledJoinGroupMutation();

  const declineGroupInvitation = async (inviteId: string) => {
    handledDeclineInvitation({
      variables: { input: { id: inviteId } },
      refetchQueries: [getGroupInvitesForViewerQueryName()],
    }).then(({ message, status }) => {
      snackbar.setSnackbar(message, status);
    });
  };
  const joinGroup = async (inviteId: string) => {
    handledJoinGroup({
      variables: { input: { id: inviteId } },
      refetchQueries: [
        getGroupInvitesForViewerQueryName(),
        getGroupListQueryName(),
      ],
    }).then(({ message, status }) => {
      snackbar.setSnackbar(message, status);
    });
  };

  const userHasNoInvitesScreen = () => (
    <div className="relative center" style={{ maxWidth: '26rem' }}>
      <CSSTransition
        key={'mountain'}
        timeout={200}
        in={props.groupInvites.length === 0}
        classNames="item"
        unmountOnExit={true}
      >
        <div className="absolute top-1 w-100 ph4">
          <React.Suspense
            fallback={
              <h2 className="mingl-light-black tc">
                You have no more group invites.
              </h2>
            }
          >
            <LazyMountainViewIcon size={'100%'} />
            <h3 className="mingl-light-black tc">
              You have no more group invites.
            </h3>
          </React.Suspense>
        </div>
      </CSSTransition>
    </div>
  );

  const userHasInvitesScreen = () => (
    <div style={{ maxWidth: '375px' }} className="w-100 relative center mv4">
      <TransitionGroup>
        {props.groupInvites.map(inviteData => (
          <CSSTransition
            key={inviteData.id}
            timeout={200}
            classNames="item"
            unmountOnExit={true}
          >
            <GroupInvitationCard
              key={inviteData.id}
              invite={inviteData}
              joinGroup={joinGroup}
              declineGroupInvitation={declineGroupInvitation}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
  return (
    <PageContainer
      mobileNavItem="/home"
      mobileTitle={<NavComponents.Title children="Group Invites" />}
    >
      <SiteBackground background={colors.blue800} />

      {props.contentLoading && (
        <div className="mt4">
          <Loader size={1} />
        </div>
      )}
      {!props.contentLoading && (
        <div className="flex-initial">
          <AuthConsumer>
            {authState => {
              if (!authState.isAuthenticated) {
                return (
                  <div className="flex flex-column justify-center items-center mt4">
                    <LoginSignup authState={authState} />
                  </div>
                );
              }
              return (
                <React.Fragment>
                  {userHasNoInvitesScreen()}
                  {userHasInvitesScreen()}
                </React.Fragment>
              );
            }}
          </AuthConsumer>
        </div>
      )}
    </PageContainer>
  );
}

export default function GrousInvitesContainer(props: RouteComponentProps) {
  const { data, loading } = useGroupInvitesForViewerQuery();
  const groupInvites = idx(data, _ => _!.groupInvitesForViewer!.invites) || [];

  return (
    <GroupInvitesRoute
      groupInvites={groupInvites}
      contentLoading={loading}
      {...props}
    />
  );
}
