/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminAddAlias
// ====================================================

export interface AdminAddAlias_adminAddAlias {
  __typename: "AdminAddAliasOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface AdminAddAlias {
  adminAddAlias: AdminAddAlias_adminAddAlias | null;
}

export interface AdminAddAliasVariables {
  input: AdminAddAliasInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminRemoveAlias
// ====================================================

export interface AdminRemoveAlias_adminRemoveAlias {
  __typename: "AdminRemoveAliasOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface AdminRemoveAlias {
  adminRemoveAlias: AdminRemoveAlias_adminRemoveAlias | null;
}

export interface AdminRemoveAliasVariables {
  input: AdminRemoveAliasInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdateCompany
// ====================================================

export interface AdminUpdateCompany_adminUpdateCompany_company {
  __typename: "Company";
  /**
   * The ID of an object
   */
  id: string;
  name: string;
  email: GQL_Email;
  about: string;
  isSearchable: boolean;
  website: GQL_URL;
  logoUrl: GQL_URL;
  publicFacebookUrl: string;
  publicInstagramUrl: string;
  publicLinkedinUrl: string;
  publicTwitterUrl: string;
  useCustomColors: boolean;
  primaryColor: string;
  phone: string;
  location: string;
  videoUrl: GQL_URL;
}

export interface AdminUpdateCompany_adminUpdateCompany {
  __typename: "AdminUpdateCompanyOutput";
  company: AdminUpdateCompany_adminUpdateCompany_company | null;
}

export interface AdminUpdateCompany {
  adminUpdateCompany: AdminUpdateCompany_adminUpdateCompany | null;
}

export interface AdminUpdateCompanyVariables {
  input: AdminUpdateCompanyInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdateUser
// ====================================================

export interface AdminUpdateUser_adminUpdateUser_user_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_methods = AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_methods_ContactInfo | AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_methods[];
  links: AdminUpdateUser_adminUpdateUser_user_cards_contactInfo_links[];
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface AdminUpdateUser_adminUpdateUser_user_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: AdminUpdateUser_adminUpdateUser_user_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: AdminUpdateUser_adminUpdateUser_user_cards_contactInfo;
  owner: AdminUpdateUser_adminUpdateUser_user_cards_owner | null;
  company: AdminUpdateUser_adminUpdateUser_user_cards_company | null;
  links: AdminUpdateUser_adminUpdateUser_user_cards_links[];
  theme: AdminUpdateUser_adminUpdateUser_user_cards_theme | null;
}

export interface AdminUpdateUser_adminUpdateUser_user {
  __typename: "User";
  /**
   * The ID of an object
   */
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  imageURL: GQL_URL;
  acceptsTerms: boolean;
  cards: AdminUpdateUser_adminUpdateUser_user_cards[];
}

export interface AdminUpdateUser_adminUpdateUser {
  __typename: "AdminUpdateUserOutput";
  user: AdminUpdateUser_adminUpdateUser_user | null;
}

export interface AdminUpdateUser {
  adminUpdateUser: AdminUpdateUser_adminUpdateUser | null;
}

export interface AdminUpdateUserVariables {
  input: AdminUpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addContacts
// ====================================================

export interface addContacts_addContacts_addedContacts_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface addContacts_addContacts_addedContacts_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface addContacts_addContacts_addedContacts_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type addContacts_addContacts_addedContacts_contactInfo_methods = addContacts_addContacts_addedContacts_contactInfo_methods_ContactInfo | addContacts_addContacts_addedContacts_contactInfo_methods_PhoneNumberContactInfo;

export interface addContacts_addContacts_addedContacts_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface addContacts_addContacts_addedContacts_contactInfo {
  __typename: "CardContactInfo";
  methods: addContacts_addContacts_addedContacts_contactInfo_methods[];
  links: addContacts_addContacts_addedContacts_contactInfo_links[];
}

export interface addContacts_addContacts_addedContacts_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface addContacts_addContacts_addedContacts_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface addContacts_addContacts_addedContacts_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface addContacts_addContacts_addedContacts_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface addContacts_addContacts_addedContacts {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: addContacts_addContacts_addedContacts_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: addContacts_addContacts_addedContacts_contactInfo;
  owner: addContacts_addContacts_addedContacts_owner | null;
  company: addContacts_addContacts_addedContacts_company | null;
  links: addContacts_addContacts_addedContacts_links[];
  theme: addContacts_addContacts_addedContacts_theme | null;
}

export interface addContacts_addContacts {
  __typename: "AddContactsOutput";
  status: RequestStatusEnum;
  message: string;
  addedContacts: addContacts_addContacts_addedContacts[];
}

export interface addContacts {
  addContacts: addContacts_addContacts | null;
}

export interface addContactsVariables {
  input: AddContactsInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddToGroup
// ====================================================

export interface AddToGroup_addToGroup_added_location {
  __typename: "GeoPosition";
  longitude: number;
  latitude: number;
}

export interface AddToGroup_addToGroup_added {
  __typename: "GroupItem";
  /**
   * The id of the group item, eg. the relation that ties a node to a group
   */
  id: string;
  /**
   * The id of the node linked to the group
   */
  nodeId: string;
  groupId: string;
  createdAt: GQL_Date;
  location: AddToGroup_addToGroup_added_location | null;
}

export interface AddToGroup_addToGroup_removed {
  __typename: "RemovedGroupItem";
  id: string;
  groupId: string;
}

export interface AddToGroup_addToGroup {
  __typename: "AddToGroupOutput";
  added: AddToGroup_addToGroup_added[];
  removed: AddToGroup_addToGroup_removed[];
}

export interface AddToGroup {
  /**
   * Add cards to groups
   */
  addToGroup: AddToGroup_addToGroup | null;
}

export interface AddToGroupVariables {
  input: AddToGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminAssignToGroup
// ====================================================

export interface AdminAssignToGroup_adminAssignToGroup_group_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface AdminAssignToGroup_adminAssignToGroup_group_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface AdminAssignToGroup_adminAssignToGroup_group_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface AdminAssignToGroup_adminAssignToGroup_group {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: AdminAssignToGroup_adminAssignToGroup_group_aliases[];
  permissions: AdminAssignToGroup_adminAssignToGroup_group_permissions;
  settings: AdminAssignToGroup_adminAssignToGroup_group_settings;
}

export interface AdminAssignToGroup_adminAssignToGroup {
  __typename: "AdminAssignToGroupMutationOutput";
  status: RequestStatusEnum;
  message: string;
  group: AdminAssignToGroup_adminAssignToGroup_group | null;
}

export interface AdminAssignToGroup {
  adminAssignToGroup: AdminAssignToGroup_adminAssignToGroup | null;
}

export interface AdminAssignToGroupVariables {
  input: AdminAssignToGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCard
// ====================================================

export interface UpdateCard_updateCard_card_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface UpdateCard_updateCard_card_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface UpdateCard_updateCard_card_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type UpdateCard_updateCard_card_contactInfo_methods = UpdateCard_updateCard_card_contactInfo_methods_ContactInfo | UpdateCard_updateCard_card_contactInfo_methods_PhoneNumberContactInfo;

export interface UpdateCard_updateCard_card_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface UpdateCard_updateCard_card_contactInfo {
  __typename: "CardContactInfo";
  methods: UpdateCard_updateCard_card_contactInfo_methods[];
  links: UpdateCard_updateCard_card_contactInfo_links[];
}

export interface UpdateCard_updateCard_card_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface UpdateCard_updateCard_card_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface UpdateCard_updateCard_card_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface UpdateCard_updateCard_card_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface UpdateCard_updateCard_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: UpdateCard_updateCard_card_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: UpdateCard_updateCard_card_contactInfo;
  owner: UpdateCard_updateCard_card_owner | null;
  company: UpdateCard_updateCard_card_company | null;
  links: UpdateCard_updateCard_card_links[];
  theme: UpdateCard_updateCard_card_theme | null;
}

export interface UpdateCard_updateCard {
  __typename: "UpdateCardOutput";
  card: UpdateCard_updateCard_card;
}

export interface UpdateCard {
  updateCard: UpdateCard_updateCard | null;
}

export interface UpdateCardVariables {
  input: UpdateCardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CardSearch
// ====================================================

export interface CardSearch_cardSearch_edges_node_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface CardSearch_cardSearch_edges_node_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface CardSearch_cardSearch_edges_node_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type CardSearch_cardSearch_edges_node_contactInfo_methods = CardSearch_cardSearch_edges_node_contactInfo_methods_ContactInfo | CardSearch_cardSearch_edges_node_contactInfo_methods_PhoneNumberContactInfo;

export interface CardSearch_cardSearch_edges_node_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface CardSearch_cardSearch_edges_node_contactInfo {
  __typename: "CardContactInfo";
  methods: CardSearch_cardSearch_edges_node_contactInfo_methods[];
  links: CardSearch_cardSearch_edges_node_contactInfo_links[];
}

export interface CardSearch_cardSearch_edges_node_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface CardSearch_cardSearch_edges_node_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface CardSearch_cardSearch_edges_node_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface CardSearch_cardSearch_edges_node_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface CardSearch_cardSearch_edges_node {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: CardSearch_cardSearch_edges_node_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: CardSearch_cardSearch_edges_node_contactInfo;
  owner: CardSearch_cardSearch_edges_node_owner | null;
  company: CardSearch_cardSearch_edges_node_company | null;
  links: CardSearch_cardSearch_edges_node_links[];
  theme: CardSearch_cardSearch_edges_node_theme | null;
}

export interface CardSearch_cardSearch_edges {
  __typename: "CardEdge";
  cursor: string;
  node: CardSearch_cardSearch_edges_node;
}

export interface CardSearch_cardSearch_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface CardSearch_cardSearch {
  __typename: "CardConnection";
  edges: CardSearch_cardSearch_edges[];
  pageInfo: CardSearch_cardSearch_pageInfo;
}

export interface CardSearch {
  cardSearch: CardSearch_cardSearch;
}

export interface CardSearchVariables {
  search: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: claimAlias
// ====================================================

export interface claimAlias_claimAlias {
  __typename: "ClaimAliasOutput";
  aliases: (string | null)[];
  claimedAlias: string | null;
}

export interface claimAlias {
  claimAlias: claimAlias_claimAlias | null;
}

export interface claimAliasVariables {
  input: ClaimAliasInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyList
// ====================================================

export interface CompanyList_companiesList_edges_node {
  __typename: "Company";
  /**
   * The ID of an object
   */
  id: string;
  name: string;
}

export interface CompanyList_companiesList_edges {
  __typename: "CompanyEdge";
  cursor: string;
  node: CompanyList_companiesList_edges_node;
}

export interface CompanyList_companiesList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface CompanyList_companiesList {
  __typename: "CompanyList";
  edges: CompanyList_companiesList_edges[];
  pageInfo: CompanyList_companiesList_pageInfo;
}

export interface CompanyList {
  companiesList: CompanyList_companiesList;
}

export interface CompanyListVariables {
  input: CompaniesListInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactInfoDefinitionsQuery
// ====================================================

export interface ContactInfoDefinitionsQuery_contactInfoDefinitions_links {
  __typename: "ContactInfoDefinition";
  platform: ContactPlatformsEnum;
  displayName: string;
  icon: string;
  inputType: ContactInfoInputEnum;
  labelType: ContactInfoLabelEnum;
  type: ContactInfoTypeEnum;
  countryCode: string | null;
  helpText: string | null;
  helpRedirect: string | null;
}

export interface ContactInfoDefinitionsQuery_contactInfoDefinitions_methods {
  __typename: "ContactInfoDefinition";
  platform: ContactPlatformsEnum;
  displayName: string;
  icon: string;
  inputType: ContactInfoInputEnum;
  labelType: ContactInfoLabelEnum;
  type: ContactInfoTypeEnum;
  countryCode: string | null;
  helpText: string | null;
  helpRedirect: string | null;
}

export interface ContactInfoDefinitionsQuery_contactInfoDefinitions {
  __typename: "ContactInfoDefinitionsOutput";
  links: ContactInfoDefinitionsQuery_contactInfoDefinitions_links[];
  methods: ContactInfoDefinitionsQuery_contactInfoDefinitions_methods[];
}

export interface ContactInfoDefinitionsQuery {
  contactInfoDefinitions: ContactInfoDefinitionsQuery_contactInfoDefinitions | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactsQuery
// ====================================================

export interface ContactsQuery_contacts_edges_node_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface ContactsQuery_contacts_edges_node_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface ContactsQuery_contacts_edges_node_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type ContactsQuery_contacts_edges_node_contactInfo_methods = ContactsQuery_contacts_edges_node_contactInfo_methods_ContactInfo | ContactsQuery_contacts_edges_node_contactInfo_methods_PhoneNumberContactInfo;

export interface ContactsQuery_contacts_edges_node_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface ContactsQuery_contacts_edges_node_contactInfo {
  __typename: "CardContactInfo";
  methods: ContactsQuery_contacts_edges_node_contactInfo_methods[];
  links: ContactsQuery_contacts_edges_node_contactInfo_links[];
}

export interface ContactsQuery_contacts_edges_node_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface ContactsQuery_contacts_edges_node_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface ContactsQuery_contacts_edges_node_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface ContactsQuery_contacts_edges_node_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface ContactsQuery_contacts_edges_node {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: ContactsQuery_contacts_edges_node_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: ContactsQuery_contacts_edges_node_contactInfo;
  owner: ContactsQuery_contacts_edges_node_owner | null;
  company: ContactsQuery_contacts_edges_node_company | null;
  links: ContactsQuery_contacts_edges_node_links[];
  theme: ContactsQuery_contacts_edges_node_theme | null;
}

export interface ContactsQuery_contacts_edges {
  __typename: "CardEdge";
  node: ContactsQuery_contacts_edges_node;
  cursor: string;
}

export interface ContactsQuery_contacts_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ContactsQuery_contacts {
  __typename: "ContactsQueryOutput";
  status: RequestStatusEnum;
  message: string | null;
  edges: ContactsQuery_contacts_edges[];
  pageInfo: ContactsQuery_contacts_pageInfo;
}

export interface ContactsQuery {
  contacts: ContactsQuery_contacts | null;
}

export interface ContactsQueryVariables {
  input: ContactsQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContactInfoMutation
// ====================================================

export interface CreateContactInfoMutation_createContactInfo_validation {
  __typename: "CreateContactInfoValidation";
  platform: string | null;
  value: string | null;
  label: string | null;
  dialingCode: string | null;
  countryCode: string | null;
  nationalNumber: string | null;
}

export interface CreateContactInfoMutation_createContactInfo {
  __typename: "CreateContactInfoMutationOutput";
  status: RequestStatusEnum;
  message: string;
  validation: (CreateContactInfoMutation_createContactInfo_validation | null)[];
}

export interface CreateContactInfoMutation {
  createContactInfo: CreateContactInfoMutation_createContactInfo | null;
}

export interface CreateContactInfoMutationVariables {
  input: CreateContactInfoMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGroupEnquiryMutation
// ====================================================

export interface CreateGroupEnquiryMutation_createGroupEnquiry {
  __typename: "CreateGroupEnquiryMutationOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface CreateGroupEnquiryMutation {
  createGroupEnquiry: CreateGroupEnquiryMutation_createGroupEnquiry;
}

export interface CreateGroupEnquiryMutationVariables {
  input: CreateGroupEnquiryMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGroup
// ====================================================

export interface CreateGroup_createGroup_createdGroup_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface CreateGroup_createGroup_createdGroup_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface CreateGroup_createGroup_createdGroup_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface CreateGroup_createGroup_createdGroup {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: CreateGroup_createGroup_createdGroup_aliases[];
  permissions: CreateGroup_createGroup_createdGroup_permissions;
  settings: CreateGroup_createGroup_createdGroup_settings;
}

export interface CreateGroup_createGroup {
  __typename: "CreateGroupOutput";
  status: RequestStatusEnum;
  message: string;
  createdGroup: CreateGroup_createGroup_createdGroup | null;
}

export interface CreateGroup {
  createGroup: CreateGroup_createGroup | null;
}

export interface CreateGroupVariables {
  input: CreateGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGroupResourcesMutation
// ====================================================

export interface CreateGroupResourcesMutation_createGroupResources_createdResources {
  __typename: "Resource";
  id: string;
  type: ResourceTypeEnum;
  /**
   * Formatted and ready for use
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  /**
   * Web uri for this resource
   */
  href: string;
}

export interface CreateGroupResourcesMutation_createGroupResources_validation {
  __typename: "CreateGroupResourcesValidation";
  label: string | null;
  value: string | null;
}

export interface CreateGroupResourcesMutation_createGroupResources {
  __typename: "CreateGroupResourcesMutationOutput";
  status: RequestStatusEnum;
  message: string;
  groupId: string;
  createdResources: CreateGroupResourcesMutation_createGroupResources_createdResources[];
  validation: (CreateGroupResourcesMutation_createGroupResources_validation | null)[];
}

export interface CreateGroupResourcesMutation {
  createGroupResources: CreateGroupResourcesMutation_createGroupResources | null;
}

export interface CreateGroupResourcesMutationVariables {
  input: CreateGroupResourcesMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineGroupInvitation
// ====================================================

export interface DeclineGroupInvitation_declineGroupInvitation {
  __typename: "DeclineGroupInvitationMutationOutput";
  status: RequestStatusEnum;
  message: string;
  deletedId: string | null;
  groupId: string | null;
}

export interface DeclineGroupInvitation {
  declineGroupInvitation: DeclineGroupInvitation_declineGroupInvitation | null;
}

export interface DeclineGroupInvitationVariables {
  input: DeclineGroupInvitationMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContactInfoMutation
// ====================================================

export interface DeleteContactInfoMutation_deleteContactInfo {
  __typename: "DeleteContactInfoMutationOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface DeleteContactInfoMutation {
  deleteContactInfo: DeleteContactInfoMutation_deleteContactInfo | null;
}

export interface DeleteContactInfoMutationVariables {
  input: DeleteContactInfoMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteItem
// ====================================================

export interface DeleteItem_deleteItem {
  __typename: "DeleteItemOutput";
  status: RequestStatusEnum;
  message: string;
  deletedId: string | null;
  deletedType: DeletedItemType | null;
  groupId: string | null;
}

export interface DeleteItem {
  deleteItem: DeleteItem_deleteItem | null;
}

export interface DeleteItemVariables {
  input: DeleteItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUsers
// ====================================================

export interface DeleteUsers_deleteUsers {
  __typename: "DeleteUsersMutationOutput";
  status: RequestStatusEnum;
  message: string;
  deletedUserIds: string[];
}

export interface DeleteUsers {
  deleteUsers: DeleteUsers_deleteUsers | null;
}

export interface DeleteUsersVariables {
  input: DeleteUsersMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNode
// ====================================================

export interface GetNode_node_GroupInvite {
  __typename: "GroupInvite" | "ClaimableAlias";
  /**
   * The id of the object.
   */
  id: string;
}

export interface GetNode_node_Company_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GetNode_node_Company_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNode_node_Company_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GetNode_node_Company_cards_contactInfo_methods = GetNode_node_Company_cards_contactInfo_methods_ContactInfo | GetNode_node_Company_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface GetNode_node_Company_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNode_node_Company_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: GetNode_node_Company_cards_contactInfo_methods[];
  links: GetNode_node_Company_cards_contactInfo_links[];
}

export interface GetNode_node_Company_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GetNode_node_Company_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GetNode_node_Company_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GetNode_node_Company_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GetNode_node_Company_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GetNode_node_Company_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GetNode_node_Company_cards_contactInfo;
  owner: GetNode_node_Company_cards_owner | null;
  company: GetNode_node_Company_cards_company | null;
  links: GetNode_node_Company_cards_links[];
  theme: GetNode_node_Company_cards_theme | null;
}

export interface GetNode_node_Company {
  __typename: "Company";
  /**
   * The ID of an object
   */
  id: string;
  name: string;
  email: GQL_Email;
  about: string;
  website: GQL_URL;
  publicFacebookUrl: string;
  publicInstagramUrl: string;
  publicLinkedinUrl: string;
  publicTwitterUrl: string;
  useCustomColors: boolean;
  primaryColor: string;
  phone: string;
  location: string;
  videoUrl: GQL_URL;
  logoUrl: GQL_URL;
  isSearchable: boolean;
  cards: GetNode_node_Company_cards[];
}

export interface GetNode_node_User_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GetNode_node_User_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNode_node_User_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GetNode_node_User_cards_contactInfo_methods = GetNode_node_User_cards_contactInfo_methods_ContactInfo | GetNode_node_User_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface GetNode_node_User_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNode_node_User_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: GetNode_node_User_cards_contactInfo_methods[];
  links: GetNode_node_User_cards_contactInfo_links[];
}

export interface GetNode_node_User_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GetNode_node_User_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GetNode_node_User_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GetNode_node_User_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GetNode_node_User_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GetNode_node_User_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GetNode_node_User_cards_contactInfo;
  owner: GetNode_node_User_cards_owner | null;
  company: GetNode_node_User_cards_company | null;
  links: GetNode_node_User_cards_links[];
  theme: GetNode_node_User_cards_theme | null;
}

export interface GetNode_node_User {
  __typename: "User";
  /**
   * The ID of an object
   */
  id: string;
  alias: string | null;
  userId: string | null;
  firstName: string;
  lastName: string;
  fullName: string;
  acceptsTerms: boolean;
  cards: GetNode_node_User_cards[];
}

export interface GetNode_node_VCard_homeAddress {
  __typename: "HomeAddress";
  street: string;
}

export interface GetNode_node_VCard {
  __typename: "VCard";
  /**
   * The ID of an object
   */
  id: string;
  firstName: string;
  lastName: string;
  email: GQL_Email;
  cellPhone: string | null;
  photo: string | null;
  organization: string | null;
  title: string;
  url: string;
  homeAddress: GetNode_node_VCard_homeAddress | null;
}

export interface GetNode_node_Card_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GetNode_node_Card_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNode_node_Card_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GetNode_node_Card_contactInfo_methods = GetNode_node_Card_contactInfo_methods_ContactInfo | GetNode_node_Card_contactInfo_methods_PhoneNumberContactInfo;

export interface GetNode_node_Card_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNode_node_Card_contactInfo {
  __typename: "CardContactInfo";
  methods: GetNode_node_Card_contactInfo_methods[];
  links: GetNode_node_Card_contactInfo_links[];
}

export interface GetNode_node_Card_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GetNode_node_Card_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GetNode_node_Card_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GetNode_node_Card_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GetNode_node_Card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GetNode_node_Card_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GetNode_node_Card_contactInfo;
  owner: GetNode_node_Card_owner | null;
  company: GetNode_node_Card_company | null;
  links: GetNode_node_Card_links[];
  theme: GetNode_node_Card_theme | null;
}

export interface GetNode_node_Group_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface GetNode_node_Group_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface GetNode_node_Group_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface GetNode_node_Group {
  __typename: "Group";
  /**
   * The id of the object.
   */
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: GetNode_node_Group_aliases[];
  permissions: GetNode_node_Group_permissions;
  settings: GetNode_node_Group_settings;
}

export type GetNode_node = GetNode_node_GroupInvite | GetNode_node_Company | GetNode_node_User | GetNode_node_VCard | GetNode_node_Card | GetNode_node_Group;

export interface GetNode {
  /**
   * Fetches an object given its ID
   */
  node: GetNode_node | null;
}

export interface GetNodeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNodes
// ====================================================

export interface GetNodes_nodes_Group {
  __typename: "Group" | "GroupInvite" | "ClaimableAlias";
  /**
   * The id of the object.
   */
  id: string;
}

export interface GetNodes_nodes_Company_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GetNodes_nodes_Company_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNodes_nodes_Company_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GetNodes_nodes_Company_cards_contactInfo_methods = GetNodes_nodes_Company_cards_contactInfo_methods_ContactInfo | GetNodes_nodes_Company_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface GetNodes_nodes_Company_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNodes_nodes_Company_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: GetNodes_nodes_Company_cards_contactInfo_methods[];
  links: GetNodes_nodes_Company_cards_contactInfo_links[];
}

export interface GetNodes_nodes_Company_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GetNodes_nodes_Company_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GetNodes_nodes_Company_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GetNodes_nodes_Company_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GetNodes_nodes_Company_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GetNodes_nodes_Company_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GetNodes_nodes_Company_cards_contactInfo;
  owner: GetNodes_nodes_Company_cards_owner | null;
  company: GetNodes_nodes_Company_cards_company | null;
  links: GetNodes_nodes_Company_cards_links[];
  theme: GetNodes_nodes_Company_cards_theme | null;
}

export interface GetNodes_nodes_Company {
  __typename: "Company";
  /**
   * The ID of an object
   */
  id: string;
  name: string;
  email: GQL_Email;
  about: string;
  website: GQL_URL;
  publicFacebookUrl: string;
  publicInstagramUrl: string;
  publicLinkedinUrl: string;
  publicTwitterUrl: string;
  useCustomColors: boolean;
  primaryColor: string;
  phone: string;
  location: string;
  videoUrl: GQL_URL;
  logoUrl: GQL_URL;
  isSearchable: boolean;
  cards: GetNodes_nodes_Company_cards[];
}

export interface GetNodes_nodes_User_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GetNodes_nodes_User_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNodes_nodes_User_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GetNodes_nodes_User_cards_contactInfo_methods = GetNodes_nodes_User_cards_contactInfo_methods_ContactInfo | GetNodes_nodes_User_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface GetNodes_nodes_User_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNodes_nodes_User_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: GetNodes_nodes_User_cards_contactInfo_methods[];
  links: GetNodes_nodes_User_cards_contactInfo_links[];
}

export interface GetNodes_nodes_User_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GetNodes_nodes_User_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GetNodes_nodes_User_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GetNodes_nodes_User_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GetNodes_nodes_User_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GetNodes_nodes_User_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GetNodes_nodes_User_cards_contactInfo;
  owner: GetNodes_nodes_User_cards_owner | null;
  company: GetNodes_nodes_User_cards_company | null;
  links: GetNodes_nodes_User_cards_links[];
  theme: GetNodes_nodes_User_cards_theme | null;
}

export interface GetNodes_nodes_User {
  __typename: "User";
  /**
   * The ID of an object
   */
  id: string;
  alias: string | null;
  userId: string | null;
  firstName: string;
  lastName: string;
  fullName: string;
  acceptsTerms: boolean;
  cards: GetNodes_nodes_User_cards[];
}

export interface GetNodes_nodes_VCard_homeAddress {
  __typename: "HomeAddress";
  street: string;
}

export interface GetNodes_nodes_VCard {
  __typename: "VCard";
  /**
   * The ID of an object
   */
  id: string;
  firstName: string;
  lastName: string;
  email: GQL_Email;
  cellPhone: string | null;
  photo: string | null;
  organization: string | null;
  title: string;
  url: string;
  homeAddress: GetNodes_nodes_VCard_homeAddress | null;
}

export interface GetNodes_nodes_Card_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GetNodes_nodes_Card_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNodes_nodes_Card_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GetNodes_nodes_Card_contactInfo_methods = GetNodes_nodes_Card_contactInfo_methods_ContactInfo | GetNodes_nodes_Card_contactInfo_methods_PhoneNumberContactInfo;

export interface GetNodes_nodes_Card_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GetNodes_nodes_Card_contactInfo {
  __typename: "CardContactInfo";
  methods: GetNodes_nodes_Card_contactInfo_methods[];
  links: GetNodes_nodes_Card_contactInfo_links[];
}

export interface GetNodes_nodes_Card_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GetNodes_nodes_Card_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GetNodes_nodes_Card_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GetNodes_nodes_Card_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GetNodes_nodes_Card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GetNodes_nodes_Card_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GetNodes_nodes_Card_contactInfo;
  owner: GetNodes_nodes_Card_owner | null;
  company: GetNodes_nodes_Card_company | null;
  links: GetNodes_nodes_Card_links[];
  theme: GetNodes_nodes_Card_theme | null;
}

export type GetNodes_nodes = GetNodes_nodes_Group | GetNodes_nodes_Company | GetNodes_nodes_User | GetNodes_nodes_VCard | GetNodes_nodes_Card;

export interface GetNodes {
  /**
   * Fetches objects given their IDs
   */
  nodes: (GetNodes_nodes | null)[];
}

export interface GetNodesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupAdmins
// ====================================================

export interface GroupAdmins_group_admins_edges_node_card_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GroupAdmins_group_admins_edges_node_card_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupAdmins_group_admins_edges_node_card_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GroupAdmins_group_admins_edges_node_card_contactInfo_methods = GroupAdmins_group_admins_edges_node_card_contactInfo_methods_ContactInfo | GroupAdmins_group_admins_edges_node_card_contactInfo_methods_PhoneNumberContactInfo;

export interface GroupAdmins_group_admins_edges_node_card_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupAdmins_group_admins_edges_node_card_contactInfo {
  __typename: "CardContactInfo";
  methods: GroupAdmins_group_admins_edges_node_card_contactInfo_methods[];
  links: GroupAdmins_group_admins_edges_node_card_contactInfo_links[];
}

export interface GroupAdmins_group_admins_edges_node_card_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GroupAdmins_group_admins_edges_node_card_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GroupAdmins_group_admins_edges_node_card_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GroupAdmins_group_admins_edges_node_card_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GroupAdmins_group_admins_edges_node_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GroupAdmins_group_admins_edges_node_card_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GroupAdmins_group_admins_edges_node_card_contactInfo;
  owner: GroupAdmins_group_admins_edges_node_card_owner | null;
  company: GroupAdmins_group_admins_edges_node_card_company | null;
  links: GroupAdmins_group_admins_edges_node_card_links[];
  theme: GroupAdmins_group_admins_edges_node_card_theme | null;
}

export interface GroupAdmins_group_admins_edges_node {
  __typename: "GroupMember";
  id: string;
  role: GroupMemberRole;
  createdAt: GQL_Date;
  card: GroupAdmins_group_admins_edges_node_card;
}

export interface GroupAdmins_group_admins_edges {
  __typename: "GroupMemberEdge";
  node: GroupAdmins_group_admins_edges_node;
}

export interface GroupAdmins_group_admins {
  __typename: "GroupMemberConnection";
  totalCount: number;
  edges: GroupAdmins_group_admins_edges[];
}

export interface GroupAdmins_group {
  __typename: "Group";
  id: string;
  admins: GroupAdmins_group_admins;
}

export interface GroupAdmins {
  /**
   * Query a single group by id
   */
  group: GroupAdmins_group | null;
}

export interface GroupAdminsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupEnquiries
// ====================================================

export interface GroupEnquiries_group_permissions {
  __typename: "GroupPermissions";
  viewerCanCreateEnquiry: boolean;
  viewerCanRemoveEnquiries: boolean;
}

export interface GroupEnquiries_group_viewersOwnGroupEnquiry_card_owner {
  __typename: "CardOwner";
  id: string;
  isViewer: boolean;
}

export interface GroupEnquiries_group_viewersOwnGroupEnquiry_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  imageThumbnailURL: GQL_URL;
  isContact: boolean;
  qrShareURL: GQL_URL;
  directChatId: string | null;
  owner: GroupEnquiries_group_viewersOwnGroupEnquiry_card_owner | null;
}

export interface GroupEnquiries_group_viewersOwnGroupEnquiry {
  __typename: "GroupEnquiry";
  id: string;
  text: string;
  viewerCanUpdate: boolean;
  viewerCanRemove: boolean;
  viewerCanRespond: boolean;
  card: GroupEnquiries_group_viewersOwnGroupEnquiry_card;
}

export interface GroupEnquiries_group_enquiries_edges_node_card_owner {
  __typename: "CardOwner";
  id: string;
  isViewer: boolean;
}

export interface GroupEnquiries_group_enquiries_edges_node_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  imageThumbnailURL: GQL_URL;
  isContact: boolean;
  qrShareURL: GQL_URL;
  directChatId: string | null;
  owner: GroupEnquiries_group_enquiries_edges_node_card_owner | null;
}

export interface GroupEnquiries_group_enquiries_edges_node {
  __typename: "GroupEnquiry";
  id: string;
  text: string;
  viewerCanUpdate: boolean;
  viewerCanRemove: boolean;
  viewerCanRespond: boolean;
  card: GroupEnquiries_group_enquiries_edges_node_card;
}

export interface GroupEnquiries_group_enquiries_edges {
  __typename: "GroupEnquiryEdge";
  cursor: string;
  node: GroupEnquiries_group_enquiries_edges_node;
}

export interface GroupEnquiries_group_enquiries {
  __typename: "GroupEnquiries";
  totalCount: number;
  edges: GroupEnquiries_group_enquiries_edges[];
}

export interface GroupEnquiries_group {
  __typename: "Group";
  id: string;
  permissions: GroupEnquiries_group_permissions;
  viewersOwnGroupEnquiry: GroupEnquiries_group_viewersOwnGroupEnquiry | null;
  enquiries: GroupEnquiries_group_enquiries;
}

export interface GroupEnquiries {
  /**
   * Query a single group by id
   */
  group: GroupEnquiries_group | null;
}

export interface GroupEnquiriesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupInvitesForViewer
// ====================================================

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_methods = GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_methods_ContactInfo | GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_methods_PhoneNumberContactInfo;

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo {
  __typename: "CardContactInfo";
  methods: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_methods[];
  links: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo_links[];
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_contactInfo;
  owner: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_owner | null;
  company: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_company | null;
  links: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_links[];
  theme: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy_theme | null;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_methods = GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_methods_ContactInfo | GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_methods_PhoneNumberContactInfo;

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo {
  __typename: "CardContactInfo";
  methods: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_methods[];
  links: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo_links[];
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_contactInfo;
  owner: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_owner | null;
  company: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_company | null;
  links: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_links[];
  theme: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser_theme | null;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_group_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_group_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_group_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites_group {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: GroupInvitesForViewer_groupInvitesForViewer_invites_group_aliases[];
  permissions: GroupInvitesForViewer_groupInvitesForViewer_invites_group_permissions;
  settings: GroupInvitesForViewer_groupInvitesForViewer_invites_group_settings;
}

export interface GroupInvitesForViewer_groupInvitesForViewer_invites {
  __typename: "GroupInvite";
  id: string;
  createdAt: GQL_Date;
  invitedBy: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedBy;
  invitedUser: GroupInvitesForViewer_groupInvitesForViewer_invites_invitedUser | null;
  invitedEmail: string | null;
  group: GroupInvitesForViewer_groupInvitesForViewer_invites_group;
}

export interface GroupInvitesForViewer_groupInvitesForViewer {
  __typename: "GroupInvitesForViewerQueryOutput";
  status: RequestStatusEnum;
  message: string | null;
  invites: GroupInvitesForViewer_groupInvitesForViewer_invites[];
}

export interface GroupInvitesForViewer {
  groupInvitesForViewer: GroupInvitesForViewer_groupInvitesForViewer | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupItemsQuery
// ====================================================

export interface GroupItemsQuery_groupItems_edges_node_card_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GroupItemsQuery_groupItems_edges_node_card_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupItemsQuery_groupItems_edges_node_card_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GroupItemsQuery_groupItems_edges_node_card_contactInfo_methods = GroupItemsQuery_groupItems_edges_node_card_contactInfo_methods_ContactInfo | GroupItemsQuery_groupItems_edges_node_card_contactInfo_methods_PhoneNumberContactInfo;

export interface GroupItemsQuery_groupItems_edges_node_card_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupItemsQuery_groupItems_edges_node_card_contactInfo {
  __typename: "CardContactInfo";
  methods: GroupItemsQuery_groupItems_edges_node_card_contactInfo_methods[];
  links: GroupItemsQuery_groupItems_edges_node_card_contactInfo_links[];
}

export interface GroupItemsQuery_groupItems_edges_node_card_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GroupItemsQuery_groupItems_edges_node_card_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GroupItemsQuery_groupItems_edges_node_card_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GroupItemsQuery_groupItems_edges_node_card_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GroupItemsQuery_groupItems_edges_node_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GroupItemsQuery_groupItems_edges_node_card_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GroupItemsQuery_groupItems_edges_node_card_contactInfo;
  owner: GroupItemsQuery_groupItems_edges_node_card_owner | null;
  company: GroupItemsQuery_groupItems_edges_node_card_company | null;
  links: GroupItemsQuery_groupItems_edges_node_card_links[];
  theme: GroupItemsQuery_groupItems_edges_node_card_theme | null;
}

export interface GroupItemsQuery_groupItems_edges_node {
  __typename: "GroupItem";
  /**
   * The id of the group item, eg. the relation that ties a node to a group
   */
  id: string;
  /**
   * The id of the node linked to the group
   */
  nodeId: string;
  createdAt: GQL_Date;
  card: GroupItemsQuery_groupItems_edges_node_card;
}

export interface GroupItemsQuery_groupItems_edges {
  __typename: "GroupItemEdge";
  cursor: string;
  node: GroupItemsQuery_groupItems_edges_node;
}

export interface GroupItemsQuery_groupItems_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface GroupItemsQuery_groupItems {
  __typename: "GroupItemConnection";
  edges: GroupItemsQuery_groupItems_edges[];
  totalCount: number;
  pageInfo: GroupItemsQuery_groupItems_pageInfo;
}

export interface GroupItemsQuery {
  groupItems: GroupItemsQuery_groupItems;
}

export interface GroupItemsQueryVariables {
  input: CardsInGroupQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupListQuery
// ====================================================

export interface GroupListQuery_groups_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface GroupListQuery_groups_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface GroupListQuery_groups_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface GroupListQuery_groups {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: GroupListQuery_groups_aliases[];
  permissions: GroupListQuery_groups_permissions;
  settings: GroupListQuery_groups_settings;
}

export interface GroupListQuery {
  groups: GroupListQuery_groups[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupMembersQuery
// ====================================================

export interface GroupMembersQuery_groupMembers_edges_node_card_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface GroupMembersQuery_groupMembers_edges_node_card_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupMembersQuery_groupMembers_edges_node_card_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type GroupMembersQuery_groupMembers_edges_node_card_contactInfo_methods = GroupMembersQuery_groupMembers_edges_node_card_contactInfo_methods_ContactInfo | GroupMembersQuery_groupMembers_edges_node_card_contactInfo_methods_PhoneNumberContactInfo;

export interface GroupMembersQuery_groupMembers_edges_node_card_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface GroupMembersQuery_groupMembers_edges_node_card_contactInfo {
  __typename: "CardContactInfo";
  methods: GroupMembersQuery_groupMembers_edges_node_card_contactInfo_methods[];
  links: GroupMembersQuery_groupMembers_edges_node_card_contactInfo_links[];
}

export interface GroupMembersQuery_groupMembers_edges_node_card_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface GroupMembersQuery_groupMembers_edges_node_card_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface GroupMembersQuery_groupMembers_edges_node_card_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface GroupMembersQuery_groupMembers_edges_node_card_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface GroupMembersQuery_groupMembers_edges_node_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: GroupMembersQuery_groupMembers_edges_node_card_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: GroupMembersQuery_groupMembers_edges_node_card_contactInfo;
  owner: GroupMembersQuery_groupMembers_edges_node_card_owner | null;
  company: GroupMembersQuery_groupMembers_edges_node_card_company | null;
  links: GroupMembersQuery_groupMembers_edges_node_card_links[];
  theme: GroupMembersQuery_groupMembers_edges_node_card_theme | null;
}

export interface GroupMembersQuery_groupMembers_edges_node {
  __typename: "GroupMember";
  id: string;
  role: GroupMemberRole;
  card: GroupMembersQuery_groupMembers_edges_node_card;
}

export interface GroupMembersQuery_groupMembers_edges {
  __typename: "GroupMemberEdge";
  cursor: string;
  node: GroupMembersQuery_groupMembers_edges_node;
}

export interface GroupMembersQuery_groupMembers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface GroupMembersQuery_groupMembers {
  __typename: "GroupMemberConnection";
  edges: GroupMembersQuery_groupMembers_edges[];
  totalCount: number;
  pageInfo: GroupMembersQuery_groupMembers_pageInfo;
}

export interface GroupMembersQuery {
  groupMembers: GroupMembersQuery_groupMembers | null;
}

export interface GroupMembersQueryVariables {
  input: GroupMembersQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupResourcesQuery
// ====================================================

export interface GroupResourcesQuery_groupResources_edges_node {
  __typename: "Resource";
  id: string;
  type: ResourceTypeEnum;
  /**
   * Formatted and ready for use
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  /**
   * Web uri to image
   */
  icon: string;
  /**
   * User specified label
   */
  label: string;
  /**
   * Web uri for this resource
   */
  href: string;
}

export interface GroupResourcesQuery_groupResources_edges {
  __typename: "GroupResourceEdge";
  cursor: string;
  node: GroupResourcesQuery_groupResources_edges_node;
}

export interface GroupResourcesQuery_groupResources_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface GroupResourcesQuery_groupResources {
  __typename: "GroupResources";
  edges: GroupResourcesQuery_groupResources_edges[];
  totalCount: number;
  pageInfo: GroupResourcesQuery_groupResources_pageInfo;
}

export interface GroupResourcesQuery {
  groupResources: GroupResourcesQuery_groupResources;
}

export interface GroupResourcesQueryVariables {
  input: GroupResourcesQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteToGroup
// ====================================================

export interface InviteToGroup_inviteToGroup {
  __typename: "InviteToGroupMutationOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface InviteToGroup {
  inviteToGroup: InviteToGroup_inviteToGroup | null;
}

export interface InviteToGroupVariables {
  input: InviteToGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: JoinGroup
// ====================================================

export interface JoinGroup_joinGroup_group_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface JoinGroup_joinGroup_group_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface JoinGroup_joinGroup_group_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface JoinGroup_joinGroup_group {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: JoinGroup_joinGroup_group_aliases[];
  permissions: JoinGroup_joinGroup_group_permissions;
  settings: JoinGroup_joinGroup_group_settings;
}

export interface JoinGroup_joinGroup {
  __typename: "JoinGroupMutationOutput";
  status: RequestStatusEnum;
  message: string;
  group: JoinGroup_joinGroup_group | null;
}

export interface JoinGroup {
  joinGroup: JoinGroup_joinGroup | null;
}

export interface JoinGroupVariables {
  input: JoinGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LastEventQuery
// ====================================================

export interface LastEventQuery_lastEvent_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface LastEventQuery_lastEvent_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface LastEventQuery_lastEvent_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface LastEventQuery_lastEvent {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: LastEventQuery_lastEvent_aliases[];
  permissions: LastEventQuery_lastEvent_permissions;
  settings: LastEventQuery_lastEvent_settings;
}

export interface LastEventQuery {
  lastEvent: LastEventQuery_lastEvent[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LeaveGroup
// ====================================================

export interface LeaveGroup_leaveGroup {
  __typename: "LeaveGroupMutationOutput";
  status: RequestStatusEnum;
  message: string;
  groupId: string;
}

export interface LeaveGroup {
  leaveGroup: LeaveGroup_leaveGroup | null;
}

export interface LeaveGroupVariables {
  input: LeaveGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedGroupsQuery
// ====================================================

export interface PaginatedGroupsQuery_paginatedGroups_edges_node_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface PaginatedGroupsQuery_paginatedGroups_edges_node_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface PaginatedGroupsQuery_paginatedGroups_edges_node_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface PaginatedGroupsQuery_paginatedGroups_edges_node {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: PaginatedGroupsQuery_paginatedGroups_edges_node_aliases[];
  permissions: PaginatedGroupsQuery_paginatedGroups_edges_node_permissions;
  settings: PaginatedGroupsQuery_paginatedGroups_edges_node_settings;
}

export interface PaginatedGroupsQuery_paginatedGroups_edges {
  __typename: "GroupEdge";
  cursor: string;
  node: PaginatedGroupsQuery_paginatedGroups_edges_node;
}

export interface PaginatedGroupsQuery_paginatedGroups_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface PaginatedGroupsQuery_paginatedGroups {
  __typename: "GroupsPagination";
  edges: PaginatedGroupsQuery_paginatedGroups_edges[];
  totalCount: number;
  pageInfo: PaginatedGroupsQuery_paginatedGroups_pageInfo;
}

export interface PaginatedGroupsQuery {
  paginatedGroups: PaginatedGroupsQuery_paginatedGroups;
}

export interface PaginatedGroupsQueryVariables {
  input: PaginatedGroupsQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeContacts
// ====================================================

export interface removeContacts_removeContacts_removedContacts_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface removeContacts_removeContacts_removedContacts_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface removeContacts_removeContacts_removedContacts_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type removeContacts_removeContacts_removedContacts_contactInfo_methods = removeContacts_removeContacts_removedContacts_contactInfo_methods_ContactInfo | removeContacts_removeContacts_removedContacts_contactInfo_methods_PhoneNumberContactInfo;

export interface removeContacts_removeContacts_removedContacts_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface removeContacts_removeContacts_removedContacts_contactInfo {
  __typename: "CardContactInfo";
  methods: removeContacts_removeContacts_removedContacts_contactInfo_methods[];
  links: removeContacts_removeContacts_removedContacts_contactInfo_links[];
}

export interface removeContacts_removeContacts_removedContacts_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface removeContacts_removeContacts_removedContacts_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface removeContacts_removeContacts_removedContacts_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface removeContacts_removeContacts_removedContacts_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface removeContacts_removeContacts_removedContacts {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: removeContacts_removeContacts_removedContacts_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: removeContacts_removeContacts_removedContacts_contactInfo;
  owner: removeContacts_removeContacts_removedContacts_owner | null;
  company: removeContacts_removeContacts_removedContacts_company | null;
  links: removeContacts_removeContacts_removedContacts_links[];
  theme: removeContacts_removeContacts_removedContacts_theme | null;
}

export interface removeContacts_removeContacts {
  __typename: "RemoveContactsOutput";
  status: RequestStatusEnum;
  message: string;
  removedContacts: removeContacts_removeContacts_removedContacts[];
}

export interface removeContacts {
  removeContacts: removeContacts_removeContacts | null;
}

export interface removeContactsVariables {
  input: RemoveContactsInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFromCompanyMutation
// ====================================================

export interface RemoveFromCompanyMutation_removeFromCompany {
  __typename: "RemoveFromCompanyMutationOutput";
  status: RequestStatusEnum;
  message: string;
  companyId: string;
  removedIds: string[];
}

export interface RemoveFromCompanyMutation {
  removeFromCompany: RemoveFromCompanyMutation_removeFromCompany | null;
}

export interface RemoveFromCompanyMutationVariables {
  input: RemoveFromCompanyMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFromGroupMutation
// ====================================================

export interface RemoveFromGroupMutation_removeFromGroup {
  __typename: "RemoveFromGroupMutationOutput";
  status: RequestStatusEnum;
  message: string;
  groupId: string;
  removedIds: (string | null)[];
}

export interface RemoveFromGroupMutation {
  removeFromGroup: RemoveFromGroupMutation_removeFromGroup | null;
}

export interface RemoveFromGroupMutationVariables {
  input: RemoveFromGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGroupEnquiry
// ====================================================

export interface RemoveGroupEnquiry_removeGroupEnquiry {
  __typename: "RemoveGroupEnquiryMutationOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface RemoveGroupEnquiry {
  removeGroupEnquiry: RemoveGroupEnquiry_removeGroupEnquiry;
}

export interface RemoveGroupEnquiryVariables {
  input: RemoveGroupEnquiryMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGroupResourcesMutation
// ====================================================

export interface RemoveGroupResourcesMutation_removeGroupResources {
  __typename: "RemoveGroupResourcesMutationOutput";
  status: RequestStatusEnum;
  message: string;
  groupId: string;
  removedResourceIds: (string | null)[];
}

export interface RemoveGroupResourcesMutation {
  removeGroupResources: RemoveGroupResourcesMutation_removeGroupResources;
}

export interface RemoveGroupResourcesMutationVariables {
  input: RemoveGroupResourcesMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderGroupResourcesMutation
// ====================================================

export interface ReorderGroupResourcesMutation_reorderGroupResources {
  __typename: "ReorderGroupResourcesMutationOutput";
  status: RequestStatusEnum;
  message: string;
  groupId: string;
}

export interface ReorderGroupResourcesMutation {
  reorderGroupResources: ReorderGroupResourcesMutation_reorderGroupResources | null;
}

export interface ReorderGroupResourcesMutationVariables {
  input: ReorderGroupResourcesMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReplyToGroupEnquiryMutation
// ====================================================

export interface ReplyToGroupEnquiryMutation_replyToGroupEnquiry {
  __typename: "ReplyToGroupEnquiryMutationOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface ReplyToGroupEnquiryMutation {
  replyToGroupEnquiry: ReplyToGroupEnquiryMutation_replyToGroupEnquiry;
}

export interface ReplyToGroupEnquiryMutationVariables {
  input: ReplyToGroupEnquiryMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ScanCards
// ====================================================

export interface ScanCards_scanCards_added_location {
  __typename: "GeoPosition";
  longitude: number;
  latitude: number;
}

export interface ScanCards_scanCards_added {
  __typename: "GroupItem";
  /**
   * The id of the group item, eg. the relation that ties a node to a group
   */
  id: string;
  /**
   * The id of the node linked to the group
   */
  nodeId: string;
  groupId: string;
  createdAt: GQL_Date;
  location: ScanCards_scanCards_added_location | null;
}

export interface ScanCards_scanCards {
  __typename: "ScanCardsOutput";
  added: ScanCards_scanCards_added[];
}

export interface ScanCards {
  scanCards: ScanCards_scanCards | null;
}

export interface ScanCardsVariables {
  input: ScanCardsInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendBack
// ====================================================

export interface SendBack_sendBack_validation {
  __typename: "SendBackValidation";
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
}

export interface SendBack_sendBack {
  __typename: "SendBackOutput";
  status: RequestStatusEnum;
  message: string;
  validation: SendBack_sendBack_validation | null;
}

export interface SendBack {
  sendBack: SendBack_sendBack | null;
}

export interface SendBackVariables {
  input: SendBackInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCard
// ====================================================

export interface SendCard_sendCard {
  __typename: "SendCardOutput";
  status: SendCardStatusResponse;
  message: string | null;
}

export interface SendCard {
  sendCard: SendCard_sendCard | null;
}

export interface SendCardVariables {
  input: SendCardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTwoWayAlias
// ====================================================

export interface SetTwoWayAlias_setTwoWayAlias {
  __typename: "SetTwoWayAliasOutput";
  message: string | null;
  status: RequestStatusEnum;
}

export interface SetTwoWayAlias {
  setTwoWayAlias: SetTwoWayAlias_setTwoWayAlias | null;
}

export interface SetTwoWayAliasVariables {
  input: SetTwoWayAliasInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetVanityAlias
// ====================================================

export interface SetVanityAlias_setVanityAlias {
  __typename: "SetVanityAliasOutput";
  message: string | null;
  status: RequestStatusEnum;
}

export interface SetVanityAlias {
  setVanityAlias: SetVanityAlias_setVanityAlias | null;
}

export interface SetVanityAliasVariables {
  input: SetVanityAliasInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactInfoMutation
// ====================================================

export interface UpdateContactInfoMutation_updateContactInfo_validation {
  __typename: "UpdateContactInfoValidation";
  platform: string | null;
  value: string | null;
  label: string | null;
  dialingCode: string | null;
  countryCode: string | null;
  nationalNumber: string | null;
}

export interface UpdateContactInfoMutation_updateContactInfo {
  __typename: "UpdateContactInfoMutationOutput";
  status: RequestStatusEnum;
  message: string;
  validation: (UpdateContactInfoMutation_updateContactInfo_validation | null)[];
}

export interface UpdateContactInfoMutation {
  updateContactInfo: UpdateContactInfoMutation_updateContactInfo | null;
}

export interface UpdateContactInfoMutationVariables {
  input: UpdateContactInfoMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGroupEnquiry
// ====================================================

export interface UpdateGroupEnquiry_updateGroupEnquiry {
  __typename: "UpdateGroupEnquiryMutationOutput";
  status: RequestStatusEnum;
  message: string;
}

export interface UpdateGroupEnquiry {
  updateGroupEnquiry: UpdateGroupEnquiry_updateGroupEnquiry;
}

export interface UpdateGroupEnquiryVariables {
  input: UpdateGroupEnquiryMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGroupInfo
// ====================================================

export interface UpdateGroupInfo_updateGroupInfo_updatedGroup_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface UpdateGroupInfo_updateGroupInfo_updatedGroup_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface UpdateGroupInfo_updateGroupInfo_updatedGroup_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface UpdateGroupInfo_updateGroupInfo_updatedGroup {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: UpdateGroupInfo_updateGroupInfo_updatedGroup_aliases[];
  permissions: UpdateGroupInfo_updateGroupInfo_updatedGroup_permissions;
  settings: UpdateGroupInfo_updateGroupInfo_updatedGroup_settings;
}

export interface UpdateGroupInfo_updateGroupInfo {
  __typename: "UpdateGroupInfoOutput";
  status: RequestStatusEnum;
  message: string;
  updatedGroup: UpdateGroupInfo_updateGroupInfo_updatedGroup | null;
}

export interface UpdateGroupInfo {
  updateGroupInfo: UpdateGroupInfo_updateGroupInfo | null;
}

export interface UpdateGroupInfoVariables {
  input: UpdateGroupInfoMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGroupResourceMutation
// ====================================================

export interface UpdateGroupResourceMutation_updateGroupResource_updatedResource {
  __typename: "Resource";
  id: string;
  type: ResourceTypeEnum;
  /**
   * Formatted and ready for use
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  /**
   * Web uri for this resource
   */
  href: string;
}

export interface UpdateGroupResourceMutation_updateGroupResource_validation {
  __typename: "UpdateGroupResourceValidation";
  label: string | null;
}

export interface UpdateGroupResourceMutation_updateGroupResource {
  __typename: "UpdateGroupResourceMutationOutput";
  status: RequestStatusEnum;
  message: string;
  groupId: string;
  updatedResource: UpdateGroupResourceMutation_updateGroupResource_updatedResource | null;
  validation: UpdateGroupResourceMutation_updateGroupResource_validation | null;
}

export interface UpdateGroupResourceMutation {
  updateGroupResource: UpdateGroupResourceMutation_updateGroupResource | null;
}

export interface UpdateGroupResourceMutationVariables {
  input: UpdateGroupResourceMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserList
// ====================================================

export interface UserList_userList_edges_node_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
}

export interface UserList_userList_edges_node {
  __typename: "User";
  /**
   * The ID of an object
   */
  id: string;
  alias: string | null;
  userId: string | null;
  firstName: string;
  lastName: string;
  fullName: string;
  acceptsTerms: boolean;
  cards: UserList_userList_edges_node_cards[];
}

export interface UserList_userList_edges {
  __typename: "UserEdge";
  cursor: string;
  node: UserList_userList_edges_node;
}

export interface UserList_userList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface UserList_userList {
  __typename: "UserList";
  edges: UserList_userList_edges[];
  pageInfo: UserList_userList_pageInfo;
}

export interface UserList {
  userList: UserList_userList;
}

export interface UserListVariables {
  input: UserListInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ViewCards
// ====================================================

export interface ViewCards_viewCards_added_location {
  __typename: "GeoPosition";
  longitude: number;
  latitude: number;
}

export interface ViewCards_viewCards_added {
  __typename: "GroupItem";
  /**
   * The id of the group item, eg. the relation that ties a node to a group
   */
  id: string;
  /**
   * The id of the node linked to the group
   */
  nodeId: string;
  groupId: string;
  createdAt: GQL_Date;
  location: ViewCards_viewCards_added_location | null;
}

export interface ViewCards_viewCards {
  __typename: "ViewCardsOutput";
  added: ViewCards_viewCards_added[];
}

export interface ViewCards {
  viewCards: ViewCards_viewCards | null;
}

export interface ViewCardsVariables {
  input: ViewCardsInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewedCardsQuery
// ====================================================

export interface ViewedCardsQuery_viewedCards_edges_node {
  __typename: "CardView";
  id: string;
  cardId: string;
  createdAt: GQL_Date;
}

export interface ViewedCardsQuery_viewedCards_edges {
  __typename: "CardViewEdge";
  cursor: string;
  node: ViewedCardsQuery_viewedCards_edges_node;
}

export interface ViewedCardsQuery_viewedCards_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ViewedCardsQuery_viewedCards {
  __typename: "CardViewConnection";
  edges: ViewedCardsQuery_viewedCards_edges[];
  pageInfo: ViewedCardsQuery_viewedCards_pageInfo;
}

export interface ViewedCardsQuery {
  viewedCards: ViewedCardsQuery_viewedCards;
}

export interface ViewedCardsQueryVariables {
  input: ViewedCardsQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Viewer
// ====================================================

export interface Viewer_viewer_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface Viewer_viewer_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface Viewer_viewer_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type Viewer_viewer_cards_contactInfo_methods = Viewer_viewer_cards_contactInfo_methods_ContactInfo | Viewer_viewer_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface Viewer_viewer_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface Viewer_viewer_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: Viewer_viewer_cards_contactInfo_methods[];
  links: Viewer_viewer_cards_contactInfo_links[];
}

export interface Viewer_viewer_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface Viewer_viewer_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface Viewer_viewer_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface Viewer_viewer_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface Viewer_viewer_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: Viewer_viewer_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: Viewer_viewer_cards_contactInfo;
  owner: Viewer_viewer_cards_owner | null;
  company: Viewer_viewer_cards_company | null;
  links: Viewer_viewer_cards_links[];
  theme: Viewer_viewer_cards_theme | null;
}

export interface Viewer_viewer {
  __typename: "Viewer";
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  imageURL: GQL_URL;
  acceptsTerms: boolean;
  isMinglAdmin: boolean;
  trackingId: string;
  createdAt: GQL_Date;
  cards: Viewer_viewer_cards[];
}

export interface Viewer {
  viewer: Viewer_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactInfoDefinitionFragment
// ====================================================

export interface ContactInfoDefinitionFragment {
  __typename: "ContactInfoDefinition";
  platform: ContactPlatformsEnum;
  displayName: string;
  icon: string;
  inputType: ContactInfoInputEnum;
  labelType: ContactInfoLabelEnum;
  type: ContactInfoTypeEnum;
  countryCode: string | null;
  helpText: string | null;
  helpRedirect: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactInfoFragment
// ====================================================

export interface ContactInfoFragment_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface ContactInfoFragment_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type ContactInfoFragment = ContactInfoFragment_ContactInfo | ContactInfoFragment_PhoneNumberContactInfo;

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardNodeFragment
// ====================================================

export interface CardNodeFragment_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface CardNodeFragment_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface CardNodeFragment_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type CardNodeFragment_contactInfo_methods = CardNodeFragment_contactInfo_methods_ContactInfo | CardNodeFragment_contactInfo_methods_PhoneNumberContactInfo;

export interface CardNodeFragment_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface CardNodeFragment_contactInfo {
  __typename: "CardContactInfo";
  methods: CardNodeFragment_contactInfo_methods[];
  links: CardNodeFragment_contactInfo_links[];
}

export interface CardNodeFragment_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface CardNodeFragment_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface CardNodeFragment_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface CardNodeFragment_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface CardNodeFragment {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: CardNodeFragment_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: CardNodeFragment_contactInfo;
  owner: CardNodeFragment_owner | null;
  company: CardNodeFragment_company | null;
  links: CardNodeFragment_links[];
  theme: CardNodeFragment_theme | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupFragment
// ====================================================

export interface GroupFragment_aliases {
  __typename: "Alias";
  id: string;
  displayName: string;
}

export interface GroupFragment_permissions {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

export interface GroupFragment_settings {
  __typename: "GroupSettings";
  invitationSetting: GroupInvitationSettingEnum;
}

export interface GroupFragment {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  icon: string;
  usesDefaultIcon: boolean;
  description: string | null;
  createdAt: GQL_Date;
  viewerRole: GroupMemberRole;
  publicURL: GQL_URL | null;
  aliases: GroupFragment_aliases[];
  permissions: GroupFragment_permissions;
  settings: GroupFragment_settings;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupPermissionsFragment
// ====================================================

export interface GroupPermissionsFragment {
  __typename: "GroupPermissions";
  viewerCanAppendItems: boolean;
  viewerCanDeleteItems: boolean;
  viewerCanEditGroupInfo: boolean;
  viewerCanDeleteGroup: boolean;
  viewerCanLeaveGroup: boolean;
  viewerCanInviteMembers: boolean;
  viewerCanRemoveMembers: boolean;
  viewerCanJoinGroup: boolean;
  viewerCanEditGroupSettings: boolean;
  viewerCanEditGroupResources: boolean;
  viewerCanViewGroupResources: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MinimalCardFragment
// ====================================================

export interface MinimalCardFragment {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  imageThumbnailURL: GQL_URL;
  isContact: boolean;
  qrShareURL: GQL_URL;
  directChatId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupEnquiryFragment
// ====================================================

export interface GroupEnquiryFragment_card_owner {
  __typename: "CardOwner";
  id: string;
  isViewer: boolean;
}

export interface GroupEnquiryFragment_card {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  imageThumbnailURL: GQL_URL;
  isContact: boolean;
  qrShareURL: GQL_URL;
  directChatId: string | null;
  owner: GroupEnquiryFragment_card_owner | null;
}

export interface GroupEnquiryFragment {
  __typename: "GroupEnquiry";
  id: string;
  text: string;
  viewerCanUpdate: boolean;
  viewerCanRemove: boolean;
  viewerCanRespond: boolean;
  card: GroupEnquiryFragment_card;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupResource
// ====================================================

export interface GroupResource {
  __typename: "Resource";
  id: string;
  type: ResourceTypeEnum;
  /**
   * Formatted and ready for use
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  /**
   * Web uri to image
   */
  icon: string;
  /**
   * User specified label
   */
  label: string;
  /**
   * Web uri for this resource
   */
  href: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ViewerFragment
// ====================================================

export interface ViewerFragment_cards_aliases {
  __typename: "Alias";
  id: string;
  twoWay: boolean;
  vanity: boolean;
  displayName: string;
}

export interface ViewerFragment_cards_contactInfo_methods_ContactInfo {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface ViewerFragment_cards_contactInfo_methods_PhoneNumberContactInfo {
  __typename: "PhoneNumberContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  /**
   * E164 formatted phone number
   */
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
  /**
   * Country calling code, 1-3 digit code (eg. 47)
   */
  dialingCode: string | null;
  /**
   * ISO 3166-1 alpha-2, 2 character country code (eg. NO)
   */
  countryCode: string | null;
  /**
   * Number without the dialing code prefix
   */
  nationalNumber: string | null;
}

export type ViewerFragment_cards_contactInfo_methods = ViewerFragment_cards_contactInfo_methods_ContactInfo | ViewerFragment_cards_contactInfo_methods_PhoneNumberContactInfo;

export interface ViewerFragment_cards_contactInfo_links {
  __typename: "ContactInfo";
  id: string;
  platform: ContactPlatformsEnum;
  value: string;
  /**
   * User input value for editation
   */
  rawValue: string;
  label: string | null;
  icon: string;
  href: string;
  appHref: string | null;
}

export interface ViewerFragment_cards_contactInfo {
  __typename: "CardContactInfo";
  methods: ViewerFragment_cards_contactInfo_methods[];
  links: ViewerFragment_cards_contactInfo_links[];
}

export interface ViewerFragment_cards_owner {
  __typename: "CardOwner";
  id: string;
  firstName: string;
  lastName: string;
  isViewer: boolean;
}

export interface ViewerFragment_cards_company {
  __typename: "CardCompany";
  id: string;
  name: string;
}

export interface ViewerFragment_cards_links {
  __typename: "CardLink";
  label: string;
  url: GQL_URL;
}

export interface ViewerFragment_cards_theme {
  __typename: "CardTheme";
  primaryColor: string;
  logoURL: GQL_URL;
  primaryContrastColor: string;
}

export interface ViewerFragment_cards {
  __typename: "Card";
  /**
   * The ID of an object
   */
  id: string;
  title: string;
  subtitle: string;
  workTitle: string;
  imageURL: GQL_URL;
  imageThumbnailURL: GQL_URL;
  shareURL: GQL_URL;
  qrShareURL: GQL_URL;
  twoWayEnabled: boolean;
  alias: string | null;
  aliases: ViewerFragment_cards_aliases[];
  vcardId: string;
  email: GQL_Email;
  phoneNumber: string;
  address: string;
  biography: string;
  isContact: boolean;
  privacy: CardPrivacyLevel;
  activatedByUser: boolean;
  contactInfo: ViewerFragment_cards_contactInfo;
  owner: ViewerFragment_cards_owner | null;
  company: ViewerFragment_cards_company | null;
  links: ViewerFragment_cards_links[];
  theme: ViewerFragment_cards_theme | null;
}

export interface ViewerFragment {
  __typename: "Viewer";
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  imageURL: GQL_URL;
  acceptsTerms: boolean;
  isMinglAdmin: boolean;
  trackingId: string;
  createdAt: GQL_Date;
  cards: ViewerFragment_cards[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The privacy level of a card
 */
export enum CardPrivacyLevel {
  HIDDEN = "HIDDEN",
  LINK_ONLY = "LINK_ONLY",
  PUBLIC = "PUBLIC",
}

export enum CardsInGroupQueryOrder {
  ALPHABETICAL = "ALPHABETICAL",
  CREATED_AT = "CREATED_AT",
}

export enum CompaniesListOrder {
  ALPHABETICAL = "ALPHABETICAL",
}

export enum ContactInfoInputEnum {
  freeText = "freeText",
  phoneNumber = "phoneNumber",
}

export enum ContactInfoLabelEnum {
  none = "none",
  optional = "optional",
  required = "required",
}

export enum ContactInfoTypeEnum {
  link = "link",
  method = "method",
}

export enum ContactPlatformsEnum {
  document = "document",
  email = "email",
  facebook = "facebook",
  instagram = "instagram",
  linkedin = "linkedin",
  messenger = "messenger",
  phoneNumber = "phoneNumber",
  snapchat = "snapchat",
  twitter = "twitter",
  website = "website",
  whatsapp = "whatsapp",
}

export enum ContactsQueryOrder {
  ALPHABETICAL = "ALPHABETICAL",
}

export enum CreateGroupTypes {
  event = "event",
  group = "group",
  list = "list",
}

export enum DeletedItemType {
  ARCHIVED_CARD_SCAN = "ARCHIVED_CARD_SCAN",
  ARCHIVED_CARD_VIEW = "ARCHIVED_CARD_VIEW",
  CARD_SCAN = "CARD_SCAN",
  CARD_VIEW = "CARD_VIEW",
  COMPANY = "COMPANY",
  GROUP = "GROUP",
  GROUP_INVITE = "GROUP_INVITE",
  GROUP_ITEM = "GROUP_ITEM",
  GROUP_MEMBER = "GROUP_MEMBER",
}

export enum GroupInvitationSettingEnum {
  ADMIN_INVITE = "ADMIN_INVITE",
  CLOSED = "CLOSED",
  LINK = "LINK",
  MEMBER_INVITE = "MEMBER_INVITE",
}

export enum GroupMemberRole {
  MEMBER = "MEMBER",
  NONE = "NONE",
  OWNER = "OWNER",
}

export enum GroupType {
  EVENT = "EVENT",
  GROUP = "GROUP",
  LIST = "LIST",
  RATING = "RATING",
  SCANS = "SCANS",
  VIEWS = "VIEWS",
}

export enum PaginatedGroupsOrder {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export enum RequestStatusEnum {
  BAD_REQUEST = "BAD_REQUEST",
  ERROR = "ERROR",
  OK = "OK",
}

export enum ResourceTypeEnum {
  website = "website",
}

export enum ResourcesOrderByEnum {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
}

export enum SendCardStatusResponse {
  BAD_REQUEST = "BAD_REQUEST",
  ERROR = "ERROR",
  OK = "OK",
}

export enum UserListOrder {
  ALPHABETICAL = "ALPHABETICAL",
  CREATED_AT = "CREATED_AT",
}

export interface AddContactsInput {
  ids: string[];
}

export interface AddToGroupItemInput {
  groupId: string;
  itemId: string;
  createdAt?: GQL_Date | null;
  location?: GeoPositionInput | null;
}

export interface AddToGroupMutationInput {
  actions: (AddToGroupItemInput | null)[];
}

export interface AdminAddAliasInput {
  alias: string;
  nodeId: string;
}

export interface AdminAssignToGroupMutationInput {
  groupId: string;
  userId: string;
}

export interface AdminRemoveAliasInput {
  aliasId: string;
  nodeId: string;
}

export interface AdminUpdateCompanyInput {
  id?: string | null;
  name: string;
  email: string;
  about: string;
  isSearchable: boolean;
  applyChangesToAllCards: boolean;
  website: string;
  publicFacebookUrl: GQL_URL;
  publicInstagramUrl: GQL_URL;
  publicLinkedinUrl: GQL_URL;
  publicTwitterUrl: GQL_URL;
  useCustomColors: boolean;
  phone: string;
  location: string;
  videoUrl: GQL_URL;
  logoUrl: GQL_URL;
  primaryColor?: string | null;
}

export interface AdminUpdateUserInput {
  userId: string;
  auth0Id: string;
  firstName: string;
  lastName: string;
  workTitle: string;
  email: string;
  address: string;
  biography: string;
  imageURL: GQL_URL;
  imageTransform?: ImageTransformInput | null;
  privacy: CardPrivacyLevel;
  employerId?: string | null;
}

export interface CardLinkInput {
  label: string;
  url: GQL_URL;
}

export interface CardScanInput {
  cardId: string;
  createdAt?: GQL_Date | null;
  location?: GeoPositionInput | null;
}

export interface CardViewInput {
  cardId: string;
  createdAt?: GQL_Date | null;
}

export interface CardsInGroupQueryInput {
  groupId: string;
  order?: CardsInGroupQueryOrder | null;
  first?: number | null;
  after?: string | null;
}

export interface ClaimAliasInput {
  alias: string;
}

export interface CompaniesListInput {
  first?: number | null;
  after?: string | null;
  nameIncludes?: string | null;
  order?: CompaniesListOrder | null;
}

export interface ContactsQueryInput {
  first?: number | null;
  after?: string | null;
  order?: ContactsQueryOrder | null;
}

export interface CreateContactInfoInput {
  platform: ContactPlatformsEnum;
  value: string;
  label?: string | null;
  dialingCode?: string | null;
  countryCode?: string | null;
}

export interface CreateContactInfoMutationInput {
  cardId: string;
  contactInfo?: CreateContactInfoInput[] | null;
}

export interface CreateGroupEnquiryMutationInput {
  groupId: string;
  text: string;
}

export interface CreateGroupInput {
  name: string;
  type: CreateGroupTypes;
  invitationSetting?: GroupInvitationSettingEnum | null;
  icon?: string | null;
  description?: string | null;
  tappinId?: string | null;
}

export interface CreateGroupMutationInput {
  group: CreateGroupInput;
}

export interface CreateGroupResourcesMutationInput {
  groupId: string;
  resources?: NewResourceInput[] | null;
}

export interface DeclineGroupInvitationMutationInput {
  id: string;
}

export interface DeleteContactInfoMutationInput {
  cardId: string;
  contactInfoIds: (string | null)[];
}

export interface DeleteItemInput {
  id: string;
  groupId?: string | null;
}

export interface DeleteUsersMutationInput {
  userIds: string[];
}

export interface GeoPositionInput {
  longitude: number;
  latitude: number;
  accuracy?: number | null;
}

export interface GroupMembersQueryInput {
  groupId: string;
  first?: number | null;
  after?: string | null;
}

export interface GroupResourcesQueryInput {
  groupId: string;
  first?: number | null;
  after?: string | null;
  orderBy?: ResourcesOrderByEnum | null;
}

export interface ImageTransformInput {
  x?: number | null;
  y?: number | null;
  scale?: number | null;
}

export interface InviteToGroupMutationInput {
  ids?: string[] | null;
  groupId: string;
}

export interface JoinGroupMutationInput {
  id: string;
}

export interface LeaveGroupMutationInput {
  groupId: string;
}

export interface NewResourceInput {
  type: ResourceTypeEnum;
  value: string;
  icon?: string | null;
  label?: string | null;
}

export interface PaginatedGroupsQueryInput {
  first?: number | null;
  after?: string | null;
  nameIncludes?: string | null;
  userId?: string | null;
  order?: PaginatedGroupsOrder | null;
}

export interface RemoveContactsInput {
  ids: string[];
}

export interface RemoveFromCompanyMutationInput {
  companyId: string;
  ids: string[];
}

export interface RemoveFromGroupMutationInput {
  groupId: string;
  ids: string[];
}

export interface RemoveGroupEnquiryMutationInput {
  enquiryId: string;
}

export interface RemoveGroupResourcesMutationInput {
  groupId: string;
  resourceIds: string[];
}

export interface ReorderGroupResourcesMutationInput {
  groupId: string;
  resourceIds?: string[] | null;
}

export interface ReplyToGroupEnquiryMutationInput {
  text: string;
  groupEnquiryId: string;
}

export interface ScanCardsInput {
  scans: (CardScanInput | null)[];
}

export interface SendBackContactInfoInput {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface SendBackInput {
  cardId: string;
  contactInfo: SendBackContactInfoInput;
}

export interface SendCardInput {
  cardId: string;
  email?: string | null;
  phoneNumber?: string | null;
}

export interface SetTwoWayAliasInput {
  alias: string;
  twoWay: boolean;
}

export interface SetVanityAliasInput {
  alias: string;
  vanity: boolean;
}

export interface UpdateCardInput {
  id?: string | null;
  firstName: string;
  lastName: string;
  workTitle?: string | null;
  phoneNumber: string;
  email: string;
  address: string;
  biography: string;
  imageURL: GQL_URL;
  imageTransform?: ImageTransformInput | null;
  links: CardLinkInput[];
  privacy: CardPrivacyLevel;
}

export interface UpdateContactInfoInput {
  platform: ContactPlatformsEnum;
  value: string;
  label?: string | null;
  dialingCode?: string | null;
  countryCode?: string | null;
  id: string;
}

export interface UpdateContactInfoMutationInput {
  cardId: string;
  contactInfo: UpdateContactInfoInput[];
}

export interface UpdateGroupEnquiryMutationInput {
  enquiryId: string;
  text: string;
}

export interface UpdateGroupInfoInput {
  id: string;
  name: string;
  icon?: string | null;
  description?: string | null;
}

export interface UpdateGroupInfoMutationInput {
  group: UpdateGroupInfoInput;
}

export interface UpdateGroupResourceMutationInput {
  groupId: string;
  resource: UpdateResourceInput;
}

export interface UpdateResourceInput {
  id: string;
  label: string;
}

export interface UserListInput {
  first?: number | null;
  after?: string | null;
  nameIncludes?: string | null;
  order?: UserListOrder | null;
}

export interface ViewCardsInput {
  views: CardViewInput[];
}

export interface ViewedCardsQueryInput {
  first?: number | null;
  after?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
