/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Note: The reason this is done this way is because we import Raven via an
 * async html <script> tag. This prevents us from loading the library AND
 * the types from node_modules..
 */

export type RavenCaptureCallback = (
  sendErr: Error | null | undefined,
  eventId: any
) => void;

export interface RavenCaptureOptions {
  tags?: { [key: string]: string };
  extra?: { [key: string]: any };
  fingerprint?: string[];
  level?: string;
  // req?: IncomingMessage;
  user?: any;
}

interface RavenClient {
  captureException: (
    e: Error,
    opts?: RavenCaptureOptions,
    cb?: RavenCaptureCallback
  ) => string;
  captureMessage: (
    message: string,
    options?: RavenCaptureOptions,
    cb?: RavenCaptureCallback
  ) => string;
  captureBreadcrumb: (breadcrumb: any) => void;
}

const getRaven = (): RavenClient | undefined => {
  // eslint-disable-next-line dot-notation
  return window['Raven'] as RavenClient | undefined;
};

export const captureException: RavenClient['captureException'] = (
  error,
  options,
  callback
) => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(`Raven.captureException:\n`, error);
  }

  const Raven = getRaven();
  if (Raven && typeof Raven.captureException === 'function') {
    return Raven.captureException(error, options, callback);
  }
  return '';
};

export const captureMessage: RavenClient['captureMessage'] = (
  error,
  options,
  callback
) => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(`Raven.captureMessage:\n`, error);
  }

  const Raven = getRaven();
  if (Raven && typeof Raven.captureMessage === 'function') {
    return Raven.captureMessage(error, options, callback);
  }
  return '';
};
export const captureBreadcrumb: RavenClient['captureBreadcrumb'] = breadcrumb => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(`Raven.captureBreadcrumb:\n`, breadcrumb);
  }

  const Raven = getRaven();
  if (Raven && typeof Raven.captureBreadcrumb === 'function') {
    return Raven.captureBreadcrumb(breadcrumb);
  }
  return '';
};
