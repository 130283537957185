import * as React from 'react';
import { useViewerQuery } from '../../queries/ViewerQuery';
import idx from '../../utils/idx';

// TODO: use material avatar instead?
interface AvatarPropsT {
  size?: number;
  imageURL?: string;
  onClick?: () => void;
}

export function UserAvatar(props: AvatarPropsT) {
  const size = props.size || 150;

  return (
    <img
      width={size}
      height={size}
      className="br-pill"
      style={{ objectFit: 'cover', cursor: 'pointer' }}
      onClick={props.onClick}
      src={
        props.imageURL || require('../../assets/img/profile_placeholder.png')
      }
    />
  );
}

export function ViewerAvatar(props: AvatarPropsT) {
  const { data } = useViewerQuery({
    fetchPolicy: 'cache-only',
  });

  return (
    <UserAvatar {...props} imageURL={idx(data, _ => _!.viewer!.imageURL)} />
  );
}
