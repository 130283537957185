import * as React from 'react';

interface Props {
  background: string;
}

const DEFAULT_BACKGROUND = '#00384D';

export class SiteBackground extends React.Component<Props> {
  setBackground = (newBackground: string) => {
    const $body = document.querySelector('body');
    if ($body) {
      $body.style.background = newBackground;
    }
  };

  componentDidMount() {
    this.setBackground(this.props.background);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.background !== this.props.background) {
      this.setBackground(this.props.background);
    }
  }

  componentWillUnmount() {
    this.setBackground(DEFAULT_BACKGROUND);
  }

  render(): null {
    return null;
  }
}
