/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const UploadImage = ({ color, size }: IconProps) => (
  <svg
    viewBox="0 0 89 90"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    name="upload-image"
  >
    <defs>
      <path
        id="a"
        d="M0 30V10C0 4.477 4.477 0 10 0h40c5.523 0 10 4.477 10 10v40c0 5.523-4.477 10-10 10H30"
      />
      <mask id="b" fill="#fff" height={60} width={60} x={0} y={0}>
        <use xlinkHref="#a" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        mask="url(#b)"
        stroke={color}
        strokeDasharray="5 5"
        strokeWidth={4}
        transform="matrix(-1 0 0 1 60 0)"
        xlinkHref="#a"
      />
      <rect
        height={58}
        rx={10}
        stroke={color}
        strokeWidth={2}
        width={58}
        x={30}
        y={31}
      />
      <path d="M60 59h6v2h-6v6h-2v-6h-6v-2h6v-6h2z" fill={color} />
    </g>
  </svg>
);

export default UploadImage;
