import React, { useContext, createContext, useMemo } from 'react';
import clsx from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

import * as minglTheme from './theme';
import { RequestStatusEnum } from '../../__generated/apollogen-types';

const variantIcon = {
  success: CheckCircleIcon,
  warn: WarningIcon,
  error: ErrorIcon,
  neutral: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: minglTheme.colors.green200,
    color: minglTheme.colors.green800,
  },
  error: {
    backgroundColor: minglTheme.colors.red300,
    color: minglTheme.colors.red900,
  },
  neutral: {
    backgroundColor: minglTheme.colors.gray100,
    color: minglTheme.colors.gray900,
  },
  warn: {
    backgroundColor: minglTheme.colors.yellow300,
    color: minglTheme.colors.yellow800,
  },
  icon: {
    fontSize: 25,
  },
  iconVariant: {
    opacity: 1,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const requestStatusMap = {
  [RequestStatusEnum.OK]: 'success',
  [RequestStatusEnum.ERROR]: 'error',
  [RequestStatusEnum.BAD_REQUEST]: 'error',
};

export type SnackbarTypesT = 'success' | 'warn' | 'error' | 'neutral';
export type SetSnackbarFnT = (
  message: string,
  type: SnackbarTypesT | RequestStatusEnum,
  duration?: number
) => void;

interface SnackbarContextT {
  position: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  setSnackbar: SetSnackbarFnT;
}
const SnackbarContext = createContext<SnackbarContextT>({
  position: {
    vertical: 'top',
    horizontal: 'center',
  },
  setSnackbar: () => {},
});

interface SnackbarStateT {
  timestamp: number;
  open: boolean;
  message: string;
  variant: SnackbarTypesT;
  duration?: number; // in milliseconds
}

const defaultSnackbarState = (): SnackbarStateT => ({
  timestamp: Date.now(),
  open: false,
  message: '',
  variant: 'neutral',
});

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function MinglSnackbar(props: { children: JSX.Element }) {
  const materialTheme = useTheme();
  const greaterThanSmallScreen = useMediaQuery(
    materialTheme.breakpoints.up('sm')
  );

  const position = useMemo<SnackbarContextT['position']>(
    () => ({
      vertical: greaterThanSmallScreen ? 'top' : 'bottom',
      horizontal: 'center',
    }),
    [greaterThanSmallScreen]
  );

  const [snackbar, setSnackbar] = React.useState<SnackbarStateT>(
    defaultSnackbarState
  );

  const closeSnackbar = React.useCallback(() => {
    setSnackbar(defaultSnackbarState);
  }, []);

  const contextState = useMemo<SnackbarContextT>(
    () => ({
      position,
      setSnackbar: (message, variant, duration = 3000) =>
        setSnackbar({
          message,
          variant: requestStatusMap[variant] || variant,
          duration,
          open: true,
          timestamp: Date.now(),
        }),
    }),
    [setSnackbar, position]
  );

  return (
    <SnackbarContext.Provider value={contextState}>
      {props.children}
      <Snackbar
        key={snackbar.message}
        anchorOrigin={position}
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={snackbar.duration || 3000}
        onClose={closeSnackbar}
      >
        <SnackbarContentWrapper {...snackbar} onClose={closeSnackbar} />
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

function SnackbarContentWrapper(
  props: SnackbarStateT & { onClose: () => void }
) {
  const classes = useStyles();
  const { message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}
