interface MinimalCard {
  id: string;
  alias?: string | null;
  __typename: 'Card';
}

export function getCardPath(
  cardOrId: MinimalCard | string,
  pageNumber?: number
) {
  let path = '';

  if (typeof cardOrId === 'string') {
    path = `/c/${cardOrId}`;
  } else {
    path = cardOrId.alias ? `/${cardOrId.alias}` : `/c/${cardOrId.id}`;
  }

  return pageNumber ? `${path}#${pageNumber}` : path;
}
