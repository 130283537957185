import difference from 'lodash-es/difference';

function getAddedAndRemovedFromArray<T>(originalArray: T[], newValues: T[]) {
  const added = difference(newValues, originalArray);
  const removed = difference(originalArray, newValues);

  const newArray = [added, removed];

  return newArray;
}

export { getAddedAndRemovedFromArray };
