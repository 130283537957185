/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  width: number | string;
  height: number | string;
  className?: string;
  strokeWidth?: number;
}

const XIcon = ({
  width,
  height,
  color,
  className,
  strokeWidth = 1,
}: IconProps) => (
  <svg
    viewBox="0 0 14 14"
    width={width}
    height={height}
    color={color}
    display="block"
    className={className}
    aria-labelledby="title"
    name="x-icon"
  >
    <g
      stroke={color}
      strokeWidth={strokeWidth}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.95 11.95l-9.9-9.9M2.05 11.95l9.9-9.9" />
    </g>
  </svg>
);

export default XIcon;
