import * as React from 'react';
import localforage from 'localforage';
import debounce from 'lodash-es/debounce';

import { setPostAuthPath } from '../../app/storage/appStorage';
import { AuthServiceT, AuthState, AuthService } from '../services/auth';

const authStorage = localforage.createInstance({ name: 'auth' });
export const authService = new AuthService(authStorage);

export interface AuthProviderContextT {
  isAuthenticated: boolean;
  provider: string | null;
  logout: AuthServiceT['logout'];
  login: AuthServiceT['login'];
  createAccount: AuthServiceT['createAccount'];
  changePassword: AuthServiceT['changePassword'];
}

interface AuthProviderState {
  initialized: boolean;
  value: AuthProviderContextT;
}

interface AuthConsumerProps {
  children: (authProps: AuthProviderContextT) => React.ReactNode;
}

export const AuthContext = React.createContext<AuthProviderContextT>({
  isAuthenticated: false,
  provider: null,
  logout: authService.logout,
  login: authService.login,
  createAccount: authService.createAccount,
  changePassword: authService.changePassword,
});

/**
 * Auth context helper components
 */
export function AuthConsumer(props: AuthConsumerProps) {
  return <AuthContext.Consumer>{props.children}</AuthContext.Consumer>;
}

// eslint-disable-next-line
type ReactElementT = React.ReactElement<any>;

export function AuthenticatedBorder(props: {
  children: ReactElementT;
}): ReactElementT | null {
  const { isAuthenticated } = useAuthContext();
  if (!isAuthenticated) {
    return null;
  }
  return props.children;
}

export function UnauthenticatedBorder(props: {
  children: ReactElementT;
}): ReactElementT | null {
  const { isAuthenticated } = useAuthContext();
  if (!isAuthenticated) {
    return props.children;
  }
  return null;
}

/**
 * Provider implementation
 */
export class AuthProvider extends React.Component<{}, AuthProviderState> {
  private unsubscribe: () => void;
  state = {
    initialized: false,
    value: {
      isAuthenticated: false,
      provider: null,
      // the service functions will be phased out when useAuthContext
      // has replaced the other consumers of the auth context.
      login: authService.login,
      logout: authService.logout,
      changePassword: authService.changePassword,
      createAccount: authService.createAccount,
    },
  };

  componentDidMount() {
    this.unsubscribe = authService.subscribe(this.onAuthChange);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  onAuthChange = (authState: AuthState) => {
    this.setState(prevState => ({
      initialized: true,
      value: {
        ...prevState.value,
        isAuthenticated: authState.isAuthenticated,
        provider: authState.provider,
      },
    }));
  };

  render() {
    return (
      <AuthContext.Provider value={this.state.value}>
        {this.state.initialized && this.props.children}
      </AuthContext.Provider>
    );
  }
}

const timeoutPromise = (timeout: number) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};

/**
 * **useAuthContext**:
 *
 * Gives handy state and functions to manage authentication.
 * Does not handle analytics, do that in the component itself.
 *
 * Usage:
 * ```
 * const { isAuthenticated, triggerSignup } = useAuthContext();
 *
 * const handleSignup = React.useCallback(() => {
 *    analytics.track(...); // handle analytics in component
 *    triggerSignup();
 * });
 *
 * return (
 *   <button children="Sign Up" onClick={handleSignup} />
 * )
 * ```
 */
export function useAuthContext() {
  const { isAuthenticated } = React.useContext(AuthContext);

  const triggerSignUp = React.useCallback(
    debounce(
      async (returnPath: string = window.location.pathname) => {
        await setPostAuthPath(returnPath);
        await timeoutPromise(50); // wait for analytics
        authService.createAccount('/authCallback');
      },
      225,
      { leading: true, trailing: false }
    ),
    []
  );

  const triggerLogin = React.useCallback(
    debounce(
      async (returnPath: string = window.location.pathname) => {
        await setPostAuthPath(returnPath);
        await timeoutPromise(50); // wait for analytics
        authService.login('/authCallback');
      },
      225,
      { leading: true, trailing: false }
    ),
    []
  );

  const triggerLogout = React.useCallback(
    debounce(
      async () => {
        await timeoutPromise(50); // wait for analytics
        authService.logout();
      },
      225,
      { leading: true, trailing: false }
    ),
    []
  );

  return React.useMemo(
    () => ({
      triggerLogin,
      triggerSignUp,
      triggerLogout,
      isAuthenticated,
    }),
    [triggerLogin, triggerSignUp, triggerLogout, isAuthenticated]
  );
}
