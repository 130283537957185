import auth0 from 'auth0-js';
import idx from '../../../utils/idx';
import addDays from 'date-fns/add_days';
import * as sentry from '../../../utils/raven';
import appConfig from '../../../config';

const protocolMatch = /^(https?):$/.exec(window.location.protocol);
let protocol = protocolMatch ? protocolMatch[1] : 'https';

if (
  !protocol ||
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_AUTH_MODE === 'production'
) {
  protocol = 'https';
}

const rootUri = `${protocol}://${window.location.host}`;
const AUTH_KEY = 'authData';

const config = {
  development: {
    domain: 'mingl.eu.auth0.com',
    clientID: 'VClBeTwC5rRcawWdM34ulNsc1nV7cnHc',
    audience: `https://dev.minglapp.io/api`,
    redirectUri: `${rootUri}/`,
    returnTo: `${rootUri}/`,
  },
  production: {
    domain: 'mingl-live.eu.auth0.com',
    clientID: 'G2EckCX7YKHJ5tXSroQVWdPWlhaLkzEq',
    audience: `https://mingl.no/api`,
    redirectUri: `${rootUri}/`,
    returnTo: `${rootUri}/`,
  },
}[appConfig.backendMode || ''];

if (!config) {
  throw new Error('Authentication mode is misconfigured');
}

export type ValidCallbackRoutesT = '/' | '/home' | '/authCallback';
export type ValidLogoutRoutesT = '/';

const webAuth = new auth0.WebAuth({
  domain: config.domain,
  clientID: config.clientID,
  responseType: 'token id_token',
  audience: config.audience,
  scope: 'openid',
  redirectUri: config.redirectUri,
});

interface AuthJSON {
  accessToken: string;
  idToken: string;
  expiresAt: string;
  provider: string;
}

export interface AuthState extends AuthJSON {
  isAuthenticated: boolean;
  renewAfter: string | null;
}

interface StorageInterface<T> {
  setItem: (key: string, value: T) => Promise<T>;
  getItem: (key: string) => Promise<T>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  removeItem: (
    key: string,
    callback?: ((err: any) => void) | undefined
  ) => Promise<void>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

function initialAuthState(): AuthState {
  return {
    accessToken: '',
    idToken: '',
    expiresAt: '',
    isAuthenticated: false,
    renewAfter: null,
    provider: '',
  };
}

function normalizeAuthState(authJson?: AuthJSON): AuthState {
  if (!authJson) {
    return initialAuthState();
  }

  const expiresAt = JSON.parse(authJson.expiresAt || '0');
  const maxRenewAfter = addDays(new Date(), 1)
    .getTime()
    .toString();

  return {
    ...authJson,
    isAuthenticated: Boolean(authJson.accessToken),
    renewAfter: expiresAt < maxRenewAfter ? expiresAt : maxRenewAfter,
  };
}

type Subscriber = (authState: AuthState) => void;

export class AuthService {
  private initialized: boolean = false;
  private authStore: StorageInterface<AuthJSON>;
  private subscribers: Subscriber[] = [];
  private authState: AuthState = initialAuthState();

  constructor(store: StorageInterface<AuthJSON>) {
    this.authStore = store;
    this._initPromise = this.initializeAuthState();
  }

  /**
   * initializeAuthState
   * This handles:
   *  - authentication callbacks when users sign in
   *  - restoration of existing credentials
   *  - renewal of existing credentials
   */
  private _initPromise: Promise<void>;
  private initializeAuthState = async () => {
    if (this._initPromise) {
      return this._initPromise;
    }

    let authState = this.authState;
    try {
      if (
        /id_token/.test(location.hash) &&
        /access_token/.test(location.hash)
      ) {
        sentry.captureBreadcrumb('initializeAuthState: from location.hash');
        authState = await this.handleAuthenticationCallback();
      } else {
        sentry.captureBreadcrumb('initializeAuthState: from storage');
        authState = await this.authStore
          .getItem(AUTH_KEY)
          .then(normalizeAuthState);

        // if state was retrieved from local store, check if we should renew it
        if (this.shouldRenewSession(authState)) {
          sentry.captureBreadcrumb('initializeAuthState: renewing session');
          authState = await this.renewSession();
        }
      }
    } catch (error) {
      sentry.captureException(error, {
        extra: { event: 'initializeAuthState' },
      });
    }

    this.initialized = true;
    this.setAuthState(authState);
  };

  /**
   * setAuthState
   * The ONLY way to update the state
   */
  private setAuthState = (authState?: AuthState): void => {
    this.authState = authState ? authState : initialAuthState();

    if (!authState) {
      this.authStore.removeItem(AUTH_KEY);
    } else {
      this.authStore.setItem(AUTH_KEY, this.authState);
    }

    this.notifySubscribers(this.authState);
  };

  public getAuthState = async (): Promise<AuthState> => {
    if (!this.initialized) {
      await this._initPromise;
    }

    if (this.shouldRenewSession(this.authState)) {
      const authState = await this.renewSession();
      this.setAuthState(authState);
    }

    return this.authState;
  };

  private shouldRenewSession = ({ renewAfter }: AuthState) => {
    return renewAfter && renewAfter < Date.now().toString();
  };

  /**
   * renewSession
   * Does a background check with Auth0 to see if the user is authenticated.
   * Useful when the browser token has expired, but a renew token (managed by Auth0) is still valid
   */
  private renewSession = async (): Promise<AuthState> => {
    try {
      const authState = await this.checkSession();
      return authState;
    } catch (error) {
      return initialAuthState();
    }
  };

  /**
   * checkSession
   * Used to do background refresh of the users authentication token
   */
  private pendingCheckSessionPromise: Promise<AuthState> | undefined;
  private checkSession = (): Promise<AuthState> => {
    if (this.pendingCheckSessionPromise) {
      return this.pendingCheckSessionPromise;
    }

    this.pendingCheckSessionPromise = new Promise((resolve, reject) => {
      webAuth.checkSession({}, (auth0Error, authResult) => {
        this.pendingCheckSessionPromise = undefined;

        // error if automatic parseHash fails, possible errors:
        // https://openid.net/specs/openid-connect-core-1_0.html#AuthError
        if (auth0Error) {
          const error = new Error('Auth: Failed to renew session');
          sentry.captureException(error, {
            extra: {
              component: 'Auth Service',
              event: 'auth.checkSession',
              ...auth0Error,
            },
          });
          return reject(error);
        }
        resolve(this.parseDecodedHash(authResult));
      });
    });

    return this.pendingCheckSessionPromise;
  };

  /**
   * handleAuthenticationCallback
   * Used to parse the returned url hash from auth0 after the user has signed in
   */
  private handleAuthenticationCallback = async (): Promise<AuthState> => {
    return new Promise((resolve, reject) => {
      webAuth.parseHash((auth0Error, authResult) => {
        // clear the auth0 hash so tokens are not reported to sentry
        history.pushState(
          '',
          document.title,
          window.location.pathname + window.location.search
        );

        if (auth0Error) {
          return reject(auth0Error);
        }

        resolve(this.parseDecodedHash(authResult));
      });
    });
  };

  /**
   * parseDecodedHash
   * Parses the hash sent by Auth0 after the user has authenticated
   */
  private parseDecodedHash = (
    authResult?: auth0.Auth0DecodedHash
  ): AuthState => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      const { accessToken, idToken, expiresIn = 0 } = authResult;
      const idSub = idx(authResult, _ => _.idTokenPayload!.sub as string) || '';
      return normalizeAuthState({
        accessToken,
        idToken,
        provider: idSub.split('|')[0],
        expiresAt: JSON.stringify(expiresIn * 1000 + new Date().getTime()),
      });
    }
    return initialAuthState();
  };

  private notifySubscribers = (state: AuthState) => {
    this.subscribers.forEach(fn => fn(state));
  };

  public subscribe = (fn: Subscriber) => {
    this.subscribers.push(fn);
    return () => {
      const fnIndex = this.subscribers.indexOf(fn);
      this.subscribers.splice(fnIndex, 1);
    };
  };

  public unauthenticateUser = () => {
    this.setAuthState(initialAuthState());
    return this.authStore.removeItem(AUTH_KEY);
  };

  /**
   * The following functions are used to trigger certain user events related to auth
   */
  public login = (redirectPath?: ValidCallbackRoutesT): void => {
    const loginOpts = redirectPath
      ? { redirectUri: `${rootUri}${redirectPath}` }
      : { redirectUri: config.redirectUri };

    webAuth.authorize(loginOpts);
  };

  public createAccount = (returnPath?: ValidCallbackRoutesT) => {
    const path = returnPath || '/home';
    let options = {};
    options = {
      redirectUri: `${rootUri}${path}`,
      initial_screen: 'signUp',
    };
    webAuth.authorize(options);
  };

  public logout = (returnToPath?: ValidLogoutRoutesT): Promise<void> => {
    const logoutOpts = returnToPath
      ? { returnTo: `${rootUri}/${returnToPath}` }
      : { returnTo: config.returnTo };

    return this.unauthenticateUser().then(() => webAuth.logout(logoutOpts));
  };

  public changePassword = (email: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      webAuth.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function(err: auth0.Auth0Error | null, resp: any) {
          if (err) {
            return reject(err);
          } else {
            return resolve(resp);
          }
        }
      );
    });
  };
}

export type AuthServiceT = AuthService;
