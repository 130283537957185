import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';

import {
  CreateGroupEnquiryMutation,
  CreateGroupEnquiryMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import CREATE_ENQUIRY_RESOURCES from './CreateGroupEnquiryMutation.graphql';
import { useCallback } from 'react';
import idx from '../utils/idx';

type DataT = CreateGroupEnquiryMutation;
type VariablesT = CreateGroupEnquiryMutationVariables;

export type CreateGroupEnquiryMutationFnT = MutationFunction<DataT, VariablesT>;

export const useCreateGroupEnquiryMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(CREATE_ENQUIRY_RESOURCES, {
    ...options,
  });
};

// ============================================================================
// Mutation handler

type MutationOutputT = DataT['createGroupEnquiry'];

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledCreateGroupEnquiryMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [createGroupEnquiry] = useCreateGroupEnquiryMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'CreateGroupEnquiryMutationOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
      };

      try {
        const res = await createGroupEnquiry(options);
        return idx(res, _ => _!.data!.createGroupEnquiry) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [createGroupEnquiry]
  );

  return handledMutationFn;
};
