import * as React from 'react';
import Button from './Button';

interface CircularButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: number;
}

export function CircularButton(props: CircularButtonProps) {
  const style = props.style || {};
  return (
    <Button
      {...props}
      style={{
        width: props.size ? `${props.size}px` : undefined,
        height: props.size ? `${props.size}px` : undefined,
        ...style,
        padding: 0,
        borderRadius: '50%',
      }}
    />
  );
}

/**
 * CenteredCircularButton
 * Because of iOS Safari 9 <button> cannot reliably be a flex parent, so
 * to center icons and text properly we nest a div.
 */
export default function CenteredCircularButton(props: CircularButtonProps) {
  return (
    <CircularButton {...props}>
      <div
        className="w-100 h-100 flex items-center justify-center"
        style={{
          width: props.size ? `${props.size}px` : undefined,
          height: props.size ? `${props.size}px` : undefined,
        }}
      >
        {props.children}
      </div>
    </CircularButton>
  );
}
