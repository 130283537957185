import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';
import {
  UpdateGroupResourceMutation,
  UpdateGroupResourceMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import UPDATE_GROUP_RESOURCES from './UpdateGroupResourceMutation.graphql';
import { useCallback } from 'react';
import idx from '../utils/idx';

type DataT = UpdateGroupResourceMutation;
type VariablesT = UpdateGroupResourceMutationVariables;

export type UpdateGroupResourceMutationFnT = MutationFunction<
  DataT,
  VariablesT
>;

export const useUpdateGroupResourceMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(UPDATE_GROUP_RESOURCES, {
    ...options,
  });
};

// ============================================================================
// Mutation handler

type MutationOutputT = NonNullable<DataT['updateGroupResource']>;

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledUpdateGroupResourceMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [updateGroupResource] = useUpdateGroupResourceMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'UpdateGroupResourceMutationOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
        updatedResource: null,
        validation: null,
        groupId: idx(options, _ => _.variables!.input.groupId) || '',
      };

      try {
        const res = await updateGroupResource(options);
        return idx(res, _ => _!.data!.updateGroupResource) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [updateGroupResource]
  );

  return handledMutationFn;
};
