import * as React from 'react';
import idx from '../utils/idx';
import Downshift from 'downshift';
import { Link } from '@reach/router';
import clsx from 'clsx';

import './Search.css';
import analytics, { analyticsEvents } from '../utils/analytics';
import CardSearchQuery, { CardSearchNode } from '../queries/CardSearchQuery';
import * as cardNavigation from '../modules/card/utils/cardNavigation';

const DOWNSHIFT_MOUSEUP = Downshift.stateChangeTypes.mouseUp;
const DOWNSHIFT_KEYDOWNENTER = Downshift.stateChangeTypes.keyDownEnter;

const DEBOUNCE_TIMEOUT = 300;

interface SearchProps {
  className?: string;
  navigate: (path: string) => void;
  onBlur?: () => void;
  analyticsContext: string;
  forwardedRef?:
    | React.RefObject<HTMLInputElement>
    | React.MutableRefObject<HTMLInputElement>;
}

interface SearchState {
  inputValue: string;
  queryValue: string;
  isOpen: boolean;
}

const initialState: SearchState = {
  inputValue: '',
  queryValue: '',
  isOpen: false,
};

class Search extends React.Component<SearchProps, SearchState> {
  private inputRef: React.RefObject<HTMLInputElement> | null = null;
  private timeout: NodeJS.Timer | undefined;

  constructor(props: SearchProps) {
    super(props);
    this.state = initialState;
    this.inputRef = props.forwardedRef || React.createRef<HTMLInputElement>();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onChange = (value?: string) => {
    const inputValue = value || '';
    const searchString = inputValue.trim();
    if (searchString === '') {
      this.setState({ inputValue });
    } else {
      this.setState({ inputValue, isOpen: true });
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(this.updateQueryValue, DEBOUNCE_TIMEOUT);
    }
  };

  updateQueryValue = () => {
    this.setState(prevState => {
      this.trackSearchPerformed(prevState.inputValue);
      return { queryValue: prevState.inputValue };
    });
  };

  trackSearchPerformed = (query: string) => {
    analytics.track(analyticsEvents.search.performed, {
      query,
      context: this.props.analyticsContext,
    });
  };

  trackShowMore = (query: string) => {
    analytics.track(analyticsEvents.pages.showMoreSearch, {
      query,
      context: this.props.analyticsContext,
    });
  };

  trackResultSelected = (data: { selectedId: string; query: string }) => {
    analytics.track(analyticsEvents.search.resultSelected, {
      ...data,
      context: this.props.analyticsContext,
    });
  };

  render(): JSX.Element {
    const { className = '' } = this.props;
    const state = this.state;

    return (
      <Downshift
        inputValue={state.inputValue}
        isOpen={!!state.inputValue && state.isOpen}
        itemToString={(item?: CardSearchNode) => ''}
        onInputValueChange={inputValue =>
          this.onChange(inputValue || undefined)
        }
        onStateChange={changes => {
          if (state.isOpen && changes.type === DOWNSHIFT_MOUSEUP) {
            if (this.inputRef && this.inputRef.current) {
              this.inputRef.current.blur();
            }
            this.setState({ isOpen: false });
          }

          if (state.isOpen && changes.type === DOWNSHIFT_KEYDOWNENTER) {
            if (this.inputRef && this.inputRef.current) {
              this.inputRef.current.blur();
            }
            this.setState({ isOpen: false });

            const selectedItem = idx(changes, _ => _.selectedItem) || {};
            if (selectedItem.type === 'link') {
              this.props.navigate(`${selectedItem.path}`);
            } else if (typeof selectedItem.id === 'string') {
              this.trackResultSelected({
                selectedId: selectedItem.id,
                query: state.inputValue,
              });
              this.props.navigate(cardNavigation.getCardPath(selectedItem));
            }
          }
        }}
        children={({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div className="relative">
            <input
              type="search"
              ref={this.inputRef}
              onFocus={() => this.setState({ isOpen: true })}
              className={`w-100 pa1 lh-copy mingl-input ${className}`}
              tabIndex="0"
              {...getInputProps({
                placeholder: 'Search',
                onBlur: this.props.onBlur,
              })}
            />

            <div
              className="custom-popup-wrapper absolute w-100 cf"
              style={{
                display: isOpen ? 'block' : 'none',
              }}
            >
              <ul className="list pl0 mv0 dropdown-menu" role="listbox">
                {!!state.queryValue && state.isOpen && (
                  <CardSearchQuery
                    search={state.queryValue}
                    first={5}
                    after=""
                    render={data => {
                      const hasMoreData = idx(
                        data,
                        _ => _.cardSearch!.pageInfo.hasNextPage
                      );
                      const cards = (
                        idx(data, _ => _.cardSearch!.edges) || []
                      ).map(edge => edge.node);

                      return (
                        <React.Fragment>
                          {cards.map((card, index) => (
                            <Link
                              key={card.id}
                              to={cardNavigation.getCardPath(card)}
                              className="db no-underline color-inherit"
                              onClick={() =>
                                this.trackResultSelected({
                                  selectedId: card.id,
                                  query: state.inputValue,
                                })
                              }
                            >
                              <li
                                {...getItemProps({ item: card, index })}
                                className={clsx('ph2 pt2 pb1', {
                                  black: highlightedIndex !== index,
                                  'bg-mingl-steel-blue white':
                                    highlightedIndex === index,
                                })}
                              >
                                <div className="flex items-center">
                                  <img
                                    src={
                                      card.imageThumbnailURL ||
                                      require('../assets/img/profile_placeholder.png')
                                    }
                                    className="pointer br-pill mr2"
                                    style={{
                                      height: '2.5rem',
                                      width: '2.5rem',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <div className="flex flex-column">
                                    <div className="f4 lh-title">
                                      <span>{card.title}</span>
                                    </div>

                                    <div className="f5 lh-copy">
                                      {card.subtitle}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </Link>
                          ))}

                          {hasMoreData && (
                            <Link
                              key="showmore"
                              to={`/search?query=${state.queryValue}`}
                              className="db no-underline"
                              onClick={() =>
                                this.trackShowMore(state.inputValue)
                              }
                            >
                              <li
                                {...getItemProps({
                                  item: {
                                    type: 'link',
                                    path: `/search?query=${state.queryValue}`,
                                  },
                                  index: cards.length,
                                })}
                                className={clsx('ph2 pt2 pb1', {
                                  black: highlightedIndex !== cards.length,
                                  'bg-mingl-steel-blue white':
                                    highlightedIndex === cards.length,
                                })}
                              >
                                <div
                                  className="flex items-center justify-center f4"
                                  style={{ height: '2.5rem' }}
                                  children="Show More"
                                />
                              </li>
                            </Link>
                          )}
                        </React.Fragment>
                      );
                    }}
                  />
                )}
              </ul>
            </div>
          </div>
        )}
      />
    );
  }
}

export default Search;
