/* tslint:disable:max-line-length */
import React from 'react';

interface IconProps {
  color: string;
  size: number | string;
  className?: string;
  style?: React.CSSProperties;
}

const AddressBookIcon = ({ size, color, className, style }: IconProps) => (
  <svg
    height={size}
    className={className}
    style={style}
    fill={color}
    viewBox="0 0 23 26"
  >
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M.734 8.125a.611.611 0 01-.609-.61v-2.03c0-.336.274-.61.61-.61H1.75V2.437A2.438 2.438 0 014.188 0h16.25a2.438 2.438 0 012.437 2.438v21.125A2.438 2.438 0 0120.437 26H4.188a2.438 2.438 0 01-2.437-2.438v-2.437H.734a.611.611 0 01-.609-.61v-2.03c0-.336.274-.61.61-.61H1.75v-3.25H.734a.611.611 0 01-.609-.61v-2.03c0-.336.274-.61.61-.61H1.75v-3.25H.734zm2.944 14.862a1 1 0 001 1H20.02a1 1 0 001-1V3.034a1 1 0 00-1-1H4.678a1 1 0 00-1 1v19.953zM12.313 13a3.253 3.253 0 01-3.25-3.25 3.253 3.253 0 013.25-3.25 3.253 3.253 0 013.25 3.25 3.253 3.253 0 01-3.25 3.25zm4.55 6.5h-9.1c-.63 0-1.138-.437-1.138-.975v-.975c0-1.615 1.529-2.925 3.412-2.925.549 0 .95.406 2.275.406 1.367 0 1.697-.406 2.276-.406 1.883 0 3.412 1.31 3.412 2.925v.975c0 .538-.508.975-1.137.975z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path
          transform="matrix(-1 0 0 1 22.875 0)"
          fill={color}
          d="M0 0h22.75v26H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AddressBookIcon;
