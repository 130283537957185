import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
} from 'react-apollo';

import {
  UpdateCard as UpdateCardMutationT,
  UpdateCardVariables as UpdateCardMutationVariables,
} from '../__generated/apollogen-types';
import UPDATE_CARD_MUTATION from './CardMutation.graphql';

type DataT = UpdateCardMutationT;
type VariablesT = UpdateCardMutationVariables;

export type UpdateCardMutationFnT = MutationFunction<DataT, VariablesT>;

export const useUpdateCardMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(UPDATE_CARD_MUTATION, options);
};
