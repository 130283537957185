import * as React from 'react';
import clsx from 'clsx';
import MuiButton, {
  ButtonProps as MuiButtonPropsT,
} from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dangerButton: {
    color: theme.palette.error.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
  },
  dangerButtonContained: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  dangerButtonOutlined: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  dangerButtonText: {
    color: theme.palette.error.main,
  },
}));

export function DangerButton(props: MuiButtonPropsT) {
  const styles = useStyles();
  const variant = props.variant || 'text';
  const className = clsx(styles.dangerButton, {
    [styles.dangerButtonText]: variant === 'text',
    [styles.dangerButtonContained]: variant === 'contained',
    [styles.dangerButtonOutlined]: variant === 'outlined',
  });

  return <MuiButton className={className} {...props} />;
}

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  fontSize?: number;
}

export default function Button({
  fontSize = 6,
  className = '',
  ...props
}: ButtonProps): JSX.Element | null {
  const buttonClasses = `f${fontSize} ${className}`;

  return <button type="button" {...props} className={buttonClasses} />;
}
