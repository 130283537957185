import * as React from 'react';
import { navigate } from '@reach/router';
import idx from '../../../utils/idx';
import isNumber from 'lodash-es/isNumber';

import analytics, { analyticsEvents, wait } from '../../../utils/analytics';
import { captureException } from '../../../utils/raven';
import { CardNodeFragment } from '../../../__generated/apollogen-types';
import UserNotFound from '../../app/components/UserNotFound';
import {
  ViewCardsMutationFnT,
  useViewCardsMutation,
} from '../../../queries/ViewCardsMutation';
import { AuthContext } from '../../core/providers/AuthProvider';
import Loader from '../../../components/Loader';
import { SiteBackground } from '../../app/containers/SiteBackground';
import PageContainer from '../../../containers/PageContainer';
import { NavComponents } from '../../Navigation/HeaderNavigationComponents';
import { SetCardViewFnT, CardViewEnumT } from './DisplayCardModal/types';
import { makeDynamicLink } from '../../../utils/minglUrlUtil';
import { colors } from '../../ui/theme';
import Helmet from 'react-helmet';

const loadDisplayCard = () => import('./DisplayCardModal');
const LazyDisplayCard = React.lazy(loadDisplayCard);

export const preload = loadDisplayCard;

const crossDotPattern = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23002531' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;

interface State {
  showCardModal: boolean;
}

interface DecoratedProps {
  viewCardsMutation: ViewCardsMutationFnT;
  isAuthenticated: boolean;
  setCardView: SetCardViewFnT;
  currentCardView: CardViewEnumT;
}

interface ParentProps {
  cardUri: string;
  card: CardNodeFragment | null;
  returnPath: string;
}

type Props = ParentProps & DecoratedProps;

export class MinglCardPage extends React.Component<Props, State> {
  state = { showCardModal: true, bottomSheetOpen: false };

  recordCardView = () => {
    const { isAuthenticated } = this.props;
    const card = idx(this.props, _ => _.card);
    const ownedByViewer = idx(this.props, _ => _.card!.owner!.isViewer);

    if (!card) {
      return;
    }

    analytics.track(analyticsEvents.card.viewed, {
      isAuthenticated,
      twoWayEnabled: card.twoWayEnabled,
      ownCard: Boolean(ownedByViewer),
    });

    this.props
      .viewCardsMutation({
        variables: {
          input: {
            views: [{ cardId: card.id, createdAt: new Date().toISOString() }],
          },
        },
      })
      .catch(error => {
        captureException(error, { extra: { component: 'MinglCardPage' } });
      });
  };

  componentDidMount() {
    preload();
    this.recordCardView();
  }

  componentDidUpdate(prevProps: Props) {
    const id = idx(this.props, _ => _.card!.id);
    const prevId = idx(prevProps, _ => _.card!.id);

    if (this.props.card && prevId !== id) {
      this.recordCardView();
    }
  }

  render(): JSX.Element {
    const { card, currentCardView, setCardView } = this.props;

    if (!card) {
      return <UserNotFound />;
    }

    return (
      <PageContainer
        redirectUnauthenticated={false}
        mainStyle={{
          backgroundColor: colors.blue800,
          backgroundImage: crossDotPattern,
        }}
        mobileNavItem={
          <NavComponents.Back
            returnPath={this.props.returnPath}
            onClick={(returnPath: string) => {
              if (currentCardView != CardViewEnumT.details) {
                setCardView(CardViewEnumT.details);
              } else {
                navigate(returnPath);
              }
            }}
          />
        }
      >
        <Helmet
          onChangeClientState={() => {
            // @ts-ignore
            window.prerenderReady = true;
          }}
        >
          <title
            children={[card.title, card.workTitle].filter(Boolean).join(' – ')}
          />
          <meta
            property="og:title"
            content={[card.title, card.workTitle].filter(Boolean).join(' – ')}
          />
          <meta property="og:description" content={``} />
          <meta property="og:site_name" content={`${card.title} on Mingl`} />
          <meta property="og:image" content={card.imageURL} />
        </Helmet>
        <div className="tc">
          <SiteBackground background={colors.blue800} />
          <React.Suspense fallback={<Loader />}>
            <LazyDisplayCard
              card={card}
              currentView={this.props.currentCardView}
              setCurrentView={this.props.setCardView}
            />
          </React.Suspense>
        </div>
      </PageContainer>
    );
  }
}

const parsePageHash = (hash: string) => {
  const pageNumber = parseInt(hash.replace('#', ''), 10);
  const validPageNumbers = [
    CardViewEnumT.details,
    CardViewEnumT.share,
    CardViewEnumT.shareBack,
  ];
  if (isNumber(pageNumber) && validPageNumbers.includes(pageNumber)) {
    return pageNumber;
  }
  return CardViewEnumT.details;
};

const usePageHash = (): [CardViewEnumT, SetCardViewFnT] => {
  const [pageParam, setPageParam] = React.useState<CardViewEnumT>(() => {
    return parsePageHash(window.location.hash);
  });

  React.useEffect(() => {
    window.onhashchange = () => {
      setPageParam(parsePageHash(window.location.hash));
    };
    return () => {
      window.onhashchange = null;
    };
  }, []);

  const newSetPageParam = React.useCallback((param: CardViewEnumT) => {
    window.location.hash = param === CardViewEnumT.details ? '' : `${param}`;
  }, []);

  return [pageParam, newSetPageParam];
};

export default function MinglCardPageContainer(props: ParentProps) {
  const { isAuthenticated = false } = React.useContext(AuthContext);
  const [currentCardView, setCardView] = usePageHash();
  const [viewCardsMutation] = useViewCardsMutation();

  return (
    <MinglCardPage
      {...props}
      setCardView={setCardView}
      currentCardView={currentCardView}
      viewCardsMutation={viewCardsMutation}
      isAuthenticated={isAuthenticated}
    />
  );
}
