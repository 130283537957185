import * as React from 'react';

interface FormLabelProps {
  htmlFor: string;
  children: React.ReactNode;
  className?: string;
  hasError?: boolean;
  color?: string;
  style?: React.CSSProperties;
}

export function FormLabel({
  className = '',
  htmlFor,
  children,
  hasError = false,
  color,
  style,
}: FormLabelProps) {
  return (
    <label
      className={`dib f6 fw4   ${
        hasError ? 'mingl-light-red' : color ? color : 'white'
      } ${className}`}
      style={{ ...style }}
      htmlFor={htmlFor}
      children={children}
    />
  );
}
