'use strict';

/**
 * React app polyfill ie11 contains:
 *  - Promise
 *  - Fetch
 *  - Array.from
 *  - Object.assign
 *  - Symbol
 */
require('react-app-polyfill/ie11');

// own polyfills
require('core-js/es6/set');
require('core-js/es6/map');
require('core-js/fn/array/find');
require('core-js/fn/array/includes');
require('core-js/fn/number/is-nan');
require('core-js/fn/object/keys');
require('core-js/fn/object/values');
require('core-js/fn/object/entries');
require('core-js/fn/string/repeat');
require('core-js/fn/string/starts-with');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}
