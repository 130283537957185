import { QueryHookOptions, useQuery } from 'react-apollo';

import { GroupListQuery as GroupListQueryT } from '../__generated/apollogen-types';
import GROUP_LIST_QUERY from './GroupListQuery.graphql';
import { getOperationName } from 'apollo-link';

type DataT = GroupListQueryT;
type VariablesT = null;

export const useGroupListQuery = (
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery(GROUP_LIST_QUERY, options);
};

export const getGroupListQueryName = () =>
  getOperationName(GROUP_LIST_QUERY) || '';
