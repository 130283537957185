import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
} from 'react-apollo';

import {
  ScanCards as ScanCardsMutationT,
  ScanCardsVariables as ScanCardsMutationVariables,
} from '../__generated/apollogen-types';
import SCAN_CARDS_MUTATION from './ScanCardsMutation.graphql';

type DataT = ScanCardsMutationT;
type VariablesT = ScanCardsMutationVariables;

type ScanCardsMutationOpts = MutationComponentOptions<DataT, VariablesT>;
export type ScanCardsMutationFnT = MutationFunction<DataT, VariablesT>;

export function ScanCardsMutation(props: {
  children: ScanCardsMutationOpts['children'];
}) {
  return (
    <Mutation<DataT, VariablesT> {...props} mutation={SCAN_CARDS_MUTATION} />
  );
}
