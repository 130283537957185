import * as React from 'react';
import CircledPlusIcon from '../icons/CircledPlusIcon';
import RejectedIcon from '../icons/RejectedIcon';
import UploadImage from '../icons/UploadImage';
import CircularButton from '../../modules/ui/CircularButton';
import Loader from '../Loader';
import * as theme from '../../modules/ui/theme';
import DeviceCameraIcon from '../icons/DeviceCameraIcon';

const dropViewStyles = {
  color: 'white',
  iconSize: '75',
  noImageBg: '#00384d',
  acceptedBg: '#00b7ae',
  rejectedBg: '#f16667',
};

export function DropzoneViewBox({
  backgroundColor,
  children,
}: {
  backgroundColor: string;
  children: React.ReactNode;
}) {
  return (
    <div
      style={{
        backgroundColor,
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'default',
      }}
    >
      {children}
    </div>
  );
}

interface ModeProps {
  fsDialog: () => void;
}

export const renderNoImage = (props: ModeProps) => (
  <DropzoneViewBox
    backgroundColor={dropViewStyles.noImageBg}
    children={
      <React.Fragment>
        <UploadImage
          size={dropViewStyles.iconSize}
          color={dropViewStyles.color}
        />
        <p
          className="f5 lh-copy"
          style={{
            color: dropViewStyles.color,
            padding: '0 35px',
            margin: 0,
            transform: 'translateY(30px)',
          }}
        >
          Drag image file here to upload your profile image
        </p>
        <CircularButton
          className="absolute right-1 bg-mingl-green bn pointer"
          size={46}
          onClick={props.fsDialog}
          style={{ bottom: '-23px' }}
          children={
            <CircledPlusIcon
              size={'40'}
              color={'white'}
              borderColor={'#00b7ae'}
            />
          }
        />
      </React.Fragment>
    }
  />
);
export const renderImageAccept = () => (
  <DropzoneViewBox
    backgroundColor={dropViewStyles.acceptedBg}
    children={
      <React.Fragment>
        <CircledPlusIcon
          size={`${dropViewStyles.iconSize}`}
          color={dropViewStyles.color}
        />
        <p
          className="f5 lh-copy"
          style={{
            color: dropViewStyles.color,
            margin: 0,
            transform: 'translateY(30px)',
          }}
        >
          Upload image
        </p>
      </React.Fragment>
    }
  />
);
export const renderImageReject = (fsDialog: () => void) => (
  <DropzoneViewBox
    backgroundColor={dropViewStyles.rejectedBg}
    children={
      <React.Fragment>
        <RejectedIcon
          size={dropViewStyles.iconSize}
          color={dropViewStyles.color}
        />
        <p
          className="f5 lh-copy"
          style={{
            color: dropViewStyles.color,
            padding: '0 35px',
            margin: 0,
            transform: 'translateY(30px)',
          }}
        >
          File must be either jpeg, jpg or png, and under 10 MB
        </p>
        <CircularButton
          className="absolute right-1 top-1 bg-mingl-green bn pointer"
          size={42}
          onClick={fsDialog}
          children={<DeviceCameraIcon width={18} height={18} color={'white'} />}
        />
      </React.Fragment>
    }
  />
);

export const renderImageUploading = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 150,
      background: theme.colors.gray900,
    }}
  >
    <Loader color={'white'} />
  </div>
);
