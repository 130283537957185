/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  borderColor?: string;
  size: number | string;
}

const CircledPlusIcon = ({ color, size, borderColor }: IconProps) => (
  <svg viewBox="0 0 60 60" width={size} height={size} name="circled-plus">
    <g fill="none" fillRule="evenodd">
      <circle
        cx={30}
        cy={30}
        r={29}
        stroke={borderColor || color}
        strokeWidth={2}
      />
      <path d="M31 29h6v2h-6v6h-2v-6h-6v-2h6v-6h2z" fill={color} />
    </g>
  </svg>
);

export default CircledPlusIcon;
