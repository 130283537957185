import * as React from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';

import {
  AuthenticatedBorder,
  UnauthenticatedBorder,
} from '../../core/providers/AuthProvider';
import Loader from '../../../components/Loader';
import PageContainer from '../../../containers/PageContainer';

const LazyHome = React.lazy(() => import('../containers/ViewerHome'));

function ViewerHomeRoute(props: RouteComponentProps) {
  return (
    <PageContainer>
      <React.Suspense fallback={<Loader />}>
        <LazyHome />
      </React.Suspense>
    </PageContainer>
  );
}

export default ViewerHomeRoute;
