import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
} from 'react-apollo';
import {
  claimAlias as ClaimAliasMutationT,
  claimAliasVariables as ClaimAliasMutationVariables,
} from '../__generated/apollogen-types';
import CLAIM_ALIAS_MUTATION from './ClaimAliasMutation.graphql';

type DataT = ClaimAliasMutationT;
type VariablesT = ClaimAliasMutationVariables;

type ClaimAliasMutationOpts = MutationComponentOptions<DataT, VariablesT>;
export type ClaimAliasMutationFnT = MutationFunction<DataT, VariablesT>;

export function ClaimAliasMutation(props: {
  children: ClaimAliasMutationOpts['children'];
}) {
  return (
    <Mutation<DataT, VariablesT> {...props} mutation={CLAIM_ALIAS_MUTATION} />
  );
}
