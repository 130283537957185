import * as React from 'react';
import { colors } from '../modules/ui/theme';

const containerStyle: React.CSSProperties = {
  flexGrow: 0,
  flexShrink: 0,
  textAlign: 'center',
};
const loaderStyle = (size: number): React.CSSProperties => ({
  display: 'inline-block',
  fontSize: `${size}rem`,
  height: `${2 * size}rem`,
  paddingTop: `${size}rem`,
  paddingBottom: `${size}rem`,
  width: `${4 * size}rem`,
});

export default function Loader(props: {
  color?: string;
  size?: number;
}): JSX.Element {
  const size = props.size || 1;
  return (
    <div style={containerStyle}>
      <div style={loaderStyle(size)}>
        <div
          className="loader"
          style={{ color: props.color || colors.green100 }}
        />
      </div>
    </div>
  );
}
