import * as React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';

import * as api from '../../../api';
import { defaultValues, FormValues as CardFormValues } from './cardFormValues';
import { UpdateCardMutationFnT } from '../../../queries/CardMutation';
import { getViewerQueryName } from '../../../queries/ViewerQuery';
import { gqlUpdateCardSchema, cardFormSchema } from './cardFormSchema';
import {
  Viewer_viewer,
  Viewer_viewer_cards,
} from '../../../__generated/apollogen-types';

class FormikCardForm extends Formik<{}, CardFormValues> {}

type VoidFn = () => void;

interface FormChildProps extends FormikProps<CardFormValues> {
  onDone: VoidFn | null;
  onCancel: VoidFn | null;
  onImageDrop: api.UploadImageFn;
}

export interface CardFormProps {
  viewer: Viewer_viewer;
  card: Viewer_viewer_cards;
  mutateCard: UpdateCardMutationFnT;
  onDone: FormChildProps['onDone'];
  onCancel: FormChildProps['onCancel'];
  onImageDrop: FormChildProps['onImageDrop'];
  children: (formProps: FormChildProps) => React.ReactNode;
}

interface CardFormState {
  initialValues: CardFormValues;
}

export class CardForm extends React.Component<CardFormProps, CardFormState> {
  constructor(props: CardFormProps) {
    super(props);
    this.state = {
      initialValues: this.parseInitialValues(props.card, props.viewer),
    };
  }

  parseInitialValues = (
    card: Viewer_viewer_cards,
    viewer: Viewer_viewer
  ): CardFormValues => {
    const formCard: Omit<
      CardFormValues,
      'firstName' | 'lastName' | 'acceptsTerms'
    > = {
      id: card.id,
      email: card.email,
      workTitle: card.workTitle,
      address: card.address,
      phoneNumber: card.phoneNumber,
      imageURL: card.imageURL,
      biography: card.biography,
      links: card.links,
      privacy: card.privacy,
    };

    const links = defaultValues.links.map(
      defaultLink =>
        formCard.links.find(link => link.label === defaultLink.label) ||
        defaultLink
    );

    return {
      ...defaultValues,
      ...formCard,
      links,
      firstName: viewer.firstName,
      lastName: viewer.lastName,
      acceptsTerms: viewer.acceptsTerms,
    };
  };

  onImageDrop = (img: File, fn: api.ProgressCallback) => {
    return this.props.onImageDrop(img, fn).then(url => {
      return url;
    });
  };

  onSubmit = (
    values: CardFormValues,
    formikActions: FormikActions<CardFormValues>
  ) => {
    const variables = {
      input: gqlUpdateCardSchema.cast(values),
    };

    const mutateOptions = {
      variables,
      refetchQueries: () => [getViewerQueryName()],
      awaitRefetchQueries: true,
    };

    this.props.mutateCard(mutateOptions).then(
      () => {
        formikActions.setSubmitting(false);
        if (this.props.onDone) {
          setTimeout(this.props.onDone);
        }
      },
      errors => {
        formikActions.setSubmitting(false);
        formikActions.setErrors(errors);
      }
    );
  };

  render(): JSX.Element | null {
    return (
      <FormikCardForm
        initialValues={this.state.initialValues}
        validationSchema={cardFormSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={this.onSubmit}
        render={formikProps => {
          return this.props.children({
            ...formikProps,
            onDone: this.props.onDone || null,
            onCancel: this.props.onCancel || null,
            onImageDrop: this.onImageDrop,
          });
        }}
      />
    );
  }
}
