import * as React from 'react';
import analytics from '../../utils/analytics';

type AnchorPropsT = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export interface AnalyticsLinkPropsT extends AnchorPropsT {
  analyticsEvent: string;
  analyticsData?: { [key: string]: string | number | undefined };
}

/**
 * Considerations:
 * - Timeouts before window.open(href) doesn't work on iOS9, but newer versoins do
 * - Google seem to use this method (https://support.google.com/analytics/answer/1136920?hl=en)
 * - Alternatives considered:
 *   - https://stackoverflow.com/questions/199731/best-practice-how-to-track-outbound-links/33357842#33357842
 */

export const AnalyticsLink = (props: AnalyticsLinkPropsT) => {
  const { analyticsEvent, analyticsData, ...anchorProps } = props;
  const href = anchorProps.href;

  return (
    <a
      {...anchorProps}
      onClick={async e => {
        analytics.track(props.analyticsEvent, {
          href,
          ...analyticsData,
        });
        return false;
      }}
    />
  );
};
