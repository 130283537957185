/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  width: number | string;
  height: number | string;
  className?: string;
}

const MinglIcon = ({ width, height, color, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    role="img"
    aria-labelledby="title"
    viewBox="0 0 16 30"
    name="mingl-icon"
  >
    <title>Mingl icon</title>
    <g fillRule="evenodd" fill={color}>
      <path d="M8.168 10.397c-2.154 0-3.98-1.65-3.98-3.862s1.826-3.863 3.98-3.863 3.981 1.651 3.981 3.863c0 2.211-1.827 3.862-3.98 3.862zM8.168 0c-4.08 0-6.98 3.015-6.98 6.535s2.9 6.534 6.98 6.534 6.98-3.015 6.98-6.534c0-3.52-2.9-6.535-6.98-6.535zM8.168 27.327c-2.154 0-3.98-1.65-3.98-3.862 0-2.211 1.826-3.862 3.98-3.862s3.981 1.65 3.981 3.862-1.827 3.862-3.98 3.862zm0-10.396c-4.08 0-6.98 3.015-6.98 6.534 0 3.52 2.9 6.535 6.98 6.535s6.98-3.015 6.98-6.535-2.9-6.534-6.98-6.534zM3.861 15a1.93 1.93 0 1 1-3.86 0 1.93 1.93 0 0 1 3.86 0" />
    </g>
  </svg>
);

export default MinglIcon;
