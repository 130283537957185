import * as yup from 'yup';
import {
  UpdateCardInput,
  ImageTransformInput,
} from '../../../__generated/apollogen-types';
import {
  validateImageUrl,
  validateCardLinks,
  validateCardPrivacyLevel,
  validatePhoneNumber,
  validateName,
  validateEmail,
} from '../../../common/validation/editCardValidationRules';

const imageTransformShape: EnforceShapeOf<Required<ImageTransformInput>> = {
  x: yup.number().notRequired(),
  y: yup.number().notRequired(),
  scale: yup.number().notRequired(),
};

const imageTransformValidation = yup
  .object()
  .shape(imageTransformShape)
  .noUnknown()
  .notRequired();

const cardSchemaShape: EnforceShapeOf<UpdateCardInput> = {
  id: yup.string().notRequired(),
  firstName: validateName,
  lastName: validateName,
  workTitle: yup.string().default(''),
  biography: yup.string().default(''),
  imageURL: validateImageUrl.default(''),
  imageTransform: imageTransformValidation,
  email: validateEmail,
  phoneNumber: validatePhoneNumber.default(''),
  links: validateCardLinks,
  address: yup.string().default(''),
  privacy: validateCardPrivacyLevel,
};

export const cardFormSchema = yup.object().shape({
  ...cardSchemaShape,
});

export const gqlUpdateCardSchema = yup
  .object<UpdateCardInput>()
  .noUnknown(true)
  .shape(cardSchemaShape);
