/// <reference types="@types/segment-analytics" />

import idx from '../utils/idx';
import noop from 'lodash-es/noop';
import { commonAnalyticsEvents } from '../common/analytics';

type VoidFn = () => void;
type AnalyticsJS = SegmentAnalytics.AnalyticsJS;
type SegmentOpts = SegmentAnalytics.SegmentOpts;

declare global {
  interface Window {
    analytics: AnalyticsJS;
  }
}

export const wait = (timeout: number) =>
  new Promise(resolve => setTimeout(resolve, timeout));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function findAndCallCallback(...args: any[]) {
  for (const arg of args) {
    if (typeof arg === 'function') {
      arg();
    }
  }
}

/**
 * Naming conventions:
 * We are using the Object-Action framework:
 *  - https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 *  - Use "Proper Case" for events
 *  - Use "snake_case" for properties
 */

const webOnlyAnalyticsEvents = {
  pages: {
    viewedQR: 'Scan QR Modal Viewed',
    dismissedQR: 'Scan QR Modal Dismissed',
    showMoreSearch: 'Search Show More',
    pageNotFound: 'Page Not Found',
    pageViewLoggedIn: 'User Page View Logged In',
    pageViewNotLoggedIn: 'User Page View Not Logged In',
    featureUnavailable: 'Feature is not available on web',
  },
  createCardPage: {
    opened: 'Create New Profile Page Opened',
    saved: 'Create New Profile Page Saved',
  },
  appNudge: {
    bannerDisplayed: 'Banner was displayed',
    bannerDismissed: 'Banner - No thanks selected',
    bannerEngaged: 'Banner - Try it selected',
  },
  groupPage: {
    opened: 'Group Page (Web) Opened',
    signupAndJoin: 'Group Page (Web) Signup and Join Tapped',
    loginAndJoin: 'Group Page (Web) Login and Join Tapped',
  },
  authCallbackPage: {
    navigateTo: 'Auth Callback NavigateTo',
  },
  finaliseUserPage: {
    navigateTo: 'Finalise User Page NavigateTo',
  },
  webReferrals: {
    appNav: 'Referral - AppNav',
    cardReferral: 'Referral - Card',
    groupReferral: 'Referral - Group',
    marketingPageReferral: 'Referral - Marketing Page',
    otherReferral: 'Referral - Other',
  },
  claimAliasNotLoggedIn: {
    opened: 'Claim Alias Opened Not Logged In',
    iosAppClicked: 'Claim Alias IOS Appstore Clicked',
    androidAppClicked: 'Claim Alias Android Playstore Clicked',
    logInClicked: 'Claim Alias Log In Clicked',
    signUpClicked: 'Claim Alias Sign Up Clicked',
  },
  shareContactInfoBack: {
    navigatedToShareContactInfoBack:
      'ShareBack - Navigated to share contact info back',
    submitPressed: 'Share Back - Pressed submit contact info',
    submitSuccess: 'Share Back - Successfully submitted contact info',
    submitFailure: 'Share Back - Failed to Submitted contact info',
  },
  editCardScreen: {
    opened: 'Edit Card Screen Opened',
    cancelled: 'Edit Card Edit Cancelled',
    saved: 'Edit Card Edit Saved',
  },
  localViews: {
    migrating: 'Local Views Migrating',
    synced: 'Local Views Synced',
  },
  cardPageWeb: {
    quickActionsToggled: 'Card Page Quick Actions Toggled',
    downloadButtonWasVisible: 'Download Contact Button Was Visible',
  },
  ownCardRowActions: {
    view: 'Own Card Row Action View (Web)',
    edit: 'Own Card Row Action Edit (Web)',
    share: 'Own Card Row Action Share (Web)',
  },
};

export const analyticsEvents = {
  ...commonAnalyticsEvents,
  ...webOnlyAnalyticsEvents,
};

function checkIfEventExists(event: string) {
  if (process.env.NODE_ENV === 'production') {
    return;
  }
  const eventFound = Object.values(analyticsEvents)
    .map(Object.values)
    .reduce((acc, valueArray) => [...acc, ...valueArray], [])
    .find(existingEventName => event === existingEventName);

  if (!eventFound) {
    console.error(
      `Warning: Analytics event "${event}" does not exist in analyticsEvents dictionary`
    );
  }
}

/**
 * To ensure this lib is more reliable when users use privacy addons we wrap it in our own
 * wrapper to be able to call the provided callback function if any.
 */
interface AnalyticsT {
  reset: AnalyticsJS['reset'];
  identify: AnalyticsJS['identify'];
  track: AnalyticsJS['track'];
  page: AnalyticsJS['page'];
}
export class Analytics implements AnalyticsT {
  private enabled: boolean;
  private apiKey: string;
  static events = analyticsEvents;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
    this.enabled = !!apiKey;
  }

  load() {
    const _load = idx(window, _ => _.analytics.load);
    if (!this.enabled || !this.apiKey || typeof _load !== 'function') {
      return;
    }
    return _load(this.apiKey);
  }

  reset() {
    const analyticsReset = idx(window, _ => _.analytics.reset) || noop;
    if (this.enabled && typeof analyticsReset === 'function') {
      analyticsReset();
    }
  }

  identify(
    userId?: string | object | VoidFn,
    traits?: SegmentOpts | object | VoidFn,
    options?: SegmentOpts | VoidFn,
    callback?: VoidFn
  ) {
    const analyticsIdentify = idx(window, _ => _.analytics.identify);
    if (!this.enabled || typeof analyticsIdentify !== 'function') {
      return findAndCallCallback.apply(null, arguments);
    }
    return analyticsIdentify.apply(null, arguments);
  }

  track(
    event: string,
    properties?: object | VoidFn,
    options?: SegmentOpts | VoidFn,
    callback?: VoidFn
  ) {
    checkIfEventExists(event);
    const analyticsTrack = idx(window, _ => _.analytics.track);
    if (!this.enabled || typeof analyticsTrack !== 'function') {
      return findAndCallCallback.apply(null, arguments);
    }
    return analyticsTrack.apply(null, arguments);
  }

  page(
    category?: string | object | VoidFn,
    name?: string | object | VoidFn | SegmentOpts,
    properties?: object | SegmentOpts | VoidFn,
    options?: SegmentOpts | VoidFn,
    callback?: VoidFn
  ) {
    const analyticsPage = idx(window, _ => _.analytics.page);
    if (!this.enabled || typeof analyticsPage !== 'function') {
      return findAndCallCallback.apply(null, arguments);
    }
    return analyticsPage.apply(null, arguments);
  }
}

const segmentWriteKey = process.env.REACT_APP_SEGMENT_ID || '';
const analytics = new Analytics(segmentWriteKey);
export default analytics;
