import * as React from 'react';

/**
 * Component for Tachyons Aspect Ratios
 * https://github.com/tachyons-css/tachyons-aspect-ratios#the-css
 */
type HTMLDivPropsT = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
type DivRef = React.Ref<HTMLDivElement>;

interface AspectRatioProps extends HTMLDivPropsT {
  className?: string;
  children?: React.ReactNode;
  ratio: '1x1' | '4x3' | '17x20' | '16x9' | '9x10' | '6x4';
}

const AspectRatio = React.forwardRef(function AspectRatio(
  props: AspectRatioProps,
  ref?: DivRef
) {
  const { className = '', children, ratio, ...divProps } = props;
  return (
    <div ref={ref} className={`aspect-ratio aspect-ratio--${ratio}`}>
      <div {...divProps} className={`aspect-ratio--object ${className}`}>
        {children}
      </div>
    </div>
  );
});

AspectRatio.displayName = 'AspectRatio';

export default AspectRatio;
