import * as React from 'react';
import { RouteComponentProps, navigate, Link } from '@reach/router';
import queryString from 'query-string';

import Footer from '../../app/components/Footer';
import QrIcon from '../../../components/icons/QrIcon';
import Loader from '../../../components/Loader';
import PageContainer from '../../../containers/PageContainer';
import { CardRowWithButton } from '../../viewer/components/CardRowWithButton';
import ViewerQuery from '../../../queries/ViewerQuery';
import { analyticsEvents } from '../../../utils/analytics';
import { captureException } from '../../../utils/raven';
import {
  ScanCardsMutationFnT,
  ScanCardsMutation,
} from '../../../queries/ScanCardsMutation';
import { NavComponents } from '../../Navigation/HeaderNavigationComponents';

import { AuthContext } from '../../core/providers/AuthProvider';

const MinglQRReader = React.lazy(() => import('../../ui/MinglQRReader'));
const backgroundPattern = `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2327657c' fill-opacity='0.14' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E")`;

export default class ScanQRoute extends React.Component<RouteComponentProps> {
  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  render(): React.ReactNode {
    return (
      <ScanCardsMutation
        children={scanCardsMutation => (
          <ScanQrRouteContent scanCardsMutation={scanCardsMutation} />
        )}
      />
    );
  }
}

function ScanQrRouteContent(props: {
  scanCardsMutation: ScanCardsMutationFnT;
}) {
  const { isAuthenticated = false } = React.useContext(AuthContext);

  const navigateToMatch = (match: string, page?: number) => {
    const search = queryString.stringify({ id: match, page });
    navigate(`/qr/match?${search}`);
  };

  const onQrMatch = async (match: string) => {
    analytics.track(analyticsEvents.minglMode.scanned, {
      match,
      screen: 'mingl_mode',
      trigger: 'qr_scanner',
    });

    if (isAuthenticated) {
      try {
        props.scanCardsMutation({
          variables: { input: { scans: [{ cardId: match }] } },
        });
      } catch (error) {
        if (
          error instanceof Error &&
          error.message.toLowerCase().indexOf('no valid id') === -1
        ) {
          captureException(error, {
            extra: {
              screen: 'Home',
              event: 'onQrMatch',
            },
          });
        }
      }
    }

    navigateToMatch(match);
  };

  return (
    <PageContainer
      redirectUnauthenticated={false}
      mobileNavItem="/home"
      mobileTitle={<NavComponents.Title children="Scan QR" />}
      mobileActionItems={<NavComponents.GetTheApp />}
    >
      <div
        className="bg-mingl-blue pb3 ph3 flex-initial"
        style={{
          minHeight: '90vh',
          backgroundColor: '#00384d',
          backgroundImage: backgroundPattern,
        }}
      >
        <div style={{ maxWidth: '340px' }} className="w-100 relative center">
          <div className="mv4">
            <React.Suspense fallback={<Loader />}>
              <MinglQRReader
                onQrMatch={onQrMatch}
                containerStyle={{
                  border: '5px solid white',
                  borderRadius: '3px',
                }}
              />
            </React.Suspense>
          </div>

          {isAuthenticated && (
            <ViewerQuery
              render={({ viewer }) => {
                if (!viewer) {
                  return null;
                }

                return (
                  <div>
                    <h3 className="f5 fw4 lh-title white mt0 mb1">
                      Display your own QR:
                    </h3>

                    {viewer.cards.map(card => (
                      <CardRowWithButton
                        key={card.id}
                        card={card}
                        onClick={() => navigateToMatch(card.id, 1)}
                        onButtonClick={() => navigateToMatch(card.id, 1)}
                        buttonBg="bg-mingl-green"
                        buttonContent={
                          <QrIcon height={20} width={20} color="#fff" />
                        }
                      />
                    ))}
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>

      <Footer />
    </PageContainer>
  );
}
