import { QueryHookOptions, useQuery } from 'react-apollo';
import { getOperationName } from 'apollo-utilities';
import {
  ContactsQuery,
  ContactsQueryVariables,
} from '../__generated/apollogen-types';
import CONTACTS_QUERY from './ContactsQuery.graphql';

type DataT = ContactsQuery;
type VariablesT = ContactsQueryVariables;

export const useContactsQuery = (
  maybeVariables?: VariablesT,
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  let variables = maybeVariables;
  if (!variables) {
    variables = {
      input: {},
    };
  }
  return useQuery(CONTACTS_QUERY, {
    ...options,
    variables,
  });
};

export const getContactsQueryName = () =>
  getOperationName(CONTACTS_QUERY) || '';
