import urlParse from 'url-parse';
import * as React from 'react';
import { Typography, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import without from 'lodash-es/without';

import { colors } from '../../ui/theme';
import { useSnackbar } from '../../ui/Snackbar';
import {
  GroupResource,
  UpdateResourceInput,
  RequestStatusEnum,
} from '../../../__generated/apollogen-types';
import { useHandledRemoveGroupResourcesMutation } from '../../../queries/RemoveGroupResourcesMutation';
import EditResourceForm from './EditResourceForm';
import PencilIcon from '../../../components/icons/PencilIcon';
import LightbulbIcon from '../../../components/icons/LightbulbIcon';
import AlertDialog from '../../../components/AlertDialog';
import ResourceAvatar from './ResourceAvatar';
import { useHandledUpdateGroupResourceMutation } from '../../../queries/UpdateGroupResourceMutation';
import { useHandledReorderGroupResourcesMutation } from '../../../queries/ReorderGroupResourcesMutation';
import { getGroupResourcesQueryName } from '../../../queries/GroupResourcesQuery';
import { getAddedAndRemovedFromArray } from '../../../utils/getAddedAndRemovedFromArray';

// ============================================================================
// EditResource

interface EditResourceProps {
  groupId: string;
  resource: GroupResource;
  onRemoveResource: (resourceId: string) => void;
}

function EditableResource(props: EditResourceProps) {
  const [editResourceForm, setEditResource] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const { groupId } = props;
  const { setSnackbar } = useSnackbar();
  const handledUpdateGroupResource = useHandledUpdateGroupResourceMutation();

  const updateGroupResource = React.useCallback(
    async (updatedResource: UpdateResourceInput) => {
      const { message, status } = await handledUpdateGroupResource({
        variables: { input: { groupId, resource: updatedResource } },
        refetchQueries: () => [getGroupResourcesQueryName()],
      });
      setSnackbar(message, status);
      if (status === RequestStatusEnum.OK) {
        setEditResource(false);
      }
    },
    [handledUpdateGroupResource, setSnackbar, groupId]
  );

  return editResourceForm ? (
    <div className="mv3">
      <EditResourceForm
        resource={props.resource}
        onSubmit={updateGroupResource}
        onCancel={() => setEditResource(false)}
        onDelete={() => setShowAlert(true)}
      />
      <AlertDialog
        open={showAlert}
        handleClose={() => setShowAlert(false)}
        title="Delete this resource?"
        handleAction={() => props.onRemoveResource(props.resource.id)}
      />
    </div>
  ) : (
    <div className="flex items-center mb2">
      <DisplayResource resource={props.resource} />
      <div className="ml2 pointer" onClick={() => setEditResource(true)}>
        <PencilIcon size={24} color={'white'} />
      </div>
    </div>
  );
}

// ============================================================================
// DisplayResource

const RESOURCE_AVATAR_SIZE = '60px';

function DisplayResource({ resource }: { resource: GroupResource }) {
  const subtitle = React.useMemo(() => {
    const { hostname } = urlParse(resource.href, {});
    return hostname;
  }, [resource]);

  return (
    <Paper
      className="flex flex-auto overflow-hidden"
      style={{ backgroundColor: colors.light }}
    >
      <div
        className="flex-initial"
        style={{ width: RESOURCE_AVATAR_SIZE, height: RESOURCE_AVATAR_SIZE }}
      >
        <ResourceAvatar imgUrl={resource.icon} />
      </div>
      <div className="pv1 ph2 w-100 flex flex-column justify-center black truncate">
        <Typography
          variant="subtitle1"
          color="inherit"
          children={resource.label}
          className="truncate"
        />
        <Typography
          variant="subtitle2"
          color="inherit"
          style={{ color: colors.gray600 }}
          children={subtitle}
        />
      </div>
    </Paper>
  );
}

const EditModeTooltip = () => (
  <div className="flex justify-center mv3">
    <div className="flex br2 bg-mingl-green100 pa2 items-center">
      <LightbulbIcon size={24} color={colors.green900} />
      <Typography variant="body1" className="pl2 mingl-green-900">
        Drag the links up or down to change their order.
      </Typography>
    </div>
  </div>
);

// ============================================================================
// DraggableResources

interface DragableAreaProps {
  children: React.ReactNode;
  onDragEnd: (result: DropResult) => void;
}
const DragableArea = (props: DragableAreaProps) => {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId={'droppableResources'}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

interface DraggableItemT {
  index: number;
  children: React.ReactNode;
  id: string;
}
const DraggableItem = (props: DraggableItemT) => (
  <Draggable draggableId={props.id} index={props.index}>
    {provided => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {props.children}
      </div>
    )}
  </Draggable>
);

// ============================================================================
// DisplayAndManageResource
export type DisplayAndManageModesT = 'display' | 'edit' | 'create';

interface DisplayAndManageProps {
  groupId: string;
  mode: DisplayAndManageModesT;
  resources: GroupResource[];
  refetchResources: () => void;
  minimizedView?: boolean;
  setResourceMode: (mode: DisplayAndManageModesT) => void;
  setMinimizedView: (minimized: boolean) => void;
  viewerCanEditGroupInfo: boolean;
}

export default function DisplayAndManageGroupResources({
  mode,
  groupId,
  resources,
  refetchResources,
  minimizedView,
  viewerCanEditGroupInfo,
  setMinimizedView,
  setResourceMode,
}: DisplayAndManageProps) {
  const { setSnackbar } = useSnackbar();
  const handledRemoveGroupResources = useHandledRemoveGroupResourcesMutation();
  const handledReorderGroupResources = useHandledReorderGroupResourcesMutation();
  const [resourceOrder, setResourceOrder] = React.useState(() =>
    resources.map(resource => resource.id)
  );

  React.useEffect(() => {
    const ids = resources.map(resource => resource.id);
    const [added, removed] = getAddedAndRemovedFromArray(resourceOrder, ids);
    const updatedOrder = without(resourceOrder, ...removed);
    if (added.length > 0) {
      setResourceOrder([...updatedOrder, ...added]);
    }
  }, [resources, resourceOrder]);

  const removeGroupResource = React.useCallback(
    async (resourceId: string) => {
      const { status, message } = await handledRemoveGroupResources({
        variables: { input: { groupId, resourceIds: [resourceId] } },
      });
      setSnackbar(message, status);
      refetchResources();
    },
    [handledRemoveGroupResources, groupId, setSnackbar, refetchResources]
  );

  const reoderGroupResources = React.useCallback(async () => {
    const { status, message } = await handledReorderGroupResources({
      variables: { input: { groupId, resourceIds: resourceOrder } },
    });
    setSnackbar(message, status);
    refetchResources();
  }, [
    handledReorderGroupResources,
    groupId,
    resourceOrder,
    setSnackbar,
    refetchResources,
  ]);

  const hasOrderChanges = React.useMemo(() => {
    const maxItems = Math.max(resourceOrder.length, resources.length);
    for (let i = 0; i < maxItems; i++) {
      if (resourceOrder[i] !== resources[i].id) {
        return true;
      }
    }
    return false;
  }, [resources, resourceOrder]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    const dragedItem = resources.find(resouce => resouce.id === draggableId);
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (dragedItem) {
      const reoorderedResouceList = [...resourceOrder];
      reoorderedResouceList.splice(source.index, 1);
      reoorderedResouceList.splice(destination.index, 0, dragedItem.id);
      setResourceOrder(reoorderedResouceList);
    }
  };

  const orderedResources = React.useMemo(() => {
    const resourceDict: Record<string, GroupResource> = {};
    for (const resource of resources) {
      resourceDict[resource.id] = resource;
    }

    const _orderedResources = resourceOrder.map(
      resourceId => resourceDict[resourceId]
    );
    return minimizedView ? _orderedResources.slice(0, 3) : _orderedResources;
  }, [minimizedView, resources, resourceOrder]);

  return (
    <>
      {mode === 'edit' && (
        <>
          <EditModeTooltip />
          <DragableArea onDragEnd={onDragEnd}>
            {orderedResources.map((resource, index) => (
              <DraggableItem key={resource.id} index={index} id={resource.id}>
                <EditableResource
                  groupId={groupId}
                  onRemoveResource={removeGroupResource}
                  resource={resource}
                />
              </DraggableItem>
            ))}
          </DragableArea>
        </>
      )}

      {mode !== 'edit' &&
        orderedResources.map(resource => (
          <a
            key={resource.id}
            href={resource.href}
            rel="noopener noreferrer"
            target="_blank"
            className="db link mb2"
          >
            <DisplayResource resource={resource} />
          </a>
        ))}

      {/* control panel of buttons */}
      <div className="w-100 flex justify-end mt2">
        {mode === 'edit' && (
          <Button
            variant="text"
            color="default"
            onClick={() => {
              if (hasOrderChanges) {
                reoderGroupResources();
              }
              setResourceMode('display');
            }}
            children={hasOrderChanges ? 'SAVE' : 'DONE'}
          />
        )}

        {viewerCanEditGroupInfo && mode === 'display' && (
          <>
            {orderedResources.length > 0 && (
              <Button
                variant="text"
                color="default"
                onClick={() => {
                  setResourceMode('edit');
                  setMinimizedView(false);
                }}
                children={'EDIT'}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              children="ADD LINK"
              onClick={() => setResourceMode('create')}
            />
          </>
        )}
      </div>
    </>
  );
}
