import * as React from 'react';
import idx from '../../utils/idx';
import { Link } from '@reach/router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { useViewerQuery } from '../../queries/ViewerQuery';
import { useAuthContext } from '../core/providers/AuthProvider';
import GroupsIcon from '../../components/icons/GroupsIcon';
import EventsIcon from '../../components/icons/EventsIcon';
import SettingsIcon from '../../components/icons/SettingsIcon';
import AddressBookIcon from '../../components/icons/AddressBookIcon';
import CameraIcon from '../../components/icons/CameraIcon';
import MenuIcon from '../../components/icons/MenuIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import { NavComponents } from './HeaderNavigationComponents';
import { UserAvatar } from '../ui/UserAvatar';

import { ViewerFragment } from '../../__generated/apollogen-types';
import { DrawerNavContext, DrawerNavContextT } from './DrawerNavigation';
import { HeaderNavItem } from './HeaderNavItem';
import { NavSearch } from './HeaderNavSearch';
import { colors } from '../ui/theme';
import { useGroupListQuery } from '../../queries/GroupListQuery';
import appConfig from '../../config';

const useStyles = makeStyles({
  minglAppBar: {
    backgroundColor: colors.blue700,
  },
  toolbar: {
    height: '1px', // only min-height doesn't work properly
  },
});

/**
 * MinglToolbar
 * A customized toolbar that adds `height: 1px` because otherwise the
 * navigation items doesn't stretch to the height of the header.
 */
export const MinglToolbar = (props: {
  children: JSX.Element | React.ReactNode;
  className?: string;
}) => {
  const styles = useStyles();

  return (
    <Toolbar className={`${styles.toolbar} ${props.className || ''}`}>
      {props.children}
    </Toolbar>
  );
};

interface NavContentPropsT extends NavbarPropsT, DrawerNavContextT {
  isAuthenticated: boolean;
  viewer?: ViewerFragment;
}

const FixedAppBar = (props: { children: React.ReactNode }) => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <AppBar position="fixed" color="inherit" className={styles.minglAppBar}>
        <MinglToolbar>{props.children}</MinglToolbar>
      </AppBar>

      {/*
        Note: Fixed appnav is take out of the normal browser layout, because of this
        we add a placeholder Toolbar here as per the recommendations from the docs.
      */}
      <MinglToolbar>{/* placeholder */}</MinglToolbar>
    </React.Fragment>
  );
};

/**
 * Idea:
 * Create common components for the variations of the navigation
 * and use invertion of control to customize the header for the
 * particular screen.
 * Eg.
 * renderNav: (props: NavContentPropsT) => <NavComponents.FinalizeUserHeader {...props} />
 */
export interface NavbarPropsT {
  navigate: (route: string) => void;
  // desktop config props
  leftNav?: JSX.Element | null;
  centerNav?: JSX.Element | null;
  rightNav?: JSX.Element | null;
  // mobile config props
  returnPath?: string;
  mobileNavItem?: JSX.Element | string | null;
  mobileTitle?: JSX.Element | null;
  mobileActionItems?: JSX.Element | null;
  eventCount?: number | 0;
}

function DesktopNavigationHeader(props: NavContentPropsT) {
  const center = props.centerNav || null;
  /**
   * This means:
   * props.left === null => render nothing
   * props.left === undefined => render default
   */
  let left = props.leftNav;
  if (typeof left === 'undefined') {
    left = (
      <>
        <div className="pr3">
          <NavComponents.MinglLogo />
        </div>
        {props.isAuthenticated && (
          <div className="flex-auto mw6 ph3">
            <NavSearch navigate={props.navigate} />
          </div>
        )}
      </>
    );
  }

  let right = props.rightNav;
  if (typeof right === 'undefined') {
    if (!props.isAuthenticated) {
      right = (
        <>
          <Link
            className="mingl-green fw6 ttu link nowrap ba br-pill pv1 ph2 ph3-ns ml1"
            style={{ fontSize: '14px' }}
            to="/register"
            children="Sign up"
          />
          <Link
            className="white link pa1 ml1 nowrap"
            style={{ fontSize: '14px' }}
            to="/login"
            children="Log in"
          />
        </>
      );
    } else {
      const viewerImageUrl = idx(props.viewer, _ => _!.imageURL);
      right = (
        <>
          <HeaderNavItem
            name="Profile"
            type="link"
            linkTo="/me/edit"
            children={<UserAvatar size={22} imageURL={viewerImageUrl} />}
          />

          <HeaderNavItem
            name="Settings"
            type="link"
            linkTo="/settings"
            children={
              <SettingsIcon width={22} height={22} color="currentColor" />
            }
          />

          <HeaderNavItem
            name="Groups"
            type="link"
            linkTo="/groups"
            children={<GroupsIcon size={22} color="currentColor" />}
          />

          {props.eventCount && props.eventCount > 0 ? (
            <HeaderNavItem
              name="Events"
              type="link"
              linkTo="/events"
              children={<EventsIcon size={22} color="currentColor" />}
            />
          ) : null}

          <HeaderNavItem
            name="Contacts"
            type="link"
            linkTo="/home"
            children={<AddressBookIcon size={22} color="currentColor" />}
          />
        </>
      );
    }
  }

  return (
    <FixedAppBar>
      <div className="h-100 flex items-center tl" style={{ flex: '1 9999 0%' }}>
        {left}
      </div>

      <div className="h-100 flex items-center flex-initial">{center}</div>

      <div
        className="h-100 flex items-center flex-row-reverse tr"
        style={{ flex: '1 9999 0%' }}
      >
        {right}
      </div>
    </FixedAppBar>
  );
}

function MobileNavigationHeader(props: NavContentPropsT) {
  const materialTheme = useTheme();
  const mediumScreenSize = useMediaQuery(materialTheme.breakpoints.up('sm'));

  let mobileNavItem = props.mobileNavItem;
  if (typeof mobileNavItem === 'undefined') {
    mobileNavItem = <NavComponents.MobileMenuItem />;
  } else if (typeof mobileNavItem === 'string') {
    mobileNavItem = <NavComponents.Back returnPath={mobileNavItem} />;
  }

  let mobileTitle = props.mobileTitle;
  if (typeof mobileTitle === 'undefined') {
    mobileTitle = <NavComponents.MinglLogo />;
  }

  let mobileActionItems = props.mobileActionItems;
  if (typeof mobileActionItems === 'undefined') {
    mobileActionItems = (
      <>
        <HeaderNavItem
          type="link"
          linkTo="/qr"
          name="QR Reader"
          children={<CameraIcon size={25} color="currentColor" />}
        />
        {props.isAuthenticated && (
          <HeaderNavItem
            type="link"
            linkTo="/search"
            name="Search"
            hide={!mediumScreenSize}
            children={
              <SearchIcon height={25} width={25} color="currentColor" />
            }
          />
        )}
      </>
    );
  }

  return (
    <FixedAppBar>
      <div className="h-100 flex items-center tl" style={{ flex: '1 9999 0%' }}>
        {mobileNavItem}
      </div>

      <div className="h-100 flex items-center flex-initial">{mobileTitle}</div>

      <div
        className="h-100 flex items-center flex-row-reverse tr"
        style={{ flex: '1 9999 0%' }}
      >
        {mobileActionItems}
      </div>
    </FixedAppBar>
  );
}

export function HeaderNavigation(props: NavbarPropsT) {
  const { data } = useViewerQuery();
  const { isAuthenticated } = useAuthContext();
  const appDrawerContext = React.useContext(DrawerNavContext);
  const materialTheme = useTheme();
  const largeScreenSize = useMediaQuery(materialTheme.breakpoints.up('md'));

  const viewer = idx(data, _ => _!.viewer) || undefined;

  if (largeScreenSize) {
    return (
      <DesktopNavigationHeader
        {...props}
        {...appDrawerContext}
        viewer={viewer}
        isAuthenticated={isAuthenticated}
        eventCount={props.eventCount}
      />
    );
  }

  return (
    <MobileNavigationHeader
      {...props}
      {...appDrawerContext}
      viewer={viewer}
      isAuthenticated={isAuthenticated}
      eventCount={props.eventCount}
    />
  );
}

export default HeaderNavigation;
