import * as React from 'react';
import idx from '../utils/idx';
import { MutationOptions } from 'apollo-client';
import { useApolloClient } from 'react-apollo';

import {
  CreateContactInfoMutation,
  CreateContactInfoMutationVariables,
  RequestStatusEnum,
  CreateContactInfoMutationInput,
  CreateContactInfoMutation_createContactInfo,
} from '../__generated/apollogen-types';

import CREATE_CONTACTS_INFO from './CreateContactInfoMutation.graphql';
import analytics, { analyticsEvents } from '../utils/analytics';

export type CreateContactInfoFnT = (
  input: CreateContactInfoMutationInput
) => Promise<CreateContactInfoMutation_createContactInfo>;

type CreateContactOptionsT = Omit<
  MutationOptions<
    CreateContactInfoMutation,
    CreateContactInfoMutationVariables
  >,
  'mutation'
>;

export const useCreateContactInfoMutation = (
  opts?: CreateContactOptionsT
): CreateContactInfoFnT => {
  const client = useApolloClient();

  return input =>
    client
      .mutate<CreateContactInfoMutation, CreateContactInfoMutationVariables>({
        ...opts,
        mutation: CREATE_CONTACTS_INFO,
        variables: { input },
      })
      .then(res => {
        const response = idx(res, _ => _!.data!.createContactInfo);
        const createResponse = response as CreateContactInfoMutation['createContactInfo'];
        if (!createResponse) {
          throw Error('Backend did not respond correctly');
        }

        return createResponse;
      })
      .catch((err: Error) => {
        analytics.track(analyticsEvents.contactInformation.createFailed, {
          err,
          message: err.message,
        });

        return {
          __typename: 'CreateContactInfoMutationOutput' as 'CreateContactInfoMutationOutput',
          status: RequestStatusEnum.ERROR,
          message: 'Failed to create info',
          validation: [],
        };
      });
};
