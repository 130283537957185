import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingPage() {
  return (
    <div className="ma3 tc">
      <CircularProgress />
    </div>
  );
}
