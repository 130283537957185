/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  stroke: string;
  fill: string;
  size: number | string;
}

const StarIcon = ({ stroke, fill, size }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2841 8.27559L15.3333 7.41075L12.6731 2.01778C12.6005 1.87013 12.4809 1.75059 12.3333 1.67794C11.963 1.49513 11.513 1.64747 11.3278 2.01778L8.66766 7.41075L2.71688 8.27559C2.55282 8.29903 2.40281 8.37638 2.28797 8.49356C2.14913 8.63627 2.07262 8.82825 2.07526 9.02733C2.0779 9.22642 2.15946 9.41631 2.30203 9.55528L6.6075 13.7529L5.59031 19.6803C5.56646 19.8182 5.58172 19.96 5.63436 20.0896C5.687 20.2193 5.77491 20.3316 5.88813 20.4138C6.00135 20.496 6.13535 20.5449 6.27492 20.5549C6.4145 20.5648 6.55407 20.5355 6.67781 20.4701L12.0005 17.6717L17.3231 20.4701C17.4684 20.5475 17.6372 20.5732 17.7989 20.5451C18.2067 20.4748 18.4809 20.0881 18.4106 19.6803L17.3934 13.7529L21.6989 9.55528C21.8161 9.44044 21.8934 9.29044 21.9169 9.12638C21.9802 8.71622 21.6942 8.33653 21.2841 8.27559Z"
      fill={fill}
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

export default StarIcon;
