/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  width: number | string;
  height: number | string;
  className?: string;
  style?: React.CSSProperties;
}

const SearchIcon = ({ width, height, color, className, style }: IconProps) => (
  <svg
    viewBox="0 0 18 18"
    width={width}
    height={height}
    className={className}
    style={style}
    role="img"
    aria-labelledby="title"
    name="search"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M12.5 11h-.79l-.28-.27A6.471 6.471 0 0 0 13 6.5 6.5 6.5 0 1 0 6.5 13c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L17.49 16l-4.99-5zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11z"
        fill={color}
      />
      <path d="M-3-3h24v24H-3z" />
    </g>
  </svg>
);

export default SearchIcon;
