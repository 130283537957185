import * as React from 'react';
import QRCode from 'qrcode.react';
import Rect from '@reach/rect';
import { Typography, Button } from '@material-ui/core';

import '../../card/containers/SelectorModal.css';

import MinglModal from '../../card/containers/MinglModal';
import { makeDynamicLink } from '../../../utils/minglUrlUtil';
import { CircularButton } from '../CircularButton';
import ChatIcon from '../../../components/icons/ChatIcon';
import { userDevice } from '../../../utils/userDevice';
import { colors } from '../theme';

export const ChatPopup = (props: { show: boolean; close: () => void }) => {
  return (
    <MinglModal
      className={{
        base: 'selector-modal appear',
        afterOpen: 'appear-enter',
        beforeClose: 'appear-exit',
      }}
      overlayClassName={{
        base: `selector-background`,
        afterOpen: 'fade-in',
        beforeClose: 'fade-out',
      }}
      style={{ overlay: { zIndex: 1050 } }}
      isOpen={props.show}
      onRequestClose={props.close}
      children={modalProps => {
        const dynamicLink = makeDynamicLink({
          medium: 'chatButton',
          source: 'chatButton',
          dynamicLinkDomain: 'mingl.page.link',
          link: `https://mingl.no${window.location.pathname}`,
        });

        return (
          <div
            style={{ wordBreak: 'break-word', maxWidth: 600 }}
            className={'bg-white br3 pa3 ma3'}
          >
            <Typography variant="h4" className="fw6">
              Chat in the app
            </Typography>

            <Rect
              children={({ ref, rect }) => (
                <div className="ph2 ph3-ns" ref={ref}>
                  <Typography variant="subtitle1">
                    Chat features cannot be accessed on the website, please use
                    our app to start chatting with people
                  </Typography>

                  {userDevice.hasAppStore ? (
                    <div className="flex items-center justify-center mt3">
                      <div className="w-40-ns">
                        <Button
                          color="primary"
                          variant="contained"
                          children="Get the app here"
                          onClick={async () => {
                            window.open(dynamicLink, '_blank');
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mt3">
                        <Typography variant="h6" className="">
                          Scan this QR with your mobile device to get the app:
                        </Typography>
                      </div>

                      <div className="tc mv3">
                        <div className="dib" style={{ maxWidth: 400 }}>
                          <QRCode
                            value={dynamicLink}
                            size={rect ? Math.min(rect.width * 0.5, 320) : 320}
                            level={'L'}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            />
          </div>
        );
      }}
    />
  );
};

export const ChatPopupButton = (props: { openChatPopup: () => void }) => (
  <CircularButton
    style={{ backgroundColor: colors.gray50 }}
    className="bg-mingl-green pointer bn"
    onClick={props.openChatPopup}
    size={40}
    children={
      <div className="flex justify-center align-center">
        <ChatIcon color={colors.gray700} size={22} />
      </div>
    }
  />
);
