import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
  MutationHookOptions,
  useMutation,
} from 'react-apollo';

import {
  ViewCards as ViewCardsMutationT,
  ViewCardsVariables as ViewCardsMutationVariables,
} from '../__generated/apollogen-types';
import VIEW_CARDS_MUTATION from './ViewCardsMutation.graphql';

type DataT = ViewCardsMutationT;
type VariablesT = ViewCardsMutationVariables;

type ViewCardsMutationOpts = MutationComponentOptions<DataT, VariablesT>;
export type ViewCardsMutationFnT = MutationFunction<DataT, VariablesT>;

export function ViewCardsMutation(props: {
  children: ViewCardsMutationOpts['children'];
}) {
  return (
    <Mutation<DataT, VariablesT> {...props} mutation={VIEW_CARDS_MUTATION} />
  );
}

export const useViewCardsMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(VIEW_CARDS_MUTATION, options);
};
