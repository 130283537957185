import * as React from 'react';
import Button from './Button';

interface LinkButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  fontSize?: number;
}

export default function LinkButton({
  fontSize = 6,
  className = '',
  ...props
}: LinkButtonProps): JSX.Element | null {
  const buttonClasses = `f${fontSize} w-auto pa0 pointer bn bg-transparent ${className}`;

  return <Button {...props} className={buttonClasses} />;
}
