import * as React from 'react';
import idx from '../utils/idx';
import {
  Query,
  QueryComponentOptions,
  QueryResult,
  QueryHookOptions,
  useQuery,
} from 'react-apollo';

import { QueryComponentInputProps } from './QueryComponentTypes';
import {
  GetNode as GetNodeQueryT,
  GetNodeVariables as GetNodeQueryVariablesT,
  GetNode_node_Card,
  GetNode_node_User,
  GetNode_node_Company,
} from '../__generated/apollogen-types';
import NODE_QUERY from './GetNodeQuery.graphql';

import LoadingPage from '../components/LoadingPage';
import ErrorMessage from '../components/ErrorMessage';

type DataT = GetNodeQueryT;
type VariablesT = GetNodeQueryVariablesT;

type PropsT = Omit<QueryComponentOptions<DataT, VariablesT>, 'query'>;

export type NodeQueryT = GetNodeQueryT;
export type NodeQueryVariablesT = GetNodeQueryVariablesT;
export type CardNodeT = GetNode_node_Card;
export type UserNodeT = GetNode_node_User;
export type CompanyNodeT = GetNode_node_Company;
export type NodeQueryResultT = QueryResult<DataT, VariablesT>;

export class NodeQuery extends React.Component<PropsT> {
  public static query = NODE_QUERY;
  public static getQueryName = () => 'GetNode';

  render() {
    return (
      <Query<DataT, VariablesT>
        {...this.props}
        query={NODE_QUERY}
        children={this.props.children}
      />
    );
  }
}

export const useNodeQuery = (options: QueryHookOptions<DataT, VariablesT>) => {
  return useQuery<DataT, VariablesT>(NODE_QUERY, options);
};

export default function NodeQueryForRoute({
  id,
  render,
  renderLoading = LoadingPage,
  renderError = ErrorMessage,
}: QueryComponentInputProps<DataT, VariablesT>) {
  const variables: VariablesT = { id };
  const ErrorComponent = renderError;

  return (
    <Query<DataT, VariablesT>
      query={NODE_QUERY}
      variables={variables}
      children={apollo => {
        const { data, loading, error, refetch } = apollo;
        if (error && !loading) {
          const message =
            idx(error, _error => _error!.graphQLErrors![0].message) || '';

          return (
            <ErrorComponent
              error={error}
              extra={{ component: 'NodeQueryForRoute' }}
              message={error.message || message}
              retry={
                Boolean(error.networkError)
                  ? () => refetch(variables)
                  : undefined
              }
            />
          );
        }
        if (loading && (!data || !data.node)) {
          return renderLoading();
        }
        return render(data || {}, apollo);
      }}
    />
  );
}
