import * as React from 'react';

import { CardForm, CardFormProps as CardFormPropsT } from './CardForm';
import * as api from '../../../api';
import { useUpdateCardMutation } from '../../../queries/CardMutation';
import FormInput from '../../ui/form/FormInput';
import { UpdateCardInput } from '../../../__generated/apollogen-types';
import FormTextArea from '../../ui/form/FormTextArea';
import PencilIcon from '../../../components/icons/PencilIcon';
import CircularButton from '../../ui/CircularButton';
import Button from '../../ui/Button';
import { CardImageField } from './CardFormComponents';

interface PropsT {
  viewer: CardFormPropsT['viewer'];
  card: CardFormPropsT['card'];
  onDone: CardFormPropsT['onDone'];
  onCancel: CardFormPropsT['onCancel'];
}

export type FormValues = UpdateCardInput & {
  acceptsTerms?: boolean;
  imageTransform?: {
    x?: string;
    y?: string;
    scale?: string;
  };
};

export default function ConnectedCardForm(props: PropsT) {
  const [updateCardMutation] = useUpdateCardMutation();
  const [editName, setEditName] = React.useState(false);

  return (
    <CardForm
      {...props}
      mutateCard={updateCardMutation}
      onImageDrop={api.uploadImage}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        onImageDrop,
        handleSubmit,
        handleChange,
        isSubmitting,
        setFieldValue,
      }) => (
        <React.Fragment>
          <div className="card-container bg-white">
            <form onSubmit={handleSubmit} className="fw2 black">
              <CardImageField
                imageURL={values.imageURL}
                onPositionChange={position =>
                  setFieldValue('imageTransform', {
                    ...(values.imageTransform || {}),
                    ...position,
                  })
                }
                onImageChange={(file, onProgress) =>
                  onImageDrop(file, onProgress).then(image => {
                    setFieldValue('imageURL', image);
                    return image;
                  })
                }
              />

              <div className="ph3 pt2">
                {!editName && (
                  <div className="flex justify-between items-baseline mv-2">
                    <h1 className="f3 fw3 lh-title mt0 mb2 mid-gray">
                      {props.card.title}
                    </h1>
                    <CircularButton
                      className="bg-mingl-green pointer bn"
                      onClick={() => setEditName(true)}
                      size={24}
                      children={<PencilIcon color="white" size={12} />}
                    />
                  </div>
                )}
                {editName && (
                  <React.Fragment>
                    <div className="mt2 mb3">
                      <FormInput
                        name="firstName"
                        label="First name"
                        labelStyle={{ fontSize: '0.875rem', fontWeight: 600 }}
                        color="black"
                        layout="column"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName || ''}
                        error={errors.firstName}
                        touched={touched.firstName}
                      />
                    </div>
                    <div className="mb3">
                      <FormInput
                        name="lastName"
                        label="Last name"
                        labelStyle={{ fontSize: '0.875rem', fontWeight: 600 }}
                        color="black"
                        layout="column"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName || ''}
                        error={errors.lastName}
                        touched={touched.lastName}
                      />
                    </div>
                  </React.Fragment>
                )}
                <div className="mt2 mb3">
                  <FormInput
                    name="workTitle"
                    label="Work title"
                    labelStyle={{ fontSize: '0.875rem', fontWeight: 600 }}
                    color="black"
                    layout="column"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.workTitle || ''}
                    error={errors.workTitle}
                    touched={touched.workTitle}
                  />
                </div>

                <h3 className="lh-title fw5 mv2">Bio</h3>
                <div className="mb4">
                  <FormTextArea
                    name="biography"
                    color="black"
                    suggestedLength={250}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.biography || ''}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      border: 'none',
                      borderBottom: '1px solid black',
                      paddingBottom: 0,
                      margin: 0,
                      width: '100%',
                    }}
                  />
                </div>

                <div className="flex justify-between pv3">
                  {props.onCancel && (
                    <Button
                      className="bg-mingl-light-blue br-pill w-40 ttu pv2 ph1 f7 fw5 tc white pointer bn"
                      onClick={props.onCancel}
                      children="Cancel"
                    />
                  )}
                  <Button
                    type="submit"
                    className="bg-mingl-green br-pill w-40 ttu pv2 ph1 f7 fw5 tc white pointer bn"
                    children="Save"
                  />
                </div>
              </div>
            </form>
          </div>
        </React.Fragment>
      )}
    </CardForm>
  );
}
