import * as React from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import PageContainer from '../../../containers/PageContainer';

import {
  AuthenticatedBorder,
  UnauthenticatedBorder,
} from '../../core/providers/AuthProvider';
import Loader from '../../../components/Loader';

const LazyMarketingPage = React.lazy(() =>
  import('../components/MarketingPage')
);

function MarketingPageRoute(props: RouteComponentProps) {
  return (
    <PageContainer redirectUnauthenticated={false}>
      <AuthenticatedBorder>
        <Redirect noThrow={true} to="/home" />
      </AuthenticatedBorder>

      <UnauthenticatedBorder>
        <React.Suspense fallback={<Loader />}>
          <LazyMarketingPage />
        </React.Suspense>
      </UnauthenticatedBorder>
    </PageContainer>
  );
}

export default MarketingPageRoute;
