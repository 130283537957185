/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
  className?: string;
  style?: React.CSSProperties;
}

const EventsIcon = ({ size, color, className, style }: IconProps) => (
  <svg
    width={size}
    height={size}
    className={className}
    style={style}
    fill="none"
    viewBox="0 0 16 18"
  >
    <path
      d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"
      fill={color}
    />
    <path
      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
      fill={color}
    />
  </svg>
);

export default EventsIcon;
