import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';

import {
  RemoveGroupEnquiry as RemoveGroupEnquiryMutation,
  RemoveGroupEnquiryVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import REMOVE_ENQUIRY_RESOURCES from './RemoveGroupEnquiryMutation.graphql';
import { useCallback } from 'react';
import idx from '../utils/idx';

type DataT = RemoveGroupEnquiryMutation;
type VariablesT = RemoveGroupEnquiryVariables;

export type RemoveGroupEnquiryMutationFnT = MutationFunction<DataT, VariablesT>;

export const useRemoveGroupEnquiryMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(REMOVE_ENQUIRY_RESOURCES, {
    ...options,
  });
};

// ============================================================================
// Mutation handler

type MutationOutputT = DataT['removeGroupEnquiry'];

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledRemoveGroupEnquiryMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [RemoveGroupEnquiry] = useRemoveGroupEnquiryMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'RemoveGroupEnquiryMutationOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
      };

      try {
        const res = await RemoveGroupEnquiry(options);
        return idx(res, _ => _!.data!.removeGroupEnquiry) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [RemoveGroupEnquiry]
  );

  return handledMutationFn;
};
