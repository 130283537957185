import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';

import idx from '../utils/idx';
import {
  DeleteItem as DeleteItemMutationT,
  DeleteItemVariables as DeleteItemMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import { DataProxy } from 'apollo-cache';
import { NodeQueryT, NodeQueryVariablesT } from './GetNode';
import DELETE_ITEM_MUTATION from './DeleteItemMutation.graphql';
import NODE_QUERY from './GetNodeQuery.graphql';

type DataT = DeleteItemMutationT;
type VariablesT = DeleteItemMutationVariables;

type DeleteItemMutationOpts = MutationComponentOptions<DataT, VariablesT>;
export type DeleteItemMutationFnT = MutationFunction<DataT, VariablesT>;

export function DeleteItemMutation(props: {
  children: DeleteItemMutationOpts['children'];
}) {
  return (
    <Mutation<DataT, VariablesT>
      {...props}
      mutation={DELETE_ITEM_MUTATION}
      update={updateCache}
    />
  );
}

const removeCompanyFromNodeQueryCache = (
  cache: DataProxy,
  deletedCompanyId: string
) => {
  let readResult: NodeQueryT | null;
  try {
    readResult = cache.readQuery<NodeQueryT, NodeQueryVariablesT>({
      query: NODE_QUERY,
      variables: { id: deletedCompanyId },
    });
  } catch (error) {
    return;
  }

  if (
    !readResult ||
    !readResult.node ||
    readResult.node.__typename !== 'Company'
  ) {
    return;
  }

  cache.writeQuery<NodeQueryT, NodeQueryVariablesT>({
    query: NODE_QUERY,
    variables: { id: deletedCompanyId },
    data: {
      ...readResult,
      node: null,
    },
  });
};

const updateCache: DeleteItemMutationOpts['update'] = (cache, result) => {
  const response = idx(result, _ => _.data!.deleteItem);
  if (!response) {
    return;
  }

  if (response.deletedId && response.deletedType === 'COMPANY') {
    removeCompanyFromNodeQueryCache(cache, response.deletedId);
  }
};

export const useDeleteItemMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(DELETE_ITEM_MUTATION, { update: updateCache, ...options });
};

// ============================================================================
// Mutation handler

type MutationOutputT = NonNullable<DataT['deleteItem']>;

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledDeleteItemMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [deleteItem] = useDeleteItemMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = React.useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'DeleteItemOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
        deletedId: null,
        deletedType: null,
        groupId: idx(options, _ => _.variables!.input.groupId) || null,
      };

      try {
        const res = await deleteItem(options);
        return idx(res, _ => _!.data!.deleteItem) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [deleteItem]
  );

  return handledMutationFn;
};
