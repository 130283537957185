import * as React from 'react';

interface FormErrorProps {
  error?: React.ReactNode;
  hideError?: boolean;
}

export function FormError({ error, hideError = false }: FormErrorProps) {
  if (hideError || !error) {
    return null;
  }

  return <div className="f6 fw5 mingl-light-red mb2">{error}</div>;
}
