import * as React from 'react';
import idx from '../utils/idx';
import { RavenCaptureOptions, captureException } from '../utils/raven';
import ButtonContainer from '../modules/ui/ButtonContainer';

function generateEmailSubjectAndBody() {
  const subject = encodeURIComponent(`Error in ${window.location.hostname}`);
  const body = encodeURIComponent(
    `Hi,\nI'm having problems accessing the following link:\n${window.location}`
  );
  return `?subject=${subject}&body=${body}`;
}

type RavenExtra = RavenCaptureOptions['extra'];
export interface ErrorMessageProps {
  error: Error;
  extra?: RavenExtra;
  message?: string;
  retry?: () => void;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  React.useEffect(() => {
    if (props.error instanceof Error) {
      const extra = props.extra || {};
      captureException(props.error, {
        extra: { location: window.location.href, ...extra },
      });
    }
  }, [props.error]);

  const { retry, message = idx(props.error, _ => _.message) || '' } = props;

  return (
    <div className="cf ph3">
      <div className="measure center">
        <h2 className="f3 lh-title red mb0">Error:</h2>
        <p className="f5 lh-copy red mt2">{message}</p>

        {retry && (
          <React.Fragment>
            <h3 className="f4 lh-title mb2">Please try again</h3>
            <div className="w-100 w-40-ns">
              <ButtonContainer
                mood="positive"
                children="Retry"
                onClick={retry}
              />
            </div>

            <p className="f5 lh-copy mt2">
              If the issue persists feel free to contact us at{' '}
              <a
                className="link"
                href={`mailto:sindre@mingl.no${generateEmailSubjectAndBody()}`}
                children="sindre@mingl.no"
              />
              &nbsp;with the link to the page you are trying to access.
            </p>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
