import { useQuery, QueryHookOptions } from 'react-apollo';
import { getOperationName } from 'apollo-utilities';

import {
  GroupEnquiries,
  GroupEnquiriesVariables,
} from '../__generated/apollogen-types';
import GROUP_ENQUIRIES from './GroupEnquiriesQuery.graphql';

type DataT = GroupEnquiries;
type VariablesT = GroupEnquiriesVariables;

export const useGroupEnquiriesQuery = (
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery<DataT>(GROUP_ENQUIRIES, {
    ...options,
  });
};

export const getGroupEnquiriesQueryName = () =>
  getOperationName(GROUP_ENQUIRIES) || '';
