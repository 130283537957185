/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const PencilIcon = ({ color, size }: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} name="pencil">
    <g fill="none" fillRule="evenodd">
      <path
        d="M18.311 2.828l2.862 2.861L6.14 20.721l-3.583.722.723-3.584L18.311 2.828zm0-2.828L1.438 16.872 0 23.999l7.127-1.437L24.001 5.689 18.311 0z"
        fill={color}
      />
    </g>
  </svg>
);

export default PencilIcon;
