/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  width: number | string;
  height: number | string;
  className?: string;
}

const QrIcon = ({ width, height, color, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    role="img"
    aria-labelledby="title"
    viewBox="0 0 66 65"
    name="qr"
  >
    <title>Q.R code icon</title>
    <g fill="none" fillRule="evenodd">
      <path
        strokeWidth={3}
        stroke={color}
        d="M2.5 2.5h20v20h-20zM2.5 43.5h20v20h-20zM43.5 2.5h20v20h-20z"
      />
      <path
        fillRule="nonzero"
        fill={color}
        d="M7.5 7.5h10v10h-10zM48.5 7.5h10v10h-10zM7.5 48.5h10v10h-10zM.5 25.5h5v5h-5zM5.5 30.5h5v5h-5zM10.5 25.5h5v5h-5zM15.5 35.5h5v5h-5zM20.5 30.5h5v5h-5zM25.5 25.5h5v5h-5zM30.5 20.5h5v5h-5zM25.5 15.5h5v5h-5zM30.5 10.5h5v5h-5zM35.5 5.5h5v5h-5zM30.5.5h5v5h-5zM25.5 5.5h5v5h-5zM55.5 25.5h5v5h-5zM45.5 25.5h5v5h-5zM35.5 30.5h5v5h-5zM50.5 30.5h5v5h-5zM40.5 35.5h5v5h-5zM30.5 35.5h5v5h-5zM35.5 45.5h5v5h-5zM35.5 55.5h5v5h-5zM30.5 50.5h5v5h-5zM45.5 45.5h5v5h-5zM50.5 50.5h5v5h-5zM45.5 55.5h5v5h-5zM60.5 45.5h5v5h-5zM60.5 55.5h5v5h-5zM55.5 40.5h5v5h-5z"
      />
    </g>
  </svg>
);

export default QrIcon;
