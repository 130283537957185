import * as React from 'react';

import { CardNodeT } from '../../../queries/GetNode';
import { CircularButton } from '../../ui/CircularButton';
import StarIcon from '../../../components/icons/StarIcon';
import { getContactsQueryName } from '../../../queries/ContactsQuery';
import {
  GroupItemsQuery_groupItems_edges_node_card,
  RequestStatusEnum,
} from '../../../__generated/apollogen-types';
import { useAddContactsMutation } from '../../../queries/AddContactsMutation';
import { useRemoveContactsMutation } from '../../../queries/RemoveContactsMutation';
import { useSnackbar } from '../../ui/Snackbar';
import Spinner from '../../../components/icons/Spinner';

import { colors } from '../../../modules/ui/theme';
import AlertDialog from '../../../components/AlertDialog';
import idx from '../../../utils/idx';

export interface CardRowStyles {
  container: React.CSSProperties;
  image: React.CSSProperties;
  content: React.CSSProperties;
}

export interface CardRowProps {
  card: CardNodeT;
  onClick?: (cardId: string) => void;
  styles?: Partial<CardRowStyles>;
}

export const StarCardRowButton = (props: {
  card: GroupItemsQuery_groupItems_edges_node_card;
}) => {
  const [addContacts] = useAddContactsMutation();
  const [removeContacts] = useRemoveContactsMutation();
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const defaultMessage =
    'An error occurred while adding the contact, please try again.';

  const handleClick = () => {
    setIsLoading(true);

    if (!props.card.isContact) {
      addContacts({
        variables: { input: { ids: [props.card.id] } },
        refetchQueries: [getContactsQueryName()],
      })
        .then(res => {
          const { status, message } = idx(res, _ => _.data!.addContacts) || {
            status: RequestStatusEnum.ERROR,
            message: defaultMessage,
          };
          setIsLoading(false);
          snackbar.setSnackbar(message, status);
        })
        .catch(error => {
          setIsLoading(false);
          snackbar.setSnackbar(defaultMessage, RequestStatusEnum.ERROR);
        });
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  const handleRemoveContact = () => {
    setOpen(false);
    setIsLoading(false);
    removeContacts({
      variables: { input: { ids: [props.card.id] } },
      refetchQueries: [getContactsQueryName()],
    }).catch(error => {
      snackbar.setSnackbar(defaultMessage, RequestStatusEnum.ERROR);
    });
  };

  return (
    <>
      <CircularButton
        style={{ backgroundColor: colors.yellow100 }}
        className="bg-mingl-green pointer bn"
        onClick={handleClick}
        size={40}
        children={
          <div className="flex justify-center align-center">
            {isLoading ? (
              <Spinner color={colors.yellow500} size={18} />
            ) : (
              <StarIcon
                stroke={props.card.isContact ? 'None' : `${colors.yellow500}`}
                fill={props.card.isContact ? `${colors.yellow500}` : 'None'}
                size={18}
              />
            )}
          </div>
        }
      />
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title="Remove from contacts?"
        contentText="The contact will not be notefied if you remove them."
        handleAction={handleRemoveContact}
      />
    </>
  );
};

const ROW_HEIGHT = 3;
const ROW_PADDING = 0.25;

export const defaultStyles: CardRowStyles = {
  container: {
    height: `${ROW_HEIGHT}rem`,
    padding: `${ROW_PADDING}rem`,
  },
  image: {
    height: `${ROW_HEIGHT - 0.5}rem`,
    width: `${ROW_HEIGHT - 0.5}rem`,
    objectFit: 'cover',
  },
  content: {
    letterSpacing: '0.25px',
  },
};

export class CardRow extends React.Component<CardRowProps> {
  static defaultProps = {};

  constructor(props: CardRowProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleBtnPress = this.handleBtnPress.bind(this);
  }

  handleClick(event: React.MouseEvent) {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick(this.props.card.id);
    }
  }
  handleBtnPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (this.props.onClick && event.key === 'Enter') {
      this.props.onClick(this.props.card.id);
    }
  }

  render(): JSX.Element | null {
    const { card, children } = this.props;
    const styles = { ...defaultStyles };
    if (this.props.styles) {
      const { container = {}, image = {}, content = {} } = this.props.styles;
      styles.container = { ...styles.container, ...container };
      styles.image = { ...styles.image, ...image };
      styles.content = { ...styles.content, ...content };
    }

    return (
      <div
        key={card.id}
        className="flex bg-white items-center br-pill"
        style={styles.container}
      >
        <img
          src={
            card.imageThumbnailURL ||
            require('../../../assets/img/profile_placeholder.png')
          }
          className="pointer br-pill flex-initial"
          onClick={this.handleClick}
          style={styles.image}
        />

        <div
          tabIndex={1}
          role="button"
          onKeyPress={this.handleBtnPress}
          onClick={this.handleClick}
          className="flex-auto flex flex-column items-start overflow-hidden ml2 mr2 pa1 pointer"
          style={styles.content}
        >
          <p className="f6 fw4 mt0 mb1 truncate" children={card.title} />
          {card.workTitle && (
            <div className="truncate">
              <p
                className="fw3 f6 mv0 truncate dib"
                children={card.workTitle}
              />
              {card.company && (
                <React.Fragment>
                  {' '}
                  <p className={'fw3 f6 mv0 truncate dib'} children="@" />{' '}
                  <p
                    className={'fw5 f6 mv0 truncate dib'}
                    children={card.company.name}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
    );
  }
}
