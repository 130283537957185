/**
 * This is a brutal simplifcation of idx as babel-plugin-idx does not
 * play with the TypeScript compiler.
 * https://github.com/facebookincubator/idx/issues/72
 */

export default function idx<Ti, Tv>(
  input: Ti,
  accessor: (input: Ti) => Tv
): Tv | undefined {
  try {
    return accessor(input);
  } catch (error) {
    return;
  }
}
