/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const AddImage = ({ color, size }: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} name="add_Image">
    <g fill="none" fillRule="evenodd">
      <path
        d="M19.5 12c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zM4 17l4-5.96L10.48 13 13 9l1.853 2.964A6.44 6.44 0 0013.026 17H4zm10.82 4H0V3h22v7.501a6.447 6.447 0 00-2-.476V5H2v14h11.502a6.507 6.507 0 001.318 2zM5.5 10a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill={color}
      />
    </g>
  </svg>
);

export default AddImage;
