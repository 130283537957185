/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const RejectedIcon = ({ color, size }: IconProps) => (
  <svg viewBox="0 0 60 60" width={size} height={size} name="rejected">
    <g fill="none" fillRule="evenodd" stroke={color} strokeWidth={2}>
      <circle cx={30} cy={30} r={29} />
      <path d="M50.963 10L10 50.963" />
    </g>
  </svg>
);

export default RejectedIcon;
