import * as React from 'react';

const cardBoxShadow =
  '0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)';

const styles = {
  image: {
    height: `3rem`,
    width: `3rem`,
    objectFit: 'cover' as 'cover',
  },
};

const GroupCard = (props: {
  group: {
    id: string;
    name: string;
    description?: string | null;
    icon?: string | null;
  };
  children: React.ReactNode;
}) => {
  const { icon, name, description } = props.group;

  return (
    <div className="bg-white br3 pa3 mb3" style={{ boxShadow: cardBoxShadow }}>
      <div className="pb3 mb3 flex mingl-bb">
        {icon && icon.startsWith('https://') ? (
          <img style={styles.image} className="br-pill" src={icon} />
        ) : (
          <span className="f2 self-start nowrap">{icon}</span>
        )}
        <div className="flex flex-column ml3">
          <span className={`f4 fw6 mingl-black`}>{name}</span>
          {description ? <span className="f5 gray">{description}</span> : null}
        </div>
      </div>

      {props.children}
    </div>
  );
};

export default GroupCard;
