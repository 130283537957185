import * as React from 'react';
import { CardNodeFragment } from '../../__generated/apollogen-types';
import { CardRow, StarCardRowButton } from '../viewer/components/CardRow';
import { navigate } from '@reach/router';
import * as cardNavigation from '../card/utils/cardNavigation';
import { ChatPopupButton } from '../ui/Popups/ChatPopup';

export function GroupMembersList(props: {
  cards: CardNodeFragment[];
  onChatTapped: () => void;
}) {
  return (
    <ul className="list pl0 mv0">
      {props.cards.map(card => (
        <li className="mb2" key={card.id}>
          <CardRow
            card={card}
            onClick={() => {
              navigate(cardNavigation.getCardPath(card));
            }}
          >
            <div className="pl1">
              <ChatPopupButton openChatPopup={props.onChatTapped} />
            </div>
            <div className="pl1">
              <StarCardRowButton card={card} />
            </div>
          </CardRow>
        </li>
      ))}
    </ul>
  );
}
