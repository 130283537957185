/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const CogwheelIcon = ({ color, size }: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} name="Cogwheel">
    <path
      d="M24 13.616v-3.232l-2.869-1.02a9.445 9.445 0 0 0-.811-1.955l1.308-2.751-2.285-2.285-2.751 1.307a9.468 9.468 0 0 0-1.955-.811L13.616 0h-3.232L9.363 2.869a9.413 9.413 0 0 0-1.955.811L4.657 2.372 2.372 4.657 3.68 7.409a9.39 9.39 0 0 0-.811 1.955L0 10.384v3.232l2.869 1.02a9.39 9.39 0 0 0 .811 1.955l-1.308 2.751 2.285 2.286 2.751-1.308a9.468 9.468 0 0 0 1.955.811L10.384 24h3.232l1.021-2.869a9.445 9.445 0 0 0 1.955-.811l2.751 1.308 2.285-2.286-1.308-2.751a9.445 9.445 0 0 0 .811-1.955L24 13.616zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
      fill={color}
    />
  </svg>
);

export default CogwheelIcon;
