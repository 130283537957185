import React, { createContext, useContext, useCallback } from 'react';
import { navigate } from '@reach/router';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MinglToolbar, NavbarPropsT } from './HeaderNavigation';

import XIcon from '../../components/icons/X-Icon';
import AddressBookIcon from '../../components/icons/AddressBookIcon';
import GroupsIcon from '../../components/icons/GroupsIcon';
import EventsIcon from '../../components/icons/EventsIcon';
import SettingsIcon from '../../components/icons/SettingsIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import UserAddIcon from '../../components/icons/userAddIcon';
import MinglIcon from '../../components/icons/MinglIcon';
import { ViewerAvatar } from '../ui/UserAvatar';
import * as theme from '../ui/theme';
import { useAuthContext } from '../core/providers/AuthProvider';

export interface DrawerNavContextT {
  drawerNavOpen: boolean;
  setDrawerNavOpen: (val: boolean) => void;
}

export const DrawerNavContext = createContext<DrawerNavContextT>({
  drawerNavOpen: false,
  setDrawerNavOpen: (value: boolean) => {},
});

export const appDrawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: appDrawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: appDrawerWidth,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface RouteT {
  path: string;
  name: string;
  Icon: (props: { size: number; color: string }) => JSX.Element;
}

/* eslint-disable react/display-name */
const routeMap: { [key: string]: RouteT } = {
  contacts: {
    path: '/home',
    name: 'Contacts',
    Icon: function RouteIcon(props) {
      return <AddressBookIcon size={props.size} color={props.color} />;
    },
  },
  events: {
    path: '/events',
    name: 'Events',
    Icon: function RouteIcon(props) {
      return <EventsIcon size={props.size} color={props.color} />;
    },
  },
  groups: {
    path: '/groups',
    name: 'Groups',
    Icon: function RouteIcon(props) {
      return <GroupsIcon size={props.size} color={props.color} />;
    },
  },
  login: {
    path: '/login',
    name: 'Log in',
    Icon: function RouteIcon(props) {
      return (
        <MinglIcon height={props.size} width={props.size} color={props.color} />
      );
    },
  },
  profile: {
    path: '/me/edit',
    name: 'Profile',
    Icon: function RouteIcon(props) {
      return <ViewerAvatar size={props.size} />;
    },
  },
  register: {
    path: '/register',
    name: 'Create account',
    Icon: function RouteIcon(props) {
      return <UserAddIcon size={props.size} color={props.color} />;
    },
  },
  search: {
    path: '/search',
    name: 'Search',
    Icon: function RouteIcon(props) {
      return (
        <SearchIcon
          height={props.size}
          width={props.size}
          color={props.color}
        />
      );
    },
  },
  settings: {
    path: '/settings',
    name: 'Settings',
    Icon: function RouteIcon(props) {
      return (
        <SettingsIcon
          height={props.size}
          width={props.size}
          color={props.color}
        />
      );
    },
  },
};

const routes = [
  routeMap.search,
  routeMap.contacts,
  routeMap.groups,
  routeMap.profile,
  routeMap.settings,
];

const unauthenticatedRoutes = [routeMap.register, routeMap.login];

export function DrawerNavigation(props: NavbarPropsT) {
  const classes = useStyles();
  const { drawerNavOpen, setDrawerNavOpen } = useContext(DrawerNavContext);
  const { isAuthenticated } = useAuthContext();

  const handleDrawerClose = useCallback(() => {
    setDrawerNavOpen(false);
  }, [setDrawerNavOpen]);

  const currentRoutes = isAuthenticated ? routes : unauthenticatedRoutes;

  const addEventsSectionIfNotExist = () => {
    if (!props.eventCount || !isAuthenticated) {
      return;
    }

    let eventsDoExist = false;

    currentRoutes.map((route, index) => {
      if (index == 2 && route == routeMap.events) {
        eventsDoExist = true;
      }
    });

    if (!eventsDoExist) {
      currentRoutes.splice(2, 0, routeMap.events);
    }
  };

  if (props.eventCount && isAuthenticated) {
    addEventsSectionIfNotExist();
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={drawerNavOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <MinglToolbar className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <XIcon width={25} height={25} color={theme.colors.gray100} />
        </IconButton>
      </MinglToolbar>

      <Divider />

      <List>
        {currentRoutes.map(({ name, path, Icon }, index) => (
          <ListItem
            button
            key={index}
            onClick={() => {
              setDrawerNavOpen(false);
              navigate(path);
            }}
          >
            <ListItemIcon>
              <Icon size={25} color={theme.colors.gray100} />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
