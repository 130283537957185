export const commonAnalyticsEvents = {
  user: {
    signedUp: 'User Signed Up', // User signed up via email.
    pressedSocialAuth: 'User Pressed Social Auth', // Log in or sign up via facebook or LinkedIn
    userCancelledSocialAuth: 'User Cancelled Social Auth',
    loggedIn: 'User Logged In', // User logged in via email or social.
    loggedOut: 'User Logged Out',
  },
  card: {
    viewed: 'Card Viewed',
    tappedSoMe: 'Card Social Media Tapped',
    tappedShare: 'Card Share Tapped',
    shareDirectSuccess: 'Card Share Direct Success',
    shareDirectFailed: 'Card Share Direct Failed',
    shareDirectInvalid: 'Card Share Direct Invalid',
    downloadContact: 'Card Download',
    downloadContactFailed: 'Card Download Failed',
    mergeContactPrompt: 'Card Download Merge Contact Prompt',
    qrButtonTapped: 'Card QR Button Tapped',
  },
  minglMode: {
    scanned: 'MinglMode Scanned QR',
  },
  search: {
    performed: 'Search Performed', // TODO: SHOULD BE THERE
    resultSelected: 'Search Result Selected', // TODO: SHOULD BE THERE
  },
  apollo: {
    retryQuery: 'Apollo Retry Query', // TODO: SHOULD BE THERE (MIDDLEWARE ?)
    networkError: 'Apollo Network Error',
    unauthenticatedUser: 'Apollo Unauthenticated User',
  },
  cardRow: {
    click: 'Card Row Click',
  },
  cardRowActions: {
    opened: 'Card Row Actions Opened',
    cancelled: 'Card Row Actions Cancelled',
    addContact: 'Card Row Actions Add Contact',
    list: 'Card Row Actions Add To List',
    share: 'Card Row Actions Share',
    group: 'Card Row Actions Add To Group',
    delete: 'Card Row Actions Delete',
    deleteFailed: 'Card Row Actions Delete Failed',
    cardInfo: 'Card Row Actions Card Info',
  },
  claimAlias: {
    opened: 'Claim Alias Opened',
    dismissed: 'Claim Alias Dismissed',
    claimPressed: 'Claim Alias Claim Button Pressed',
    success: 'Claim Alias successful',
    fail: 'Claim Alias failed',
  },
  settingsPage: {
    feedback: 'Feedback button pressed',
  },
  groups: {
    newPressed: 'Create New Group Pressed',
    newSuccessful: 'Create New Group Successful',
    newFailed: 'Create New Group Failed',
    changeSuccessful: 'Change Group Successful',
    changeFailed: 'Change Group Failed',
    deleteGroupPressed: 'Delete Group Pressed',
    deleteGroupSuccessful: 'Delete Group Successful',
    deleteGroupFailed: 'Delete Group Failed',
  },
  groupInvites: {
    inviteAcceptedPressed: 'Accepting Group Invite Pressed',
    inviteAcceptedFailed: 'Accepting Group Invite Failed',
    inviteDeclinedPressed: 'Declining Group Invite Pressed',
    inviteDeclinedSuccessful: 'Declining Group Invite Successful',
    inviteDeclinedFailed: 'Declining Group Invite Failed',
    addContactsSuccessful: 'Adding Contacts Successful',
  },
  contactInformation: {
    updateSuccess: 'Contact Information Update Success',
    updateFailed: 'Contact Information Update Failed',
    updateInvalid: 'Contact Information Update Invalid',
    createSuccess: 'Contact Information Create Success',
    createFailed: 'Contact Information Create Failed',
    createInvalid: 'Contact Information Create Invalid',
    deleteSuccess: 'Contact Information Delete Success',
    deleteFailed: 'Contact Information Delete Failed',
  },
};
