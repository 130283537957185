import * as React from 'react';
import { colors } from './theme';
import Loader from '../../components/Loader';

type ButtonPropsT = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface ButtonContainerPropsT extends ButtonPropsT {
  mood: 'positive' | 'neutral' | 'negative';
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const stylesheet = {
  positive: {
    backgroundColor: colors.green500,
    border: `1px solid ${colors.green500}`,
    color: 'white',
  },
  negative: {
    border: `none`,
    color: colors.red400,
    whiteSpace: 'nowrap' as 'nowrap',
  },
  neutral: {
    backgroundColor: 'white',
    border: `1px solid ${colors.gray300}`,
    color: colors.gray800,
  },
};

const ButtonContainer = (props: ButtonContainerPropsT) => {
  const { onClick, disabled, mood, children, ...buttonProps } = props;
  return (
    <button
      onClick={onClick}
      className={`dib w-100 br2 f5 pv1 ph3` + `${disabled ? '' : ' pointer'}`}
      style={stylesheet[mood]}
      type="button"
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export const ButtonWithLoadingState = (
  props: Omit<ButtonContainerPropsT, 'children'> & {
    text: React.ReactNode;
    loading?: boolean;
  }
) => {
  const { text, loading, ...containerProps } = props;
  return (
    <ButtonContainer {...containerProps}>
      <div className="flex content-center justify-center">
        {props.loading ? (
          <Loader color="currentColor" size={0.5} />
        ) : (
          <span>{props.text}</span>
        )}
      </div>
    </ButtonContainer>
  );
};

export default ButtonContainer;
