import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';

import {
  JoinGroup as JoinGroupMutationT,
  JoinGroupVariables as JoinGroupMutationVariables,
  RequestStatusEnum,
  JoinGroup_joinGroup,
} from '../__generated/apollogen-types';
import JOIN_GROUP_MUTATION from './JoinGroupMutation.graphql';
import idx from '../utils/idx';

type DataT = JoinGroupMutationT;
type VariablesT = JoinGroupMutationVariables;

type JoinGroupMutationOpts = MutationComponentOptions<DataT, VariablesT>;
export type JoinGroupMutationFnT = MutationFunction<DataT, VariablesT>;

export function JoinGroupMutation(props: {
  children: JoinGroupMutationOpts['children'];
}) {
  return (
    <Mutation<DataT, VariablesT> {...props} mutation={JOIN_GROUP_MUTATION} />
  );
}

export const useJoinGroupMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(JOIN_GROUP_MUTATION, options);
};

// ============================================
type MutationOutputT = JoinGroup_joinGroup;

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledJoinGroupMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [joinGroupMutation] = useJoinGroupMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledAdminAddAliasMutation = React.useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'JoinGroupMutationOutput',
        message: `Failed to join group.`,
        status: RequestStatusEnum.ERROR,
        group: null,
      };

      try {
        const res = await joinGroupMutation(options);
        return idx(res, _ => _!.data!.joinGroup) || fallbackResponse;
      } catch (error) {
        return fallbackResponse;
      }
    },
    [joinGroupMutation]
  );

  return handledAdminAddAliasMutation;
};
