import * as React from 'react';
import { navigate } from '@reach/router';
import debounce from 'lodash-es/debounce';
import idx from '../../../utils/idx';

import {
  AuthConsumer,
  AuthProviderContextT,
} from '../../core/providers/AuthProvider';
import { setPostAuthPath } from '../../app/storage/appStorage';
import Button from '../../ui/Button';
import LinkButton from '../../ui/LinkButton';
import { SiteBackground } from '../../../modules/app/containers/SiteBackground';
import analytics, { analyticsEvents } from '../../../utils/analytics';
import {
  ClaimAliasMutation,
  ClaimAliasMutationFnT,
} from '../../../queries/ClaimAliasMutation';
import { captureException } from '../../../utils/raven';
import { urls } from '../../../config';
import { useSnackbar } from '../../ui/Snackbar';

interface SignUpToClaimProps {
  authState: AuthProviderContextT;
}

const timeoutPromise = (timeout: number) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};

const AppButtons = () => (
  <div className="app-stores-container center" style={{ width: '152px' }}>
    <a
      className="db ma2 mb3"
      target="_blank"
      rel="noopener noreferrer"
      href={urls.playStore}
      onClick={() =>
        analytics.track(analyticsEvents.claimAliasNotLoggedIn.androidAppClicked)
      }
    >
      <img
        className="w-100"
        src={require('../../../assets/img/google-play-badge.png')}
      />
    </a>
    <a
      className="db ma2"
      target="_blank"
      rel="noopener noreferrer"
      href={urls.appStore}
      onClick={() =>
        analytics.track(analyticsEvents.claimAliasNotLoggedIn.iosAppClicked)
      }
    >
      <img
        className="w-100"
        src={require('../../../assets/img/Download_on_the_App_Store_Badge.svg')}
      />
    </a>
  </div>
);

const LoginSignup = (props: { authState: AuthProviderContextT }) => {
  const onSignUp = debounce(
    async () => {
      analytics.track(analyticsEvents.claimAliasNotLoggedIn.signUpClicked);
      await setPostAuthPath(window.location.pathname);
      await timeoutPromise(50);
      props.authState.createAccount('/authCallback');
    },
    225,
    { leading: true, trailing: false }
  );

  const onLogin = debounce(
    async () => {
      analytics.track(analyticsEvents.claimAliasNotLoggedIn.logInClicked);
      await setPostAuthPath(window.location.pathname);
      await timeoutPromise(50);
      props.authState.login('/authCallback');
    },
    225,
    { leading: true, trailing: false }
  );

  return (
    <React.Fragment>
      <Button
        className="bn br-pill pointer bg-button-green grow box-shadow-1 pv2 ph4 mb3"
        onClick={onSignUp}
        fontSize={5}
        children={
          <span
            className="f5 b ls-05 lh-title white ttc"
            children="Sign up for free"
          />
        }
      />
      <br />

      <LinkButton
        className="white underline"
        onClick={onLogin}
        fontSize={6}
        children="Existing user?"
      />
    </React.Fragment>
  );
};

class SignUpToClaim extends React.Component<SignUpToClaimProps, {}> {
  componentDidMount() {
    analytics.track(analyticsEvents.claimAliasNotLoggedIn.opened);
  }
  render() {
    return (
      <div>
        <h4 className="f4 fw5 lh-title mt0 mb3">
          Connect it to your Mingl card with the Mingl app
        </h4>
        <div className="mb4">
          <AppButtons />
        </div>

        <h4 className="f4 fw5 lh-title mt0 mb3">
          Alternatively, continue in the browser
        </h4>
        <LoginSignup authState={this.props.authState} />
      </div>
    );
  }
}

function QRTip(props: { className?: string; children: React.ReactNode }) {
  return (
    <div className={`inline-flex items-start tl ${props.className}`}>
      <div className="f4 lh-copy flex-initial">💡</div>
      <div>
        <p className="f5 lh-copy mv0">{props.children}</p>
      </div>
    </div>
  );
}

interface ClaimAliasProps {
  alias: string;
  claim: ClaimAliasMutationFnT;
}

function ClaimAliasPage(props: ClaimAliasProps) {
  const snackbar = useSnackbar();

  React.useEffect(() => {
    analytics.track(analyticsEvents.claimAlias.opened, {
      alias: props.alias,
    });
  }, [props.alias]);

  const onClaimQr = async () => {
    analytics.track(analyticsEvents.claimAlias.claimPressed);
    const defaultMsg =
      'Failed to connect the QR code to you profile, please try again';

    try {
      const res = await props.claim({
        variables: { input: { alias: props.alias } },
      });
      if (!res) {
        throw new Error(defaultMsg);
      }

      const claimedAlias = idx(res, _ => _.data!.claimAlias!.claimedAlias);
      if (claimedAlias) {
        snackbar.setSnackbar(
          'The QR code is now linked to your Profile',
          'success'
        );
        analytics.track(analyticsEvents.claimAlias.success, {
          aliasClaimed: claimedAlias,
        });
        navigate('/home');
      } else {
        const error = (res.errors || []).find(err =>
          (err.path || []).includes('claimAlias')
        );
        const message = error && error.message ? error.message : defaultMsg;
        throw new Error(message);
      }
    } catch (error) {
      snackbar.setSnackbar(error.message || defaultMsg, 'error');
      analytics.track(analyticsEvents.claimAlias.fail, {
        failedAlias: props.alias,
      });
      captureException(error, {
        extra: {
          event: analyticsEvents.claimAlias.claimPressed,
        },
      });
    }
  };

  return (
    <div>
      <div className="mb5">
        <p className="f4 f3-ns lh-title mt0 mb3">
          Connect this QR code to your Mingl card?
        </p>
        <Button
          fontSize={5}
          onClick={onClaimQr}
          className="br-pill pointer bn grow bg-button-green drop-shadow-1 pv2 ph4"
          children={
            <span
              className="f4 b tracked lh-title white ttc"
              children="Connect"
            />
          }
        />
      </div>

      <div className="ph3">
        <h4 className="f4 lh-title mt0 mb3">Tips</h4>
        <QRTip className="mb3">
          Put the sticker on your phone or conference badge, and start sharing
        </QRTip>
        <br />
        <QRTip className="mb3">
          Did you know all iPhones have QR scanners integrated in the camera?
        </QRTip>
      </div>
    </div>
  );
}

const ClaimAliasContainer = (props: { alias: string }) => {
  return (
    <div className="tc">
      <SiteBackground background="#1b6f83" />

      <div className="dib white pt4 pt5-ns ph3 mw-content">
        <h1 className="f2 lh-title fw6 ls-05 mt0 mb2">Congratulations!</h1>
        <h4 className="f5 lh-title fw5 mt0 mb4">
          You just scanned an available QR code!
        </h4>

        <AuthConsumer>
          {authState => {
            if (!authState.isAuthenticated) {
              return <SignUpToClaim authState={authState} />;
            }

            return (
              <ClaimAliasMutation
                children={claimAlias => (
                  <ClaimAliasPage alias={props.alias} claim={claimAlias} />
                )}
              />
            );
          }}
        </AuthConsumer>
      </div>
    </div>
  );
};

export default ClaimAliasContainer;
