import * as React from 'react';
import Dropzone from 'react-dropzone';
import { renderImageAccept, renderImageReject } from './ImageUploadModes';
import { UploadImageFn } from '../../modules/forms/formTypes';

export interface ImageUploaderChildProps {
  tempImg: string | null;
  removeTempImage: () => void;
  isDragAccept: boolean;
  isDragReject: boolean;
  uploadProgress: number;
  fsDialog: () => void;
  dropRejected?: boolean;
}

interface ImageUploaderProps {
  onChange: UploadImageFn;
  children: (childProps: ImageUploaderChildProps) => React.ReactNode;
  width?: string;
  height?: string;
}

interface ImageUploaderState {
  tempImg: string | null;
  uploadProgress: number; // integer from 0->100
  dropRejected: boolean;
}

interface DropzoneState {
  isDragAccept: boolean;
  isDragActive: boolean;
  isDragReject: boolean;
  acceptedFiles: boolean;
  rejectedFiles: boolean;
}

const dropzoneStyle = {
  display: 'inline-block',
  cursor: 'pointer',
};

export class ImageUploader extends React.Component<
  ImageUploaderProps,
  ImageUploaderState
> {
  dropzoneRef: Dropzone | null = null;

  state = {
    tempImg: null,
    uploadProgress: 0,
    dropRejected: false,
  };

  fsDialog = () => {
    if (this.dropzoneRef) {
      this.dropzoneRef.open();
    }
  };

  removeTempImage = () => {
    this.setState({ tempImg: null });
  };

  onUploadProgress = (uploadProgress: number) => {
    this.setState({ uploadProgress });
  };

  render(): React.ReactNode {
    const { onChange, width = '200px', height = '200px' } = this.props;
    return (
      <Dropzone
        maxSize={10e6}
        disableClick={true}
        ref={node => {
          this.dropzoneRef = node;
        }}
        style={{ width, height, ...dropzoneStyle }}
        accept="image/jpeg, image/png, image/jpg"
        onDropRejected={() => {
          this.setState({ dropRejected: true });
        }}
        onDropAccepted={acceptedFiles => {
          this.setState({ dropRejected: false });

          const fileReader = new FileReader();

          fileReader.addEventListener(
            'load',
            () => this.setState({ tempImg: fileReader.result as string }),
            false
          );

          // TODO: memory leak here when uploading multiple images
          fileReader.readAsDataURL(acceptedFiles[0]);
          onChange(acceptedFiles[0], this.onUploadProgress);
        }}
      >
        {({ isDragAccept, isDragReject }: DropzoneState) => {
          return this.props.children({
            isDragAccept,
            isDragReject,
            tempImg: this.state.tempImg,
            removeTempImage: this.removeTempImage,
            uploadProgress: this.state.uploadProgress,
            fsDialog: this.fsDialog,
            dropRejected: this.state.dropRejected,
          });
        }}
      </Dropzone>
    );
  }
}

interface SimpleImageUploaderProps
  extends Omit<ImageUploaderProps, 'children'> {
  children: (
    props: Omit<ImageUploaderChildProps, 'isDragAccept' | 'isDragReject'>
  ) => React.ReactNode;
}

export default function SimpleImageUploader(props: SimpleImageUploaderProps) {
  return (
    <ImageUploader
      height={props.height}
      width={props.width}
      onChange={props.onChange}
      children={({
        isDragAccept,
        isDragReject,
        tempImg,
        fsDialog,
        removeTempImage,
        uploadProgress,
        dropRejected,
      }) => {
        if (isDragAccept) {
          return renderImageAccept();
        }
        if (isDragReject || dropRejected) {
          return renderImageReject(fsDialog);
        }
        return props.children({
          fsDialog,
          uploadProgress,
          tempImg,
          removeTempImage,
        });
      }}
    />
  );
}
