import * as React from 'react';
import { TextField, Button, ButtonGroup } from '@material-ui/core';
import {
  UpdateResourceInput,
  GroupResource,
} from '../../../__generated/apollogen-types';
import ResourceAvatar from './ResourceAvatar';

interface EditResourceFormProps {
  resource: GroupResource;
  onSubmit: (form: UpdateResourceInput) => void;
  onCancel: () => void;
  onDelete: () => void;
}

export default function EditResourceForm(props: EditResourceFormProps) {
  const { onSubmit, resource } = props;
  const [label, setLabel] = React.useState(props.resource.label);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setLabel(value);
    },
    [setLabel]
  );

  const handleSubmit = React.useCallback(() => {
    onSubmit({ label, id: resource.id });
  }, [onSubmit, label, resource]);

  const RESOURCE_SIZE = '40px';

  return (
    <form className="w-100">
      <div className="flex mb2 w-100">
        <div
          className="mr3"
          style={{
            width: RESOURCE_SIZE,
            height: RESOURCE_SIZE,
            minWidth: RESOURCE_SIZE,
          }}
        >
          <ResourceAvatar imgUrl={props.resource.icon} className="br2" />
        </div>
        <TextField
          id="label"
          name="label"
          className="w-100"
          label="Title (optional)"
          variant="outlined"
          value={label}
          onChange={handleChange}
          size="small"
        />
      </div>
      <div className="flex justify-end">
        <ButtonGroup variant="outlined" color="default">
          <Button children="DELETE" onClick={props.onDelete} />
          <Button children="CANCEL" onClick={props.onCancel} />
          <Button children="SAVE" onClick={handleSubmit} />
        </ButtonGroup>
      </div>
    </form>
  );
}
