import * as React from 'react';
import { navigate, Redirect } from '@reach/router';
import HeaderNavigation, {
  NavbarPropsT,
} from '../modules/Navigation/HeaderNavigation';
import { DrawerNavigation } from '../modules/Navigation/DrawerNavigation';
import {
  AuthenticatedBorder,
  UnauthenticatedBorder,
  useAuthContext,
} from '../modules/core/providers/AuthProvider';
import { useGroupListQuery } from '../queries/GroupListQuery';
import idx from '../utils/idx';
import { useLastEventQuery } from '../queries/LastEventQuery';

interface PageContainerProps {
  children?: React.ReactNode;
  mainStyle?: React.CSSProperties;
  redirectUnauthenticated?: boolean;
  // desktop props
  leftNav?: NavbarPropsT['leftNav'];
  centerNav?: NavbarPropsT['centerNav'];
  rightNav?: NavbarPropsT['rightNav'];

  // mobile props
  mobileTitle?: NavbarPropsT['mobileTitle'];
  mobileNavItem?: NavbarPropsT['mobileNavItem'];
  mobileActionItems?: NavbarPropsT['mobileActionItems'];
}

export function PageContainer(props: PageContainerProps) {
  const { isAuthenticated } = useAuthContext();
  let redirectUnauthenticated = props.redirectUnauthenticated;
  if (typeof redirectUnauthenticated === 'undefined') {
    redirectUnauthenticated = true;
  }

  const lastEventQuery = useLastEventQuery();
  const lastEvent = idx(lastEventQuery.data, _ => _!.lastEvent) || [];

  return (
    <div className="flex flex-column align-stretch h-100">
      {redirectUnauthenticated && !isAuthenticated && (
        <Redirect noThrow={true} to="/" />
      )}
      <HeaderNavigation
        navigate={navigate}
        mobileNavItem={props.mobileNavItem}
        mobileTitle={props.mobileTitle}
        mobileActionItems={props.mobileActionItems}
        leftNav={props.leftNav}
        centerNav={props.centerNav}
        rightNav={props.rightNav}
        eventCount={lastEvent.length}
      />

      <main className="flex flex-column flex-grow" style={props.mainStyle}>
        {props.children}
      </main>

      <DrawerNavigation navigate={navigate} eventCount={lastEvent.length} />
    </div>
  );
}

export default PageContainer;
