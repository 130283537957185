/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const UserIcon = ({ color, size }: IconProps) => (
  <svg
    width={size}
    height={size}
    name="UserIcon"
    viewBox="0 0 24 24"
    fillOpacity={0}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
    <circle cx={12} cy={7} r={4} />
  </svg>
);

export default UserIcon;
