import * as React from 'react';
import debounce from 'lodash-es/debounce';
import AvatarEditor from 'react-avatar-editor';

import CircularButton from './CircularButton';
import DeviceCameraIcon from '../../components/icons/DeviceCameraIcon';
import SimpleImageUploader from '../../components/ImageUploader/ImageUploader';
import { strictImageCanBeTransformed } from '../../utils/minglImageUtils';
import {
  renderImageUploading,
  renderNoImage,
} from '../../components/ImageUploader/ImageUploadModes';
import { UploadImageFn } from '../forms/formTypes';

interface ImageInputEditorProps {
  onImageChange: UploadImageFn;
  onChange: (config: { x: string; y: string }) => void;
  value: string | null;
  width: number;
  height: number;
}

interface State {
  editMode: boolean;
  uploadingImage: boolean;
  showEditImgInfo: boolean;
  position: {
    x: number;
    y: number;
  };
}

const EditImgInfo = () => {
  return (
    <div
      className="absolute right-0 left-0 bottom-2 center w-50 tc f5 pa1 bg-black-60 white"
      style={{
        borderRadius: '4px',
        border: '1px solid #ffffffd6',
        cursor: 'default',
      }}
      children="Drag to adjust image"
    />
  );
};

const ImageDisplay = (props: { imageUrl: string }) => (
  <div
    style={{
      backgroundImage: `url(${props.imageUrl})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      height: '100%',
      cursor: 'default',
    }}
  />
);

export default class ImageInputEditor extends React.Component<
  ImageInputEditorProps,
  State
> {
  state: State = {
    editMode: false,
    uploadingImage: false,
    showEditImgInfo: false,
    position: { x: 0.5, y: 0.5 },
  };

  checkAndEnableEditMode = (url: string) => {
    if (strictImageCanBeTransformed(url)) {
      this.setState({
        editMode: true,
        showEditImgInfo: true,
        uploadingImage: false,
      });
    } else {
      this.setState({
        editMode: false,
        showEditImgInfo: false,
        uploadingImage: false,
      });
    }
  };

  componentDidMount() {
    if (this.props.value) {
      this.checkAndEnableEditMode(this.props.value);
    }
  }

  componentDidUpdate(prevProps: ImageInputEditorProps) {
    if (this.props.value && prevProps.value !== this.props.value) {
      this.checkAndEnableEditMode(this.props.value);
    }
  }

  handleImageDrop: UploadImageFn = async (imageFile, onProgress) => {
    this.setState({ uploadingImage: true });
    return this.props.onImageChange(imageFile, onProgress);
  };

  handleImageReady = () => {
    this.setState({ uploadingImage: false });
    this.handlePositionChange({ x: 0.5, y: 0.5 }, false);
  };

  handlePositionChange = (
    position: { x: number; y: number },
    triggeredByUser = true
  ) => {
    if (triggeredByUser && this.state.showEditImgInfo === true) {
      this.setState({ position, showEditImgInfo: false });
    } else {
      this.setState({ position });
    }
    this.debouncedOnChange(position);
  };

  debouncedOnChange = debounce((position: { x: number; y: number }) => {
    this.props.onChange({
      x: position.x.toString(),
      y: position.y.toString(),
    });
  }, 300);

  render() {
    const { showEditImgInfo, editMode } = this.state;
    const { value, height, width } = this.props;

    const border = editMode ? 25 : 0;

    const avatarProps = {
      border,
      width: Math.round(width - 2 * border),
      height: Math.round(height - 2 * border),
      style: { cursor: 'move' },
    };

    return (
      <SimpleImageUploader
        height="100%"
        width="100%"
        onChange={this.handleImageDrop}
        children={({ fsDialog }) => {
          if (this.state.uploadingImage) {
            return renderImageUploading();
          }
          if (!value) {
            return renderNoImage({ fsDialog });
          }

          return (
            <React.Fragment>
              {!this.state.editMode && <ImageDisplay imageUrl={value} />}

              {this.state.editMode && (
                <AvatarEditor
                  image={value}
                  position={this.state.position}
                  {...avatarProps}
                  onImageReady={this.handleImageReady}
                  onPositionChange={this.handlePositionChange}
                />
              )}

              <React.Fragment>
                <CircularButton
                  className="absolute right-1 top-1 bg-mingl-green bn pointer"
                  size={42}
                  onClick={fsDialog}
                  children={
                    <DeviceCameraIcon width={18} height={18} color={'white'} />
                  }
                />
                {showEditImgInfo && <EditImgInfo />}
              </React.Fragment>
            </React.Fragment>
          );
        }}
      />
    );
  }
}
