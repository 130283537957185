import * as React from 'react';

import '../../card/containers/SelectorModal.css';

import MinglModal from '../../card/containers/MinglModal';

export const CopyGroupLinkPopup = (props: {
  show: boolean;
  close: () => void;
  groupLink: string;
}) => (
  <MinglModal
    className={{
      base: 'selector-modal appear',
      afterOpen: 'appear-enter',
      beforeClose: 'appear-exit',
    }}
    overlayClassName={{
      base: `selector-background`,
      afterOpen: 'fade-in',
      beforeClose: 'fade-out',
    }}
    style={{ overlay: { zIndex: 1050 } }}
    isOpen={props.show}
    onRequestClose={props.close}
    children={modalProps => {
      return (
        <div className="bg-white br3 pa3 ma3">
          <p className="f5 lh-copy">Use the following link to invite people:</p>
          <div style={{ wordBreak: 'break-word' }} className="flex">
            <p className="f6">{props.groupLink}</p>
          </div>
        </div>
      );
    }}
  />
);
