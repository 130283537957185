import { QueryHookOptions, useQuery } from 'react-apollo';
import {
  GroupAdmins as DataT,
  GroupAdminsVariables as VariablesT,
} from '../__generated/apollogen-types';
import QUERY from './GroupAdminsQuery.graphql';

export const useGroupAdminsQuery = (
  variables: VariablesT,
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery<DataT, VariablesT>(QUERY, {
    ...options,
    variables,
  });
};
