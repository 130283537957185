import * as React from 'react';
import idx from '../utils/idx';
import {
  Query,
  QueryComponentOptions,
  useQuery,
  QueryHookOptions,
} from 'react-apollo';
import { getOperationName } from 'apollo-utilities';

import { QueryComponentInputProps } from './QueryComponentTypes';
import {
  Viewer as ViewerQuery,
  Viewer_viewer,
} from '../__generated/apollogen-types';

import LoadingPage from '../components/LoadingPage';
import ErrorMessage from '../components/ErrorMessage';
import VIEWER_QUERY from './ViewerQuery.graphql';

export type ViewerQueryT = ViewerQuery;
export type ViewerT = Viewer_viewer;

type DataT = ViewerQueryT;
type VariablesT = null;

type PropsT = Omit<QueryComponentOptions<DataT, VariablesT>, 'query'>;

export function ViewerQuery({ children }: { children: PropsT['children'] }) {
  return <Query<DataT, VariablesT> query={VIEWER_QUERY} children={children} />;
}

export const getViewerQueryName = () => getOperationName(VIEWER_QUERY) || '';

export default function QueryViewerForRoute({
  render,
  renderLoading = LoadingPage,
  renderError = ErrorMessage,
}: QueryComponentInputProps<DataT, VariablesT>) {
  const variables: VariablesT = null;
  const ErrorComponent = renderError;

  return (
    <Query<DataT, VariablesT> query={VIEWER_QUERY}>
      {apollo => {
        const { data, loading, error, refetch } = apollo;
        if (error && !loading) {
          const message =
            idx(error, _error => _error!.graphQLErrors![0].message) || '';

          return (
            <ErrorComponent
              error={error}
              extra={{ component: 'ViewerQuery' }}
              message={error.message || message}
              retry={() => refetch(variables)}
            />
          );
        }
        if (loading && (!data || !data.viewer)) {
          return renderLoading();
        }
        return render(data || {}, apollo);
      }}
    </Query>
  );
}

export function GetViewer(props: {
  children: (childProps: Partial<ViewerQueryT>) => JSX.Element | null;
}) {
  return (
    <Query<DataT, VariablesT>
      query={VIEWER_QUERY}
      children={({ data }) => props.children(data || {})}
    />
  );
}

export const useViewerQuery = (options?: QueryHookOptions<ViewerQueryT>) => {
  return useQuery<ViewerQueryT>(VIEWER_QUERY, options);
};
