import * as React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import idx from '../../../utils/idx';

import { useNodeQuery } from '../../../queries/GetNode';
import PageContainer from '../../../containers/PageContainer';
import { NavComponents } from '../../Navigation/HeaderNavigationComponents';
import CardForm from '../../Common/CardForm';
import analytics, { analyticsEvents } from '../../../utils/analytics';
import { Viewer_viewer } from '../../../__generated/apollogen-types';
import { useViewerQuery } from '../../../queries/ViewerQuery';
import ManageContactInfo from '../../Common/ManageContactInfo';

type EditNodePageProps = RouteComponentProps<{
  viewer: Viewer_viewer;
}> & {
  returnTo: string;
};

function EditNodePage(props: EditNodePageProps) {
  const { returnTo, viewer } = props;
  const cardId = idx(viewer, _ => _!.cards[0]!.id) || '';

  const { data, refetch } = useNodeQuery({
    variables: { id: cardId },
    skip: !cardId,
  });
  const card = idx(data, _ => _!.node);

  React.useEffect(() => {
    analytics.track(analyticsEvents.editCardScreen.opened);
  }, []);

  const returnHome = React.useCallback(() => {
    navigate(returnTo);
  }, [returnTo]);

  if (!viewer || !cardId) {
    return null;
  }

  if (!card || card.__typename !== 'Card') {
    return <p>You cannot edit this here</p>;
  }

  if (!viewer.cards.find(card => card.id === card.id)) {
    return <p>You are not the owner of this card</p>;
  }

  return (
    <div className="tc">
      <CardForm
        viewer={viewer}
        card={card}
        onDone={() => {
          analytics.track(analyticsEvents.editCardScreen.saved);
          returnHome();
        }}
        onCancel={() => {
          analytics.track(analyticsEvents.editCardScreen.cancelled);
          returnHome();
        }}
      />

      <div className="card-container white">
        <ManageContactInfo card={card} refetch={refetch} />
      </div>
    </div>
  );
}

export function EditOwnCardRoute(props: EditNodePageProps) {
  const { data } = useViewerQuery();
  const viewer = idx(data, _ => _!.viewer) || undefined;

  return (
    <PageContainer
      mobileNavItem={<NavComponents.Close returnPath={props.returnTo} />}
    >
      <EditNodePage viewer={viewer} {...props} />
    </PageContainer>
  );
}
