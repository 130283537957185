import * as React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Popover,
  Typography,
  Button,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import head from 'lodash-es/head';

import { colors, spacing } from '../../ui/theme';
import CameraIcon from '../../../components/icons/CameraIcon';
import { uploadImage } from '../../../api';
import AddImage from '../../../components/icons/AddImage';
import { useUpdateGroupMutation } from '../../../queries/UpdateGroupMutation';
import idx from '../../../utils/idx';
import { useSnackbar } from '../../ui/Snackbar';
import analytics, { analyticsEvents } from '../../../utils/analytics';
import {
  RequestStatusEnum,
  GroupFragment,
} from '../../../__generated/apollogen-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      minWidth: `5rem`,
      minHeight: `5rem`,
      objectFit: 'cover' as 'cover',
    },
    imageEmptyState: {
      minWidth: `5rem`,
      minHeight: `5rem`,

      objectFit: 'cover' as 'cover',
      backgroundColor: colors.blue900,
      display: 'flex',
      flexDirection: 'row' as 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '9999px',
      cursor: 'pointer',
      userSelect: 'none' as 'none',
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      alignSelf: 'end',
      backgroundColor: '#000000a8',
      color: colors.light,
      minWidth: `5rem`,
      minHeight: `5rem`,
      borderRadius: '9999px',
    },
    popoverContainer: {
      maxWidth: '600px',
      paddingLeft: spacing[4],
      paddingRight: spacing[4],
      paddingTop: spacing[3],
      paddingBottom: spacing[3],
      backgroundColor: colors.light,
      display: 'flex',
      flexDirection: 'column',
    },
    selectImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '400px',
      backgroundColor: colors.gray50,
      height: '12rem',
      width: '12rem',
      border: `2px dashed ${colors.gray400}`,
      cursor: 'pointer',
      marginBottom: spacing[3],
      '&:hover': {
        backgroundColor: colors.gray200,
      },
    },
    typography: {
      padding: spacing[2],
      color: colors.gray800,
      textAlign: 'center',
    },
    popoverHeaderText: {
      color: theme.palette.grey[800],
      textAlign: 'center',
      marginBottom: spacing[3],
      fontWeight: theme.typography.fontWeightMedium,
    },
    popoverPrimaryButton: {
      backgroundColor: colors.green200,
      color: colors.green800,
      '&:hover': {
        backgroundColor: colors.green300,
      },
    },
    popoverSecondaryButton: {
      backgroundColor: colors.gray200,
      marginBottom: spacing[3],
      color: colors.gray800,
      '&:hover': {
        backgroundColor: colors.gray300,
      },
    },
    popoverInput: {
      position: 'absolute',
      overflow: 'hidden',
      visibility: 'hidden',
    },
  })
);

const FILE_INPUT_ID = 'image-file';
const openFileSelector = () => {
  try {
    //@ts-ignore
    window.document.getElementById(FILE_INPUT_ID).click();
  } catch (error) {
    console.warn(error);
  }
};

const IconOrImagePreview = (props: {
  groupIcon: string;
  file: File | undefined;
  iconIsDefault: boolean;
}) => {
  const classes = useStyles();

  const imageURL = props.file ? URL.createObjectURL(props.file) : '';
  if (props.iconIsDefault && !imageURL) {
    return (
      <div className={classes.selectImage} onClick={() => openFileSelector()}>
        <AddImage size={38} color={colors.gray700} />
        <Typography variant="body1" className={classes.typography}>
          Select a photo from your computer
        </Typography>
      </div>
    );
  }
  return (
    <div className="flex items-center justify-center mb3">
      <DisplayGroupIcon groupIcon={imageURL || props.groupIcon} />
    </div>
  );
};

const DisplayGroupIcon = (props: { groupIcon: string }) => {
  const classes = useStyles();
  return props.groupIcon.startsWith('https://') ||
    props.groupIcon.startsWith('blob') ? (
    <img
      className={`${classes.image} h3 h4-m h4-l w3 w4-m w4-l br-pill`}
      src={props.groupIcon}
    />
  ) : (
    <span className="f-5 self-center nowrap">{props.groupIcon}</span>
  );
};

const FileSelector = (props: { onFile: (file: File | undefined) => void }) => {
  const classes = useStyles();
  return (
    <input
      id={FILE_INPUT_ID}
      className={classes.popoverInput}
      type="file"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        props.onFile(head(e.target.files))
      }
    />
  );
};

const PopoverUploadImage = (props: {
  open: boolean;
  id: string | undefined;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  groupIcon: string;
  groupId: string;
  groupName: string;
  groupDescription: string;
  iconIsDefault: boolean;
}) => {
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [updateGroup] = useUpdateGroupMutation();
  const { setSnackbar } = useSnackbar();
  const classes = useStyles();

  const [uploadProgress, setUploadProgress] = React.useState(0);
  const onUploadProgress = (x: number) => {
    setUploadProgress(x);
  };

  const handleOnClick = (file: File) => {
    const _defaultMessage = 'An error happened while saving, please try again.';
    uploadImage(file, onUploadProgress)
      .then(imageRes => {
        return updateGroup({
          variables: {
            input: {
              group: {
                id: props.groupId,
                name: props.groupName,
                icon: imageRes,
                description: props.groupDescription,
              },
            },
          },
        });
      })
      .then(res => {
        const { message, status } = idx(res, _ => _!.data!.updateGroupInfo) || {
          status: RequestStatusEnum.ERROR,
          message: _defaultMessage,
        };

        if (status === 'OK') {
          analytics.track(analyticsEvents.groups.changeSuccessful, {
            status,
            message,
          });
          setSnackbar(message, 'success');
          props.onClose();
          setFile(undefined);
          setUploadProgress(0);
        } else {
          setSnackbar(message, 'error');
          analytics.track(analyticsEvents.groups.changeFailed, {
            status,
            message,
          });
        }
      })
      .catch((error: Error) => {
        setSnackbar(error.message || _defaultMessage, 'error');
        analytics.track(analyticsEvents.groups.changeFailed, error);
      });
  };

  return (
    <Popover
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className={classes.popoverContainer}>
        <Typography variant="h5" className={classes.popoverHeaderText}>
          Add Group Image
        </Typography>
        <FileSelector onFile={file => setFile(file)} />
        <IconOrImagePreview
          groupIcon={props.groupIcon}
          file={file}
          iconIsDefault={props.iconIsDefault}
        />

        {((props.groupIcon && !props.iconIsDefault) || file) && (
          <Button
            //@ts-ignore
            onClick={() => openFileSelector()}
            className={classes.popoverSecondaryButton}
            children={'Upload new image'}
          />
        )}
        {file && (
          <Button
            onClick={() => handleOnClick(file)}
            className={classes.popoverPrimaryButton}
            children={'Add'}
          />
        )}
      </div>
      {uploadProgress === 0 ? null : <LinearProgress value={uploadProgress} />}
    </Popover>
  );
};

interface GroupImageProps {
  group: GroupFragment;
}

export const DisplayAndManageGroupImage = (props: GroupImageProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const materialTheme = useTheme();
  const largeScreenSize = useMediaQuery(materialTheme.breakpoints.up('md'));
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const viewerCanEdit = props.group.permissions.viewerCanEditGroupInfo;

  if (!viewerCanEdit) {
    return <DisplayGroupIcon groupIcon={props.group.icon} />;
  }

  return (
    <>
      <div
        className={`${classes.imageEmptyState} relative hide-child`}
        onClick={handleClick}
      >
        {props.group.usesDefaultIcon ? (
          <div className="flex items-center justify-center flex-column w3 w4-m w4-l h3 h4-m h4-l ma3 ma0-m ma0-l">
            <CameraIcon
              size={largeScreenSize ? 40 : 20}
              color={colors.gray300}
            />
            <p className="tc mb0 mt1 f7 mingl-gray300">Add Group Image</p>
          </div>
        ) : (
          <>
            <DisplayGroupIcon groupIcon={props.group.icon} />
            <div
              className={`${classes.overlay} w3 w4-m w4-l h3 h4-m h4-l child absolute top-0 bottom-0 left-0 right-0 w-100`}
            >
              <CameraIcon size={20} color={colors.light} />
              <span className="f7 nowrap">Edit Image</span>
            </div>
          </>
        )}
      </div>
      <PopoverUploadImage
        onClose={handleClose}
        id={id}
        open={open}
        anchorEl={anchorEl}
        groupIcon={props.group.icon}
        iconIsDefault={props.group.usesDefaultIcon}
        groupId={props.group.id}
        groupName={props.group.name}
        groupDescription={props.group.description || ''}
      />
    </>
  );
};
