import { QueryHookOptions, useQuery } from 'react-apollo';
import { getOperationName } from 'apollo-utilities';
import { ContactInfoDefinitionsQuery as ContactInfoDefinitionsQueryT } from '../__generated/apollogen-types';
import CONTACT_INFORMATION_DEFINITIONS from './ContactInfoDefinitionsQuery.graphql';

type DataT = ContactInfoDefinitionsQueryT;
type VariablesT = null;

export const useContactInfoDefinitionsQuery = (
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery(CONTACT_INFORMATION_DEFINITIONS, options);
};

export const getContactInfoDefinitionsQueryName = () =>
  getOperationName(CONTACT_INFORMATION_DEFINITIONS) || '';
