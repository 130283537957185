import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

// Material Design Guidelines
// https://material.io/design/color/the-color-system.html#color-theme-creation

export const spacing = [
  '0rem', // 0
  '0.25rem', // 1
  '0.5rem', // 2
  '1rem', // 3
  '2rem', // 4
  '4rem', // 5
  '8rem', // 6
  '16rem', // 7
];

export const colors = {
  //------Palette

  //---Primary
  blue900: '#002531',
  blue800: '#00384D',
  blue700: '#20485C', // primary
  blue600: '#1E5776',
  blue500: '#3683B0',
  blue400: '#4099BF',
  blue300: '#89C5E1',
  blue200: '#B8E8F9',
  blue100: '#E6F9FF',

  //---Secondary
  green900: '#084245',
  green800: '#125959',
  green700: '#19807E',
  green600: '#29A8A0',
  green500: '#44C3B6', // secondary
  green400: '#63D4BE',
  green300: '#8BE9CE',
  green200: '#BAFDE6',
  green100: '#E1FFF5',

  //---Alert
  red900: '#520100',
  red800: '#6F0606',
  red700: '#8D1111',
  red600: '#AF2928',
  red500: '#CC3D3C', // alert
  red400: '#F16667', // alertAccent
  red300: '#F48889',
  red200: '#FECBCB',
  red100: '#FFF1F1',

  //---Warning
  yellow900: '#5C3900',
  yellow800: '#9E6400',
  yellow700: '#C68906',
  yellow600: '#EBA400',
  yellow500: '#FFBF29',
  yellow400: '#FFD450', // warning
  yellow300: '#FCE497', // warningAccent
  yellow200: '#FEF2CD',
  yellow100: '#FFF9E5',

  //---Shades
  gray900: '#1A2124',
  gray800: '#303A3F', // darkGray
  gray700: '#4C585E', // midGray
  gray600: '#6E767A', // placeholder
  gray500: '#BAC4C9', // placeholderLight
  gray400: '#A6B6BE',
  gray300: '#D9E0E3',
  gray200: '#E4EAEE',
  gray100: '#E5ECF1', // gray100
  gray50: '#F2F6F8',
  light: '#FFFFFF',
  //------------------------------------------------------------------------------------------------------

  // special:
  facebook: '#3B5998',
  linkedin: '#0077B5',
  whatsApp: '#25D366',
  messenger: '#0078FF',
};

const cardRowMinWidth = '19rem';
const cardRowMaxWidth = '26rem';
const pageMinWidth = '20rem';

const mainMuiTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: colors.green500,
      light: colors.green100,
      dark: colors.green700,
      contrastText: colors.gray100,
    },
    secondary: {
      main: colors.blue300,
      light: colors.blue100,
      dark: colors.blue500,
      contrastText: colors.gray100,
    },
    error: {
      main: colors.red500,
      light: colors.red400,
      dark: colors.red700,
    },
    background: {
      default: colors.blue800,
      paper: colors.blue600,
    },
    text: {
      primary: colors.gray100,
      secondary: colors.gray500,
    },
    grey: {
      50: colors.gray50,
      100: colors.gray100,
      200: colors.gray200,
      300: colors.gray300,
      400: colors.gray400,
      500: colors.gray500,
      600: colors.gray600,
      700: colors.gray700,
      800: colors.gray800,
      900: colors.gray900,
      // Accent greys:
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    // text
  },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontFamily: [
      'Source Sans Pro',
      // system fonts:
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Helvetica',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
};

export const whiteMuiTheme: ThemeOptions = responsiveFontSizes(
  createMuiTheme({
    ...mainMuiTheme,
    palette: {
      ...mainMuiTheme.palette,

      common: {
        black: '#000',
        white: '#fff',
      },

      type: 'light',
      primary: {
        light: '#7986cb',
        main: '#3f51b5',
        dark: '#303f9f',
        contrastText: '#fff',
      },

      secondary: {
        light: colors.green100,
        main: colors.green500,
        dark: colors.green700,
        contrastText: colors.gray100,
      },

      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },

      background: {
        default: colors.light,
        paper: colors.light,
      },
      text: {
        primary: colors.gray900,
        secondary: colors.gray700,
      },

      // warning: {
      //   light: '#ffb74d',
      //   main: '#ff9800',
      //   dark: '#f57c00',
      //   contrastText: 'rgba(0, 0, 0, 0.87)',
      // },

      // info: {
      //   light: '#64b5f6',
      //   main: '#2196f3',
      //   dark: '#1976d2',
      //   contrastText: '#fff',
      // },

      // success: {
      //   light: '#81c784',
      //   main: '#4caf50',
      //   dark: '#388e3c',
      //   contrastText: 'rgba(0, 0, 0, 0.87)',
      // },

      contrastThreshold: 3,
    },
  })
);

export const muiTheme = responsiveFontSizes(createMuiTheme(mainMuiTheme));

export const widths = {
  cardRowMinWidth,
  cardRowMaxWidth,
  pageMinWidth,
};
