
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"GroupFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Group"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"icon"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"usesDefaultIcon"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewerRole"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"publicURL"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"aliases"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayName"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"permissions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"GroupPermissionsFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"settings"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"invitationSetting"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":306}};
    doc.loc.source = {"body":"#import \"./GroupPermissionsFragment.graphql\"\n\nfragment GroupFragment on Group {\n  id\n  name\n  type\n  icon\n  usesDefaultIcon\n  description\n  createdAt\n  viewerRole\n  publicURL\n  aliases {\n    id\n    displayName\n  }\n  permissions {\n    ...GroupPermissionsFragment\n  }\n  settings {\n    invitationSetting\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./GroupPermissionsFragment.graphql").definitions));


      module.exports = doc;
    
