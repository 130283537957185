import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import ReactLifecycles from '../../../components/ReactLifecycles';
import { AuthConsumer } from '../providers/AuthProvider';

export default function Register(props: RouteComponentProps) {
  return (
    <AuthConsumer
      children={_auth => (
        <ReactLifecycles
          onComponentDidMount={() => {
            if (!_auth.isAuthenticated) {
              return _auth.createAccount();
            }
            setTimeout(() => props.navigate!('/home'));
          }}
        />
      )}
    />
  );
}
