/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
}

const CameraIcon = ({ color, size }: IconProps) => (
  <svg viewBox="0 0 20 20" width={size} height={size} name="Camera">
    <path
      d="M0 6c0-1.1.9-2 2-2h3l2-2h6l2 2h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm10 10a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
      fill={color}
    />
  </svg>
);

export default CameraIcon;
