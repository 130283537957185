import * as React from 'react';
import {
  MutationFunctionOptions,
  MutationHookOptions,
  useMutation,
} from 'react-apollo';

import {
  DeclineGroupInvitation as DeclineGroupInvitationMutationT,
  DeclineGroupInvitationVariables as DeclineGroupInvitationMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';

import DECLINE_GROUP_INVITATION_MUTATION from './DeclineGroupInvitationMutation.graphql';
import idx from '../utils/idx';

type DataT = DeclineGroupInvitationMutationT;
type VariablesT = DeclineGroupInvitationMutationVariables;

// ============================================================================
// Mutation handler

type MutationOutputT = NonNullable<DataT['declineGroupInvitation']>;

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledDeclineGroupInvitationMutation = (
  _options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [declineGroupInvitation] = useMutation(
    DECLINE_GROUP_INVITATION_MUTATION,
    _options
  );

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = React.useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'DeclineGroupInvitationMutationOutput',
        message: `Failed to remove invitation.`,
        status: RequestStatusEnum.ERROR,
        deletedId: null,
        groupId: idx(options, _ => _.variables!.input.id) || '',
      };

      try {
        const res = await declineGroupInvitation(options);
        return (
          idx(res, _ => _!.data!.declineGroupInvitation) || fallbackResponse
        );
      } catch (error) {
        return fallbackResponse;
      }
    },
    [declineGroupInvitation]
  );

  return handledMutationFn;
};
