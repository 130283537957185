/* tslint:disable:max-line-length */
import React from 'react';

interface IconProps {
  color: string;
  size: number | string;
  className?: string;
  style?: React.CSSProperties;
}

const GroupsIcon = ({ size, color, className, style }: IconProps) => (
  <svg
    width={size}
    height={size}
    className={className}
    style={style}
    fill="none"
    viewBox="0 0 30 30"
  >
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M.644 8.848l13.916 7.588a1.103 1.103 0 00.674.058 2.77 2.77 0 00.205-.058L29.53 8.848c.332-.176.488-.46.468-.85a.922.922 0 00-.131-.483.873.873 0 00-.367-.337L15.585.118a.931.931 0 00-.85 0L.674 7.177a.974.974 0 00-.38.337.853.853 0 00-.147.483c0 .39.166.674.498.85zm14.502-6.856L27.04 8.057 14.999 14.53 3.105 8.057l12.04-6.065zm14.18 12.07l-2.784-1.406-1.992 1.084 2.344 1.201-12.041 6.475-11.895-6.475 2.49-1.26-1.992-1.083-2.959 1.464c-.332.176-.502.455-.512.836-.01.38.151.659.483.834l13.916 7.588c.156.078.313.117.469.117.078 0 .151-.01.22-.029.068-.02.141-.049.22-.088l14.062-7.588c.332-.175.498-.454.498-.834 0-.381-.176-.66-.527-.835zm0 6.563l-2.637-1.29-1.992 1.085 2.197 1.084-12.041 6.474-11.895-6.474 2.315-1.055-1.992-1.084-2.784 1.26c-.332.176-.502.454-.512.835-.01.38.151.66.483.835l13.916 7.588c.059.02.112.039.161.058a.844.844 0 00.308.059c.078 0 .151-.01.22-.03.068-.019.141-.048.22-.087l14.062-7.588c.332-.176.498-.454.498-.835 0-.38-.176-.66-.527-.835z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GroupsIcon;
