import * as React from 'react';

import analytics, { analyticsEvents } from '../../../utils/analytics';
import { ApolloError } from 'apollo-client';
import { captureException } from '../../../utils/raven';
import { Typography } from '@material-ui/core';

interface UserNotFoundProps {
  error?: ApolloError;
}

export default class UserNotFound extends React.Component<
  UserNotFoundProps,
  {}
> {
  componentDidMount() {
    analytics.track(analyticsEvents.pages.pageNotFound);
    if (this.props.error instanceof Error) {
      captureException(this.props.error, {
        extra: {
          event: 'Error during query for user',
        },
      });
    }
  }

  render(): JSX.Element | null {
    return (
      <div className="tc mt5 mb3">
        <Typography variant="h3" component="h1">
          Sorry, we could not find this user
        </Typography>
      </div>
    );
  }
}
