import * as React from 'react';

interface LifecycleProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onComponentDidMount?: () => any | Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onComponentWillUnmount?: () => any | Promise<any>;
  render?: () => JSX.Element | null;
  children?: () => JSX.Element | null;
}

export class Lifecycle extends React.Component<LifecycleProps, {}> {
  componentDidMount() {
    if (this.props.onComponentDidMount) {
      this.props.onComponentDidMount();
    }
  }

  componentWillUnmount() {
    if (this.props.onComponentWillUnmount) {
      this.props.onComponentWillUnmount();
    }
  }

  render(): JSX.Element | null {
    if (typeof this.props.children === 'function') {
      return this.props.children();
    }
    if (typeof this.props.render === 'function') {
      return this.props.render();
    }
    return null;
  }
}

export default Lifecycle;
