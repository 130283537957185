import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogClassKey } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import { colors } from '../modules/ui/theme';

const useStyles = makeStyles({
  paper: {
    backgroundColor: colors.blue900,
  },
});

interface AlertDialogProps {
  open: boolean;
  handleClose: () => void;
  handleAction: () => void;
  title: string;
  contentText?: string;
  confirmText?: string;
  cancelText?: string;
  classesPaper?: Partial<Record<DialogClassKey, string>> | undefined;
}

const AlertDialog = (props: AlertDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={
        props.classesPaper ? props.classesPaper : { paper: classes.paper }
      }
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      {props.contentText && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.contentText}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={props.handleClose} color="default">
          {props.cancelText || 'No'}
        </Button>
        <Button onClick={props.handleAction} color="default" autoFocus>
          {props.confirmText || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
