import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
} from 'react-apollo';

import {
  UpdateGroupInfo,
  UpdateGroupInfoVariables,
} from '../__generated/apollogen-types';
import UPDATE_GROUP from './UpdateGroupMutation.graphql';

type DataT = UpdateGroupInfo;
type VariablesT = UpdateGroupInfoVariables;

export type UpdateGroupMutationFnT = MutationFunction<DataT, VariablesT>;
export type UpdateGroupInfoT = DataT;
export type UpdateGroupInfoVariablesT = VariablesT;
export type UpdateGroupInfoMutationInputT = UpdateGroupInfoVariablesT['input'];

export const useUpdateGroupMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(UPDATE_GROUP, { ...options });
};
