import * as React from 'react';
import ReactModal from 'react-modal';
import { default as ScrollLock } from 'react-scrolllock';

import { appRootId } from '../../../config';

export interface ModalChildProps {
  requestClose: () => void;
  allowUserTriggeredClose: boolean;
  style?: React.StyleHTMLAttributes<HTMLDivElement>['style'];
}

export type CloseHandlerArgs = ModalChildProps & {
  userTriggered: boolean;
  closeTimeout: number;
};

export interface MinglModalProps {
  isOpen: boolean;
  style?: {
    content?: React.StyleHTMLAttributes<HTMLDivElement>['style'];
    overlay?: React.StyleHTMLAttributes<HTMLDivElement>['style'];
  };
  allowUserTriggeredClose?: boolean;
  onRequestClose: (args: CloseHandlerArgs) => void;
  children: (props: ModalChildProps) => React.ReactNode;
  className: {
    base: string;
    afterOpen: string;
    beforeClose: string;
  };
  overlayClassName: {
    base: string;
    afterOpen: string;
    beforeClose: string;
  };
  closeTimeOut?: number;
}

const CLOSE_TIMEOUT_MS_FALLBACK = 300;

export class MinglModal extends React.Component<MinglModalProps, {}> {
  getModalChildProps = (): ModalChildProps => {
    return {
      style: { pointerEvents: 'auto' },
      requestClose: this.handleRequestClose,
      allowUserTriggeredClose: this.props.allowUserTriggeredClose || false,
    };
  };

  handleRequestClose = (event?: MouseEvent | KeyboardEvent) => {
    this.props.onRequestClose({
      ...this.getModalChildProps(),
      userTriggered: !!event,
      closeTimeout: this.props.closeTimeOut || CLOSE_TIMEOUT_MS_FALLBACK,
    });
  };

  modalElementRef?: HTMLElement;

  setOverlayRef = (el?: HTMLElement | null) => {
    this.modalElementRef = el || undefined;
    this.forceUpdate();
  };

  render(): JSX.Element | null {
    const {
      children,
      allowUserTriggeredClose = true,
      isOpen,
      style = {},
      className = '',
    } = this.props;

    return (
      <ReactModal
        style={{
          overlay: style.overlay,
          content: style.content,
        }}
        overlayRef={this.setOverlayRef}
        isOpen={isOpen}
        className={this.props.className}
        overlayClassName={this.props.overlayClassName}
        appElement={document.getElementById(appRootId) || undefined}
        closeTimeoutMS={this.props.closeTimeOut || CLOSE_TIMEOUT_MS_FALLBACK}
        shouldCloseOnEsc={allowUserTriggeredClose}
        shouldCloseOnOverlayClick={allowUserTriggeredClose}
        onRequestClose={this.handleRequestClose}
        children={
          <React.Fragment>
            {children(this.getModalChildProps())}
            {this.modalElementRef && (
              <ScrollLock
                key="scrollLock"
                touchScrollTarget={this.modalElementRef}
              />
            )}
          </React.Fragment>
        }
      />
    );
  }
}

export default MinglModal;
