import * as React from 'react';
import {
  Query,
  QueryComponentOptions,
  QueryHookOptions,
  useQuery,
} from 'react-apollo';
import idx from '../utils/idx';

import {
  GroupItemsQuery as GroupItemsQueryT,
  GroupItemsQueryVariables as GroupItemsQueryVariablesT,
  GroupItemsQuery_groupItems_edges,
} from '../__generated/apollogen-types';
import GROUP_ITEMS_QUERY from './GroupItemsQuery.graphql';
import { getOperationName } from 'apollo-link';

type DataT = GroupItemsQueryT;
type VariablesT = GroupItemsQueryVariablesT;

type PropsT = Omit<QueryComponentOptions<DataT, VariablesT>, 'query'>;

type PageInfoT = GroupItemsQueryT['groupItems']['pageInfo'];

interface FetchedMoreT {
  fetchMoreResult?: GroupItemsQueryT;
}
const defaultPageInfo: PageInfoT = {
  __typename: 'PageInfo',
  hasNextPage: false,
  hasPreviousPage: false,
};

function updateQuery(
  prevResult: GroupItemsQueryT,
  fetchedMore: FetchedMoreT
): GroupItemsQueryT {
  const { fetchMoreResult } = fetchedMore;

  const existingEdges = idx(prevResult, _ => _.groupItems.edges) || [];
  const existingPageInfo =
    idx(prevResult, _ => _.groupItems.pageInfo) || defaultPageInfo;
  const newEdges = idx(fetchMoreResult, _ => _!.groupItems.edges) || [];
  const newPageInfo =
    idx(fetchMoreResult, _ => _!.groupItems.pageInfo) || defaultPageInfo;

  const pageInfo: PageInfoT = {
    ...newPageInfo,
    hasPreviousPage: existingPageInfo.hasPreviousPage,
  };

  const edgeMap = new Map<string, GroupItemsQuery_groupItems_edges>();
  existingEdges.forEach(edge => edgeMap.set(edge.cursor, edge));
  newEdges.forEach(edge => edgeMap.set(edge.cursor, edge));

  const mergedResult: GroupItemsQueryT = {
    ...prevResult,
    groupItems: {
      ...prevResult.groupItems,
      edges: Array.from(edgeMap.values()),
      pageInfo,
    },
  };

  return mergedResult;
}

export class GroupItemsQuery extends React.Component<PropsT> {
  public static query = GROUP_ITEMS_QUERY;
  public static updateQuery = updateQuery;

  render() {
    return (
      <Query<DataT, VariablesT>
        {...this.props}
        query={GROUP_ITEMS_QUERY}
        variables={this.props.variables}
        children={this.props.children}
      />
    );
  }
}

export const useGroupItemsQuery = (
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery(GROUP_ITEMS_QUERY, options);
};

export const getGroupItemsQueryName = () =>
  getOperationName(GROUP_ITEMS_QUERY) || '';
