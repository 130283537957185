/* tslint:disable:max-line-length */
import * as React from 'react';

interface IconProps {
  color: string;
  size: number | string;
  className?: string;
  style?: object;
  strokeWidth?: number;
}

export const ChevronDownIcon = ({
  color,
  size,
  className,
  style,
  strokeWidth = 3,
}: IconProps) => (
  <svg viewBox="0 0 18 11" height={size} className={className} style={style}>
    <path
      d="M16.071 2L9 9.071 1.929 2"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      name="chevronDown"
    />
  </svg>
);

export const ChevronLeftIcon = ({
  color,
  size,
  className,
  style,
  strokeWidth = 3,
}: IconProps) => (
  <svg viewBox="0 0 11 18" height={size} className={className} style={style}>
    <path
      d="M9 1.929L1.929 9 9 16.071"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      name="chevronLeft"
    />
  </svg>
);
