import * as yup from 'yup';
import { CardPrivacyLevel } from '../../__generated/apollogen-types';

export const validateCardPrivacyLevel = yup
  .string()
  .oneOf([
    CardPrivacyLevel.PUBLIC,
    CardPrivacyLevel.LINK_ONLY,
    CardPrivacyLevel.HIDDEN,
  ])
  .required();

export const validateImageUrl = yup.string().url();

const generalUrlErrorMessage =
  'Invalid url, remember to include the protocol https:// or http://';

export const validateWebsiteUrl = yup
  .string()
  .ensure()
  .trim()
  .url(generalUrlErrorMessage)
  .default('');

export const cardLinkObject = yup
  .object({
    label: yup
      .string()
      .oneOf([
        'facebook',
        'instagram',
        'linkedin',
        'twitter',
        'website',
        'document',
      ])
      .required(),
    url: validateWebsiteUrl,
  })
  .noUnknown(true);

export const validateCardLinks = yup.array(cardLinkObject).default([]);

const hasInternationalPrefix = (value: string) => /^\+/.test(value);

export const validatePhoneNumber = yup.string().default('');

export const validateName = yup
  .string()
  .min(1, 'Please enter your name')
  .max(200, 'Your name seems too long')
  .default('');

export const validateEmail = yup
  .string()
  .email('Please Check your email')
  .default('');
