import * as React from 'react';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  style: React.CSSProperties;
  value: string;
}

export default function Input(props: InputProps) {
  return <input className="input-reset w-100 pt2 mb2 lh-copy" {...props} />;
}
