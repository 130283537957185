import * as React from 'react';
import Rect from '@reach/rect';

import AspectRatio from '../../ui/AspectRatio';
import ImageInputEditor from '../../ui/ImageInputEditor';
import { UploadImageFn } from '../../forms/formTypes';

interface CardImageFieldProps {
  imageURL?: string;
  onImageChange: UploadImageFn;
  onPositionChange: (pos: { x: string; y: string }) => void;
}
export function CardImageField(props: CardImageFieldProps) {
  return (
    <Rect
      children={({ rect, ref }) => (
        <AspectRatio ref={ref} ratio="17x20">
          {rect && (
            <ImageInputEditor
              height={rect.height}
              width={rect.width}
              value={props.imageURL || null}
              onChange={props.onPositionChange}
              onImageChange={props.onImageChange}
            />
          )}
        </AspectRatio>
      )}
    />
  );
}
