import * as React from 'react';

export function TextEllipsis({
  text,
  onClick,
  textClassName,
  buttonClassName,
}: {
  text: string;
  onClick?: () => void;
  textClassName?: string;
  buttonClassName?: string;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const BIO_CHAR_LIMIT = 76;

  if (text.length <= BIO_CHAR_LIMIT) {
    return (
      <span
        onClick={onClick}
        className={textClassName}
        children={text}
        style={{ whiteSpace: 'pre-wrap' }}
      />
    );
  } else {
    const trimmedText = text.slice(0, BIO_CHAR_LIMIT).trimRight() + '...';
    return (
      <>
        <span
          onClick={onClick}
          className={textClassName}
          children={expanded ? text : trimmedText}
          style={{ whiteSpace: 'pre-wrap' }}
        />
        <span
          children={expanded ? ' Show less' : 'Show more'}
          onClick={() => setExpanded(!expanded)}
          className={`${buttonClassName} ${!buttonClassName &&
            'blue'} pointer fw6 `}
        />
      </>
    );
  }
}
