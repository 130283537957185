import { useQuery, QueryHookOptions } from 'react-apollo';

import {
  GroupResourcesQuery as GroupResourcesQueryT,
  GroupResourcesQueryVariables,
} from '../__generated/apollogen-types';
import GROUP_RESOURCES_QUERY from './GroupResourcesQuery.graphql';
import { getOperationName } from 'apollo-utilities';

type DataT = GroupResourcesQueryT;
type VariablesT = GroupResourcesQueryVariables;

export const getGroupResourcesQueryName = () =>
  getOperationName(GROUP_RESOURCES_QUERY) || '';

export const useGroupResources = (
  variables: VariablesT,
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery<DataT, VariablesT>(GROUP_RESOURCES_QUERY, {
    ...options,
    variables,
  });
};
