import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import idx from '../../../utils/idx';
import DisplayAndManageGroupResources, {
  DisplayAndManageModesT,
} from '../components/DisplayAndManageGroupResources';
import CreateResourceForm from './CreateResourceForm';
import { GroupFragment } from '../../../__generated/apollogen-types';
import { useGroupResources } from '../../../queries/GroupResourcesQuery';
import { colors } from '../../../modules/ui/theme';
import LinkIcon from '../../../components/icons/LinkIcon';

export default function ResourcesSection({
  group,
  minimizedView,
  setMinimizedView,
}: {
  group: GroupFragment;
  minimizedView: boolean;
  setMinimizedView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const groupId = group.id;
  const {
    data: resourcesData,
    loading: loadingResources,
    refetch: refetchResources,
  } = useGroupResources({ input: { groupId } });
  const groupResources =
    idx(resourcesData, _ => _!.groupResources!.edges.map(edge => edge.node)) ||
    [];

  const [resourceMode, setResourceMode] = React.useState<
    DisplayAndManageModesT
  >('display');

  const loading = loadingResources && !resourcesData;

  return (
    <div className="mt3">
      <div className="flex justify-between items-baseline mb2">
        <div className="flex items-baseline white">
          <Typography
            variant="h5"
            color="textPrimary"
            children="Links by organisers"
            className="pr2"
          />
          <LinkIcon size={16} color={colors.light} />
          <p className="ma0 pl1" children={groupResources.length} />
        </div>
        {groupResources.length > 3 && resourceMode !== 'edit' && (
          <Typography
            variant="body1"
            color="textPrimary"
            className="pl3 pointer"
            children={minimizedView ? `See All` : 'Show less'}
            onClick={() => setMinimizedView(!minimizedView)}
          />
        )}
      </div>

      {loading && <CircularProgress />}

      {!loading && (
        <DisplayAndManageGroupResources
          groupId={group.id}
          mode={resourceMode}
          resources={groupResources}
          refetchResources={refetchResources}
          minimizedView={minimizedView}
          setMinimizedView={setMinimizedView}
          setResourceMode={setResourceMode}
          viewerCanEditGroupInfo={
            group.permissions.viewerCanEditGroupInfo || false
          }
        />
      )}

      {resourceMode === 'create' && (
        <div className="flex-grow mt3">
          <Typography variant="h6" color="textPrimary">
            Add a new link
          </Typography>
          <CreateResourceForm
            key={groupResources.length}
            groupId={groupId}
            onCancel={() => setResourceMode('display')}
          />
        </div>
      )}
    </div>
  );
}
