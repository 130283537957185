import urlParse from 'url-parse';
import queryString from 'query-string';

const minglHostnames = [
  'min.gl',
  'mingl.no',
  'mingl.app',
  'dev.minglapp.io',
  'develop.mingl.no',
];

export function isMinglHost(hostname: string): boolean {
  return (
    minglHostnames.indexOf(hostname) !== -1 ||
    (process.env.NODE_ENV !== 'production' && hostname === 'localhost')
  );
}

export function getPathFromMinglUri(data: string): string {
  const { hostname, pathname } = urlParse(data, {});

  if (isMinglHost(hostname)) {
    return pathname;
  }

  return '';
}

export function updateQueryString(
  href: string,
  newSearchParams: { [key: string]: string | number | null | undefined },
  stringifyOptions?: queryString.StringifyOptions
) {
  const { url, query } = queryString.parseUrl(href);
  const newSearch = queryString.stringify(
    { ...query, ...newSearchParams },
    stringifyOptions
  );

  return {
    url,
    search: newSearch,
    href: url + `?${newSearch}`,
  };
}

// Docs: https://firebase.google.com/docs/dynamic-links/create-manually
interface DynamicLinkConfig {
  link: string;
  source?: string;
  medium?: string;
  dynamicLinkDomain: 'mingl.page.link';
}

export function makeDynamicLink({
  link,
  source,
  medium,
  dynamicLinkDomain,
}: DynamicLinkConfig) {
  // Android params
  const androidParams = {
    apn: 'com.mingl',
    amv: 456, // Android minimum version
    // afl: '', // Fallback url if not intalled, defaults to play store

    // analytics
    utm_source: source,
    utm_medium: medium,
    // utm_campaign: '',
    // utm_term: '',
    // utm_content: '',
    // gclid: '',
  };

  // iOS params
  const iOSParams = {
    ibi: 'com.minglno.MinglApp',
    isi: '1276639898', // AppleID
    // imv: '2.7.4', // iOS minimum version, handle manually

    // analytics
    // at: '', // Afilliate token
    ct: source, // campaign token/name
    mt: '8', // MediaTypes (https://stackoverflow.com/a/6774015)
    pt: '118742182', // Privoder Token (Provider ID)
  };

  const firbaseParams = {
    efr: '1', // skip app preview page
    link,
  };

  const queryParams = queryString.stringify({
    ...androidParams,
    ...iOSParams,
    ...firbaseParams,
  });

  return `https://${dynamicLinkDomain}/?${queryParams}`;
}
