import * as React from 'react';
import { Link } from '@reach/router';

export default function Footer() {
  return (
    <footer
      className="w-100 tc f6 ph4 flex-initial white"
      style={{
        backgroundColor: 'rgb(21, 20, 20)',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%230f0f0f' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div
        className="flex justify-between flex-column flex-row-ns items-center items-end pb5 pb4-ns pt5 center mw8 "
        style={{ color: '#b5b5b5de' }}
      >
        <div className="flex flex-column h3 justify-around f6 f7-ns mb4">
          <Link
            className="link color-inherit underline-hover mb4 mv0-ns"
            to="/app/privacypolicy"
            children="Privacy Policy"
          />
          <Link
            className="link color-inherit underline-hover mv0-ns"
            to="/app/termsandconditions"
            children="Terms &amp; Conditions"
          />
        </div>
        <div className="mw5 flex flex-column h3 justify-around mb4 f6 ">
          <a
            style={{ padding: '1px 0px' }}
            className="underline-hover color-inherit no-underline pointer mb4 mv0-ns"
            href={`mailto:contact@mingl.no`}
          >
            contact@mingl.no
          </a>
          <a
            style={{ padding: '1px 0px' }}
            className="underline-hover color-inherit no-underline pointer mv0-ns"
            href="http://maps.google.com/?q=MinglHQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Valkyriegata 15 A, 0366 Oslo
          </a>
        </div>
        <div className="flex flex-column h3 justify-around f6 f7-ns mb0 mb4-ns ">
          <div className="flex justify-center items-center mb4 mv0-ns">
            <p className="ma0">Norway </p>
            <div className="pl2" style={{ width: '1.7rem' }}>
              <div
                className="aspect-ratio--4x3"
                style={{
                  backgroundImage: `url("${require('../../../assets/img/NorwegianFlag.png')}"`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
            </div>
          </div>
          <div className="mv0-ns">&copy; 2018 Mingl AS</div>
        </div>
      </div>
    </footer>
  );
}
