import * as React from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';

import { NavComponents } from './Navigation/HeaderNavigationComponents';
import Loader from '../components/Loader';
import { NodeQuery } from '../queries/GetNode';
import ClaimAliasContainer from './card/containers/ClaimAlias';
import PageContainer from '../containers/PageContainer';
import idx from '../utils/idx';
import UserNotFound from './app/components/UserNotFound';
import { useQueryParams } from './Common/useQueryParams';
import MinglCardPage, {
  preload as preloadMinglCardPageDeps,
} from './card/containers/MinglCardPage';
import { makeDynamicLink } from '../utils/minglUrlUtil';

type DisplayNodeRouteProps = RouteComponentProps<{
  id: string;
}> & {
  returnPath: string;
};

export function NodeRouteViaRouteParam(props: DisplayNodeRouteProps) {
  const { uri } = props;
  if (!uri) {
    return (
      <PageContainer
        mobileNavItem={props.returnPath}
        mobileActionItems={<NavComponents.GetTheApp />}
      >
        <UserNotFound />
      </PageContainer>
    );
  }

  return <DisplayNode nodeUri={uri} returnPath={props.returnPath} />;
}

export function NodeRouteViaQueryParam(props: DisplayNodeRouteProps) {
  const { id = '' } = useQueryParams(['id'], window.location.search);
  if (!id) {
    return (
      <PageContainer
        redirectUnauthenticated={false}
        mobileNavItem={props.returnPath}
        mobileActionItems={<NavComponents.GetTheApp />}
      >
        <UserNotFound />
      </PageContainer>
    );
  }

  return <DisplayNode nodeUri={id} returnPath={props.returnPath} />;
}

function DisplayNode(props: { returnPath: string; nodeUri: string }) {
  const { nodeUri } = props;

  React.useEffect(() => {
    preloadMinglCardPageDeps();
  });

  const appLink = makeDynamicLink({
    medium: 'webCard',
    source: 'webCard',
    dynamicLinkDomain: 'mingl.page.link',
    link: `https://mingl.no${nodeUri}`,
  });

  const pageHeaderProps = {
    mobileNavItem: props.returnPath,
    mobileActionItems: <NavComponents.GetTheApp href={appLink} />,
  };

  return (
    <NodeQuery
      key={nodeUri}
      variables={{ id: nodeUri }}
      children={queryResult => {
        const node = idx(queryResult, _ => _.data!.node);
        if (queryResult.loading) {
          return (
            <PageContainer {...pageHeaderProps} redirectUnauthenticated={false}>
              <Loader />
            </PageContainer>
          );
        }
        if (queryResult.error) {
          return (
            <PageContainer {...pageHeaderProps}>
              <UserNotFound error={queryResult.error} />
            </PageContainer>
          );
        }
        if (node && node.__typename === 'Card') {
          return (
            <MinglCardPage
              card={node}
              cardUri={nodeUri}
              returnPath={props.returnPath}
            />
          );
        }

        if (node && node.__typename === 'ClaimableAlias') {
          return (
            <PageContainer
              mobileNavItem={pageHeaderProps.mobileNavItem}
              redirectUnauthenticated={false}
              mobileActionItems={
                <NavComponents.GetTheApp
                  href={appLink}
                  analyticsData={{
                    nodeId: node.id,
                    event: 'Referral ClaimableAlias',
                  }}
                />
              }
              mainStyle={{ justifyContent: 'center' }}
            >
              <ClaimAliasContainer alias={node.id} />
            </PageContainer>
          );
        }

        if (node && node.__typename === 'Group') {
          return <Redirect to={`/groups/${node.id}`} />;
        }

        return (
          <PageContainer {...pageHeaderProps}>
            <UserNotFound />
          </PageContainer>
        );
      }}
    />
  );
}
