/* tslint:disable:max-line-length */
import React from 'react';

interface IconProps {
  color: string;
  size: number | string;
  className?: string;
  style?: React.CSSProperties;
}

const MenuIcon = ({ size, color, className, style }: IconProps) => (
  <svg
    width={size}
    height={size}
    className={className}
    style={style}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 12h18M3 6h18M3 18h18" />
  </svg>
);

export default MenuIcon;
