import * as React from 'react';
import idx from '../utils/idx';
import { MutationOptions } from 'apollo-client';
import { useApolloClient } from 'react-apollo';

import {
  UpdateContactInfoMutation as DataT,
  UpdateContactInfoMutationVariables as VariablesT,
  RequestStatusEnum,
  UpdateContactInfoMutationInput,
  UpdateContactInfoMutation_updateContactInfo,
} from '../__generated/apollogen-types';

import Update_CONTACTS_INFO from './UpdateContactInfoMutation.graphql';
import analytics, { analyticsEvents } from '../utils/analytics';

export type UpdateContactInfoFnT = (
  input: UpdateContactInfoMutationInput
) => Promise<UpdateContactInfoMutation_updateContactInfo>;

type UpdateContactOptionsT = Omit<
  MutationOptions<DataT, VariablesT>,
  'mutation'
>;

export const useUpdateContactInfoMutation = (
  opts?: UpdateContactOptionsT
): UpdateContactInfoFnT => {
  const client = useApolloClient();

  return input =>
    client
      .mutate<DataT, VariablesT>({
        ...opts,
        mutation: Update_CONTACTS_INFO,
        variables: { input },
      })
      .then(res => {
        const response = idx(res, _ => _!.data!.updateContactInfo);
        const updateResponse = response as DataT['updateContactInfo'];
        if (!updateResponse) {
          throw Error('Backend did not respond correctly');
        }

        return updateResponse;
      })
      .catch((err: Error) => {
        analytics.track(analyticsEvents.contactInformation.createFailed, {
          err,
          message: err.message,
        });

        return {
          __typename: 'UpdateContactInfoMutationOutput' as 'UpdateContactInfoMutationOutput',
          status: RequestStatusEnum.ERROR,
          message: 'Failed to update info',
          validation: [],
        };
      });
};
