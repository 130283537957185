import * as React from 'react';
import idx from '../utils/idx';
import { MutationOptions } from 'apollo-client';
import { useApolloClient } from 'react-apollo';

import {
  DeleteContactInfoMutation as DataT,
  DeleteContactInfoMutationVariables as VariablesT,
  RequestStatusEnum,
  DeleteContactInfoMutationInput,
  DeleteContactInfoMutation_deleteContactInfo,
} from '../__generated/apollogen-types';

import DELETE_CONTACT_INFO from './DeleteContactInfoMutation.graphql';
import analytics, { analyticsEvents } from '../utils/analytics';

export type DeleteContactInfoFnT = (
  input: DeleteContactInfoMutationInput
) => Promise<DeleteContactInfoMutation_deleteContactInfo>;

type DeleteContactOptionsT = Omit<
  MutationOptions<DataT, VariablesT>,
  'mutation'
>;

export const useDeleteContactInfoMutation = (
  opts?: DeleteContactOptionsT
): DeleteContactInfoFnT => {
  const client = useApolloClient();

  return input =>
    client
      .mutate<DataT, VariablesT>({
        ...opts,
        mutation: DELETE_CONTACT_INFO,
        variables: { input },
      })
      .then(res => {
        const response = idx(res, _ => _!.data!.deleteContactInfo);
        const createResponse = response as DataT['deleteContactInfo'];
        if (!createResponse) {
          throw Error('Backend did not respond correctly');
        }

        return createResponse;
      })
      .catch((err: Error) => {
        analytics.track(analyticsEvents.contactInformation.deleteFailed, {
          err,
          message: err.message,
        });

        return {
          __typename: 'DeleteContactInfoMutationOutput' as 'DeleteContactInfoMutationOutput',
          status: RequestStatusEnum.ERROR,
          message: 'Failed to create info',
          validation: [],
        };
      });
};
