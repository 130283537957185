/* tslint:disable:max-line-length */
import React from 'react';
interface IconProps {
  color: string;
  size: number | string;
  className?: string;
  style?: React.CSSProperties;
}
const ChatIcon = ({ size, color, className, style }: IconProps) => (
  <svg
    width={size}
    height={size}
    className={className}
    style={style}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      d="M25.5 4h-19A3.51 3.51 0 003 7.5v12A3.51 3.51 0 006.5 23H9v5l5.857-4.884a.5.5 0 01.321-.116H25.5a3.51 3.51 0 003.5-3.5v-12A3.51 3.51 0 0025.5 4v0z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M10 15.5a2 2 0 100-4 2 2 0 000 4zM16 15.5a2 2 0 100-4 2 2 0 000 4zM22 15.5a2 2 0 100-4 2 2 0 000 4z"
      fill={color}
    />
  </svg>
);
export default ChatIcon;
