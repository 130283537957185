import * as React from 'react';
import {
  Mutation,
  MutationFunction,
  MutationComponentOptions,
  MutationHookOptions,
  useMutation,
} from 'react-apollo';
import {
  addContacts as addContactsMutation,
  addContactsVariables,
} from '../__generated/apollogen-types';

import ADD_CONTACTS_MUTATION from './AddContactsMutation.graphql';

type AddContactsMutationOpts = MutationComponentOptions<
  addContactsMutation,
  addContactsVariables
>;

export type AddContactsMutationFn = MutationFunction<
  addContactsMutation,
  addContactsVariables
>;

export const AddContactsMutation = (props: {
  children: AddContactsMutationOpts['children'];
}) => (
  <Mutation<addContactsMutation, addContactsVariables>
    {...props}
    mutation={ADD_CONTACTS_MUTATION}
  />
);

export const useAddContactsMutation = (
  options?: MutationHookOptions<addContactsMutation, addContactsVariables>
) => {
  return useMutation(ADD_CONTACTS_MUTATION, {
    ...options,
  });
};
