import { useState, useMemo } from 'react';

type PrimitiveValuesT = string | number | boolean | undefined | null;
type ErrorValuesT = string | undefined | null;

type InputRecordT = Record<string | number, PrimitiveValuesT>;
type ErrorRecord<T> = { [P in keyof T]: ErrorValuesT };

type ValidationErrorT<T> = T extends PrimitiveValuesT
  ? ErrorValuesT
  : ErrorRecord<T>;

interface InputStateOptionsT<T> {
  validate?: (value: T) => ValidationErrorT<T>;
}

interface ValidationStateT<T = string> {
  error?: ValidationErrorT<T>;
  value: T;
  touched: boolean;
  hasFocus: boolean;
}

interface ValidationObjT<T> extends ValidationStateT<T> {
  setValue: (value: T) => void;
  setError: (validation: ValidationErrorT<T>) => void;
  onFocus: () => void;
  onBlur: () => void;
  showError: boolean;
}

export default function useInputState<
  T extends PrimitiveValuesT | InputRecordT
>(
  initialValue: T | (() => T),
  opts: InputStateOptionsT<T> = {}
): ValidationObjT<T> {
  const [validationState, setValidationState] = useState<ValidationStateT<T>>(
    () => {
      const value =
        typeof initialValue === 'function' ? initialValue() : initialValue;
      const error = opts.validate ? opts.validate(value) : undefined;
      return { value, error, touched: false, hasFocus: false };
    }
  );

  return useMemo(
    () => ({
      ...validationState,
      showError: !validationState.hasFocus && validationState.touched,

      setValue: (value: T) => {
        setValidationState({
          ...validationState,
          error: opts.validate ? opts.validate(value) : validationState.error,
          value,
          touched: true,
        });
      },

      onFocus: () => {
        setValidationState({ ...validationState, hasFocus: true });
      },

      onBlur: () => {
        setValidationState({ ...validationState, hasFocus: false });
      },

      // manually set error message
      setError: error => {
        setValidationState({ ...validationState, error });
      },
    }),
    [validationState, opts]
  );
}
