import * as React from 'react';

const defaultUrl =
  'https://res.cloudinary.com/mingl/image/upload/v1572357597/DefaultImgPreview.png';

interface ResourceAvatarProps {
  imgUrl: string;
  className?: string;
}

export default function ResourceAvatar(props: ResourceAvatarProps) {
  const [resourceUrl, setResourceUrl] = React.useState(
    props.imgUrl || defaultUrl
  );
  return (
    <img
      src={resourceUrl}
      className={`w-100 h-100 ${props.className}`}
      style={{ objectFit: 'cover' }}
      onError={e => {
        setResourceUrl(defaultUrl);
      }}
    />
  );
}
