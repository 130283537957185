import * as React from 'react';
import Input from '../Input';
import { FormLabel } from './FormLabel';
import { FormError } from './FormError';

interface FormInputProps {
  name: string;
  value: string;
  type?: 'text' | 'password';
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  layout?: 'row' | 'column';
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  color?: string;
  placeholder?: string;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  labelContainerStyle?: string;
}

export default function FormInput({
  name,
  type = 'text',
  value,
  onChange,
  onBlur,
  onFocus,
  label,
  layout = 'row',
  touched,
  error,
  color,
  placeholder,
  disabled = false,
  inputStyle = {},
  labelStyle = {},
  labelContainerStyle,
}: FormInputProps) {
  return (
    <div
      className={`flex ${
        layout === 'column' ? 'flex-column' : 'flex-row items-center'
      }`}
    >
      {label && (
        <div
          className={`flex-none mb1 ` + labelContainerStyle}
          style={{
            flexBasis: layout === 'column' ? 'auto' : '90px',
          }}
        >
          <FormLabel
            style={labelStyle}
            htmlFor={name}
            children={label}
            hasError={!!(error && touched)}
            color={color}
          />
        </div>
      )}

      <div className="flex-auto">
        <Input
          type={type}
          id={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          className="fw2 w-100"
          placeholder={placeholder}
          aria-invalid={!!error}
          style={{
            backgroundColor: 'transparent',
            color: color ? color : 'white',
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: `1px solid ${
              error && touched ? '#f16667' : color || 'white'
            }`,
            borderLeft: 'none',
            ...inputStyle,
          }}
        />

        <FormError hideError={!touched} error={error} />
      </div>
    </div>
  );
}
