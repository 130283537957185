import { QueryHookOptions, useQuery } from 'react-apollo';
import { getOperationName } from 'apollo-link';

import { GroupInvitesForViewer as GroupInvitesForViewerQueryT } from '../__generated/apollogen-types';
import GROUP_INVITES_FOR_VIEWER_QUERY from './GroupInvitesForViewer.graphql';

type DataT = GroupInvitesForViewerQueryT;
type VariablesT = null;

export const useGroupInvitesForViewerQuery = (
  options?: QueryHookOptions<DataT, VariablesT>
) => {
  return useQuery(GROUP_INVITES_FOR_VIEWER_QUERY, options);
};

export const getGroupInvitesForViewerQueryName = () =>
  getOperationName(GROUP_INVITES_FOR_VIEWER_QUERY) || '';
