import * as React from 'react';

import { CardRow, CardRowStyles, CardRowProps, defaultStyles } from './CardRow';
import CircularButton from '../../ui/CircularButton';

interface CardRowWithButtonProps extends CardRowProps {
  onButtonClick: (cardId: string) => void;
  buttonContent: React.ReactNode;
  buttonBg?: 'bg-mingl-light-blue' | 'bg-mingl-green';
}

const buttonStyle = {
  height: defaultStyles.image.height,
  width: defaultStyles.image.width,
};

export class CardRowWithButton extends React.Component<CardRowWithButtonProps> {
  constructor(props: CardRowWithButtonProps) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    this.props.onButtonClick(this.props.card.id);
  }

  render(): JSX.Element | null {
    const buttonBg = this.props.buttonBg || 'bg-mingl-light-blue';
    return (
      <CardRow styles={defaultStyles} {...this.props}>
        <div className="flex-initial">
          <CircularButton
            tabIndex={1}
            style={buttonStyle}
            onClick={this.handleButtonClick}
            children={this.props.buttonContent}
            className={`f8 tc white bn ${buttonBg} pointer`}
          />
        </div>
      </CardRow>
    );
  }
}
