import * as React from 'react';
import idx from '../utils/idx';
import { Query } from 'react-apollo';
import debounce from 'lodash-es/debounce';
import { QueryComponentInputProps } from './QueryComponentTypes';
import {
  CardSearch_cardSearch_edges_node,
  CardSearch as CardSearchQuery,
  CardSearchVariables as CardSearchQueryVariablesT,
} from '../__generated/apollogen-types';
import CARD_SEARCH_QUERY from './CardSearchQuery.graphql';

import LoadingPage from '../components/LoadingPage';
import ErrorMessage from '../components/ErrorMessage';

export type CardSearchQueryT = CardSearchQuery;
export type CardSearchNode = CardSearch_cardSearch_edges_node;

type DataT = CardSearchQueryT;
type VariablesT = CardSearchQueryVariablesT;

type CardSearchProps = QueryComponentInputProps<DataT, VariablesT>;

export default function CardSearchQuery({
  render,
  renderLoading = LoadingPage,
  renderError = ErrorMessage,
  ...variables
}: CardSearchProps) {
  const ErrorComponent = renderError;
  return (
    <Query<DataT, VariablesT> query={CARD_SEARCH_QUERY} variables={variables}>
      {apollo => {
        const { data, loading, error, refetch } = apollo;
        if (error && !loading) {
          const message =
            idx(error, _error => _error!.graphQLErrors![0].message) || '';

          return (
            <ErrorComponent
              error={error}
              extra={{ component: 'CardSearchQuery' }}
              message={error.message || message}
              retry={() => refetch(variables)}
            />
          );
        }
        if (loading && (!data || !data.cardSearch)) {
          return renderLoading();
        }
        return render(data || {}, apollo);
      }}
    </Query>
  );
}

// ============================================================================
//   Debounced managed search query
// ============================================================================

const DEBOUNCE_TIMEOUT = 300;
interface DebouncedState {
  variables: {
    search: string;
    first: number;
    after: string;
  };
  inputValue: string;
}

interface QueryCallbackProps {
  result?: CardSearchQueryT['cardSearch'];
  loading: boolean;
  inputValue: string;
  onChange: (input: string) => void;
  handleChangeDOM: (domEvent: React.ChangeEvent<HTMLInputElement>) => void;
  fetchMore: () => Promise<{}>;
}
export interface SearchData {
  query: string;
}
export interface DebouncedSearchQueryProps {
  initialVariables?: CardSearchQueryVariablesT;
  children: (query: QueryCallbackProps) => JSX.Element | null;
  onSearchPerformed?: (data: SearchData) => void;
}

export class DebouncedCardSearchQuery extends React.Component<
  DebouncedSearchQueryProps,
  DebouncedState
> {
  constructor(props: DebouncedSearchQueryProps) {
    super(props);
    const search = idx(props, _ => _.initialVariables!.search) || '';
    const first = idx(props, _ => _.initialVariables!.first) || 25;
    const after = idx(props, _ => _.initialVariables!.after) || '';

    this.state = {
      inputValue: search,
      variables: {
        search,
        first,
        after,
      },
    };
  }

  setSearchQuery = debounce(
    (search: string) => {
      if (this.props.onSearchPerformed) {
        this.props.onSearchPerformed({ query: search });
      }
      this.setState(({ variables }) => ({
        variables: { ...variables, search },
      }));
    },
    DEBOUNCE_TIMEOUT,
    { leading: true, trailing: true }
  );

  onChange = (inputValue: string) => {
    this.setState({ inputValue });
    this.setSearchQuery(inputValue);
    return null;
  };

  handleChangeDOM = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.onChange(event.target.value);
  };

  componentWillUnmount() {
    this.setSearchQuery.cancel();
  }

  render() {
    const { variables, inputValue } = this.state;

    return (
      <Query<DataT, VariablesT>
        query={CARD_SEARCH_QUERY}
        variables={variables}
        skip={inputValue === ''}
        {...variables}
        children={apollo => {
          const result = idx(apollo, _ => _.data!.cardSearch!) || undefined;
          const loading = idx(apollo, _ => _.loading) || false;
          const edges = (result && result.edges) || [];
          const fetchMore = () =>
            apollo.fetchMore({
              variables: {
                after: edges[edges.length - 1].cursor,
              },
              updateQuery: (previousResult, { fetchMoreResult }) =>
                fetchMoreResult && fetchMoreResult.cardSearch.edges.length
                  ? {
                      cardSearch: {
                        __typename: previousResult.cardSearch.__typename,
                        edges: [
                          ...previousResult.cardSearch.edges,
                          ...fetchMoreResult.cardSearch.edges,
                        ],
                        pageInfo: fetchMoreResult.cardSearch.pageInfo,
                      },
                    }
                  : previousResult,
            });
          return this.props.children({
            result,
            loading,
            inputValue,
            onChange: this.onChange,
            handleChangeDOM: this.handleChangeDOM,
            fetchMore,
          });
        }}
      />
    );
  }
}
