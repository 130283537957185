import * as React from 'react';
import Search from '../../components/Search';

export function NavSearch(props: { navigate: (route: string) => void }) {
  const [searching, setSearching] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSearchBlur = React.useCallback(() => {
    setSearching(false);
  }, []);

  React.useEffect(() => {
    if (searching && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searching]);

  return (
    <Search
      className="ph2 br-pill"
      onBlur={onSearchBlur}
      navigate={props.navigate}
      forwardedRef={inputRef}
      analyticsContext="fixed_navbar"
    />
  );
}
