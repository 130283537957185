import {
  MutationFunction,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
} from 'react-apollo';
import { useCallback } from 'react';
import idx from '../utils/idx';

import {
  ReorderGroupResourcesMutation,
  ReorderGroupResourcesMutationVariables,
  RequestStatusEnum,
} from '../__generated/apollogen-types';
import REORDER_GROUP_RESOURCES from './ReorderGroupResourcesMutation.graphql';

type DataT = ReorderGroupResourcesMutation;
type VariablesT = ReorderGroupResourcesMutationVariables;

export type ReorderGroupResourcesMutationFnT = MutationFunction<
  DataT,
  VariablesT
>;

export const useReorderGroupResourcesMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  return useMutation(REORDER_GROUP_RESOURCES, {
    ...options,
  });
};

// ============================================================================
// Mutation handler

type MutationOutputT = NonNullable<DataT['reorderGroupResources']>;

type HandledMutationFn = (
  options: MutationFunctionOptions<DataT, VariablesT>
) => Promise<MutationOutputT>;

export const useHandledReorderGroupResourcesMutation = (
  options?: MutationHookOptions<DataT, VariablesT>
) => {
  const [reorderGroupResources] = useReorderGroupResourcesMutation(options);

  /**
   * Modified mutation function that forwards or defaults the mutation output
   */
  const handledMutationFn = useCallback<HandledMutationFn>(
    async options => {
      const fallbackResponse: MutationOutputT = {
        __typename: 'ReorderGroupResourcesMutationOutput',
        message: 'An error occurred',
        status: RequestStatusEnum.ERROR,
        groupId: idx(options, _ => _.variables!.input.groupId) || '',
      };

      try {
        const res = await reorderGroupResources(options);
        return (
          idx(res, _ => _!.data!.reorderGroupResources) || fallbackResponse
        );
      } catch (error) {
        return fallbackResponse;
      }
    },
    [reorderGroupResources]
  );

  return handledMutationFn;
};
