import * as React from 'react';
import { navigate, Link } from '@reach/router';

import XIcon from '../../components/icons/X-Icon';
import CogwheelIcon from '../../components/icons/CogwheelIcon';
import CameraIcon from '../../components/icons/CameraIcon';
import { ChevronLeftIcon } from '../../components/icons/ChevronIcon';
import MenuIcon from '../../components/icons/MenuIcon';
import { AnalyticsLink, AnalyticsLinkPropsT } from '../ui/AnalyticsLink';
import { AuthContext } from '../core/providers/AuthProvider';
import { userDevice } from '../../utils/userDevice';
import { analyticsEvents } from '../../utils/analytics';
import { urls } from '../../config';
import { DrawerNavContext } from './DrawerNavigation';
import { HeaderNavItem } from './HeaderNavItem';

const Title = (props: { children: React.ReactNode }) => (
  <h2 children={props.children} className="white ma0 fw6" />
);

const Back = (props: {
  returnPath: string;
  onClick?: (returnPath: string) => void;
}) => (
  <span
    className="dib relative pointer"
    style={{ padding: '0.5rem 1rem', left: '-1rem' }}
    onClick={() =>
      props.onClick
        ? props.onClick(props.returnPath)
        : navigate(props.returnPath)
    }
  >
    <ChevronLeftIcon size={20} color="#FFF" />
  </span>
);

const Close = (props: { returnPath: string }) => (
  <span
    className="dib relative pointer"
    style={{ padding: '0.5rem', left: '-0.5rem' }}
    onClick={() => navigate(props.returnPath)}
  >
    <XIcon height={20} width={20} color="#FFF" />
  </span>
);

const QrReader = () => (
  <div>
    <Link className="db" tabIndex={1} to="/qr">
      <CameraIcon size={25} color="white" />
    </Link>
  </div>
);

const MinglLogo = () => (
  <div>
    <Link className="db" tabIndex={1} to="/">
      <img
        style={{ objectFit: 'contain', minWidth: '40px' }}
        className="mt2"
        src={require('../../assets/img/mingl-logo-white.png')}
        height={40}
      />
    </Link>
  </div>
);

const Settings = () => (
  <Link
    to="/settings"
    tabIndex={1}
    className="dib ph2 pb1 pt3"
    style={{ color: 'transparent' }}
  >
    <CogwheelIcon size={25} color="white" />
  </Link>
);

const AuthenticateWeb = () => (
  <React.Fragment>
    <Link
      className="dib white link pa1 ml1 nowrap"
      style={{ fontSize: '14px' }}
      to="/login"
      children="Log in"
    />
    <Link
      className="dib mingl-green fw6 ttu link nowrap ba br-pill pv1 ph2 ph3-ns ml1"
      style={{ fontSize: '14px' }}
      to="/register"
      children="Sign up"
    />
  </React.Fragment>
);

const GetTheApp = (props: {
  title?: string;
  href?: string;
  analyticsEvent?: AnalyticsLinkPropsT['analyticsEvent'];
  analyticsData?: AnalyticsLinkPropsT['analyticsData'];
}) => {
  const { isAuthenticated } = React.useContext(AuthContext);

  if (userDevice.hasAppStore) {
    const {
      title = 'Get the App',
      href = urls.getMingl,
      analyticsData = {},
      analyticsEvent = analyticsEvents.webReferrals.appNav,
    } = props;

    return (
      <AnalyticsLink
        analyticsEvent={analyticsEvent}
        analyticsData={{
          component: 'AppNav.GetTheApp',
          title,
          href,
          ...analyticsData,
        }}
        className="dib mingl-green fw6 ttu link nowrap ba br-pill pv1 ph2 ph3-ns ml1"
        style={{ fontSize: '14px' }}
        href={href}
        target="_blank"
        children={title}
      />
    );
  }

  return isAuthenticated ? null : <AuthenticateWeb />;
};

const MobileMenuItem = () => {
  const appDrawerContext = React.useContext(DrawerNavContext);

  return (
    <HeaderNavItem
      name="Menu"
      type="button"
      onClick={() =>
        appDrawerContext.setDrawerNavOpen(!appDrawerContext.drawerNavOpen)
      }
      children={<MenuIcon size={22} color="currentColor" />}
    />
  );
};

export const NavComponents = {
  Back,
  Close,
  Title,
  GetTheApp,
  Settings,
  MinglLogo,
  QrReader,
  AuthenticateWeb,
  MobileMenuItem,
};
